
import { useEffect, useState } from 'react';
import { getCustomizationSectionTemplates } from '../api/optionsList';
import { useTypedSelector } from './useTypedSelector';


export type CustomizationSectionTemplate = {
    customizationSectionTemplateId: number;
    serviceProviderUserId: number;
    businessId: number;
    customizationSectionTemplatName: string;
    customizationSectionTemplateDescription: string;
    customizationSectionTemplateJson: string;
    createDate: string;
    updateDate: string;
}


export const useCustomizationTemplates = () => {
    const bizInfo = useTypedSelector(x => x.businessInfo.businessInfo);
    const [templates, setTemplates] = useState<Array<CustomizationSectionTemplate>>([]);

    useEffect(() => {
        const serviceProvId = bizInfo?.serviceProviderUserId;
        if (!serviceProvId) return;

        const businessId = bizInfo?.businessId;
        // if (!businessId) return;

        (async () => {
            const res = await getCustomizationSectionTemplates(serviceProvId);

            if (res.status !== 200) {
                console.error();
                return;
            }

            const fetchedTemplates = res.data;
            setTemplates(fetchedTemplates);
        })()

    }, [bizInfo?.serviceProviderUserId, bizInfo?.businessId])

    return templates;
};

