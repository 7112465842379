import { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


interface SortableCardProps {
  content: (item, listeners) => React.ReactNode;
  item: any;
  sortIndex: string;
  styles?: CSSProperties;
}

const SortableCard: React.FC<SortableCardProps> = (props) => {
  const { item, content, sortIndex, styles } = props;

  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id: sortIndex });

  const style: CSSProperties = {
    ...styles,
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
  };

  return (
    <div ref={setNodeRef} {...attributes} style={style} >
      {content(item, listeners)}
    </div>
  );
};

export default SortableCard;
