import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sendPasswordChange } from "../../api/user";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Account.module.scss";
import InputWithIcons from "./InputWithIcons";
import SubmitFieldWithButton from "./SubmitFieldWithButton";


const ChangePassword: React.FC = () => {
  const params = useParams<{ businessName: string }>();
  const appBaseUrl = `/${params.businessName}`;
  const account = useTypedSelector(state => state.account);
  const { validateUserEmail } = useActions();
  const [infoMsg, setInfoMsg] = useState("");
  const [fields, setFields] = useState({
    emailAsUserId: account.credential.email ?? "",
    password: "",
    newPassword: "",
    confirmedPassword: "",
  });

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    if (event.target.name === nameOf((a: typeof fields) => a.emailAsUserId)) {
      setFields({
        ...fields,
        emailAsUserId: currValue
      });
      validateUserEmail(currValue);
      return;
    }

    const fieldName = event.target.name;
    setFields({
      ...fields,
      [fieldName]: currValue
    });
  }

  const isFormValid = () => {
    const { password, newPassword, confirmedPassword } = fields;

    if (account.error !== "") return false;
    if (password.length < 4) return false;
    if (newPassword.length < 4) return false;
    if (newPassword !== confirmedPassword) return false;

    return true;
  }

  const tryChangePassword = async (event) => {
    const result = await sendPasswordChange({ ...fields });
    console.log(result);
    setInfoMsg("Your password has been changed successfully.");
  }

  const nameOf = (f: (x: any) => any): string => {
    var p = new Proxy({}, {
      get: (target, key) => key
    })
    return f(p);
  }

  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>
        Enter your new password for Relayfy account
      </div>

      <SubmitFieldWithButton
        name={nameOf((a: typeof fields) => a.emailAsUserId)}
        value={fields.emailAsUserId}
        onChange={handleInput}
        onSubmit={tryChangePassword}
        isFormValid={isFormValid}
      />

      <InputWithIcons
        name={nameOf((a: typeof fields) => a.password)}
        value={fields.password}
        placeholder="Type current password"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        wrapperClasses={classes.elemWithIcon_bottomGap}
      />

      <InputWithIcons
        name={nameOf((a: typeof fields) => a.newPassword)}
        value={fields.newPassword}
        placeholder="Type new password"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        wrapperClasses={classes.elemWithIcon_bottomGap}
      />

      <InputWithIcons
        name={nameOf((a: typeof fields) => a.confirmedPassword)}
        value={fields.confirmedPassword}
        placeholder="Confirm new password"
        type="password"
        leftIcon={faLock}
        onChange={handleInput}
        wrapperClasses={classes.elemWithIcon_bottomGap}
      />

      <div className={classes.messages}>
        {account.error || infoMsg}
      </div>

      <div className={classes.alternatives}>
        <Link
          className={`${classes.backLink}`}
          to={`${appBaseUrl}`} >
          Got back to menu
        </Link>
      </div>
    </div>
  );
}

export default ChangePassword;