import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Account.module.scss";
import InputWithIcons from "./InputWithIcons";
import SubmitFieldWithButton from "./SubmitFieldWithButton";



const SignIn: React.FC = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const params = useParams<{ businessName: string }>();
  const appBaseUrl = `/${params.businessName}`;
  const location = useLocation<{ justSignedUp: boolean, infoMessage: string }>();
  const { signInWithEmail, validateUserEmail } = useActions();

  const account = useTypedSelector(state => state.account);
  const [credential, setCredential] = useState({
    signInId: "",
    password: "",
    serviceProviderId: "",
  });

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    if (event.target.type === "email") {
      setCredential(rest => ({ ...rest, signInId: currValue }));
      validateUserEmail(currValue);
      return;
    }

    setCredential(rest => ({ ...rest, password: currValue }));
  }

  const isFormValid = () => {
    return credential.signInId.length > 0 && credential.password.length >= 4;
  }

  const getMessage = () => {
    if (account.error) {
      return account.error;
    }

    if (!!location.state && location.state.justSignedUp) {
      return location.state.infoMessage;
    }
    return "";
  }

  const handleSubmit = (e) => {
    credential.serviceProviderId = businessInfo?.serviceProviderUserId ?? "";
    signInWithEmail(credential);
  }

  const onKeyUp = (event) => {
    if(event.key === "Enter") {
      handleSubmit(event);
    }
  }

  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>Sign In</div>

      <SubmitFieldWithButton
        value={credential.signInId}
        onChange={handleInput}
        isFormValid={isFormValid}
        onKeyUp={onKeyUp}
        onSubmit={handleSubmit}
      />

      <InputWithIcons
        value={credential.password}
        placeholder="Password"
        type="password"
        leftIcon={faLock}
        onKeyUp={onKeyUp}
        onChange={handleInput}
      />

      <div className={classes.messages}>
        {getMessage()}
      </div>

      <div className={classes.signIn_alternatives}>
        <Link
          className={classes.link}
          to={`${appBaseUrl}/account/forgot`} >
          Forgot password?
        </Link>
      </div>
    </div>
  );
}

export default SignIn;
