import classes from './DashboardPage.module.scss';
import { useEffect, useState } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { getDashboardStats } from '../../api/statistics';
import { formatMoney } from '../../common/pageUtils';
import { DashboardStatistics, StatRowProps } from './DashboardTypes';


const DashboardPage: React.FC = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [statistics, setStatistics] = useState<DashboardStatistics>();

  useEffect(() => {

    const fetchDashboardStats = async () => {
      if (!businessInfo?.serviceProviderUserId) return;
      if (!businessInfo?.businessId) return;

      const res = await getDashboardStats(businessInfo?.serviceProviderUserId, businessInfo.businessId);

      if (res.status !== 200) return;
      setStatistics(res.data as DashboardStatistics);
    }

    fetchDashboardStats();
  }, [businessInfo?.serviceProviderUserId])


  return (
    <div className={classes.dashboardPage}>
      <div className={classes.h1}>Dashboard</div>

      {!statistics ?
        <span>
          There is no data about this business yet.
        </span>
        : <>
          <StatRow title="Today"
            total={statistics?.salesThisDay}
            ordersNum={statistics?.thisDayTransactionsCount}
            tipTotal={statistics?.tipsThisDay}
            tipPercent={statistics?.tipPercentageThisDay}
            tipNum={statistics?.tipCountThisDay}
          />
          <StatRow title="This Week"
            total={statistics?.salesThisWeek}
            ordersNum={statistics?.thisWeekTransactionsCount}
            tipTotal={statistics?.tipsThisWeek}
            tipPercent={statistics?.tipPercentageThisWeek}
            tipNum={statistics?.tipCountThisWeek}
          />

          <StatRow title="This Month"
            total={statistics?.salesThisMonth}
            ordersNum={statistics?.thisMonthTransactionsCount}
            tipTotal={statistics?.tipsThisMonth}
            tipPercent={statistics?.tipPercentageThisMonth}
            tipNum={statistics?.tipCountThisMonth}
          />

          <StatRow totalLabel="Sign-in"
            total={statistics?.salesThisMonthSignedUp}
            ordersNum={statistics?.countThisMonthSignedUp}
          />

          <StatRow totalLabel="Mobile Pay"
            total={statistics?.salesThisMonthMobilePay}
            ordersNum={statistics?.countThisMonthMobilePay}
          />

          <StatRow title="This Year"
            total={statistics?.salesThisYear}
            ordersNum={statistics?.thisYearTransactionsCount}
            tipTotal={statistics?.tipsThisYear}
            tipPercent={statistics?.tipPercentageThisYear}
            tipNum={statistics?.tipCountThisYear}
          />

          <div className={classes.h2}>Sales by Months</div>

          <MonthRow isMonth totalLabel="Jan" total={statistics?.salesJan}
            ordersNum={statistics?.salesJanTransactionsCount} tipTotal={statistics?.tipsAmountJan}
            tipPercent={statistics?.tipPercentageJan} tipNum={statistics?.tipCountJan}
          />

          <MonthRow isMonth totalLabel="Feb" total={statistics?.salesFeb}
            ordersNum={statistics?.salesFebTransactionsCount} tipTotal={statistics?.tipsAmountFeb}
            tipPercent={statistics?.tipPercentageFeb} tipNum={statistics?.tipCountFeb}
          />

          <MonthRow isMonth totalLabel="Mar" total={statistics?.salesMar}
            ordersNum={statistics?.salesMarTransactionsCount} tipTotal={statistics?.tipsAmountMar}
            tipPercent={statistics?.tipPercentageMar} tipNum={statistics?.tipCountMar}
          />

          <MonthRow isMonth totalLabel="Apr" total={statistics?.salesApr}
            ordersNum={statistics?.salesAprTransactionsCount} tipTotal={statistics?.tipsAmountApr}
            tipPercent={statistics?.tipPercentageApr} tipNum={statistics?.tipCountApr}
          />

          <MonthRow isMonth totalLabel="May" total={statistics?.salesMay}
            ordersNum={statistics?.salesMayTransactionsCount} tipTotal={statistics?.tipsAmountMay}
            tipPercent={statistics?.tipPercentageMay} tipNum={statistics?.tipCountMay}
          />

          <MonthRow isMonth totalLabel="Jun" total={statistics?.salesJun}
            ordersNum={statistics?.salesJunTransactionsCount} tipTotal={statistics?.tipsAmountJun}
            tipPercent={statistics?.tipPercentageJun} tipNum={statistics?.tipCountJun}
          />

          <MonthRow isMonth totalLabel="Jul" total={statistics?.salesJul}
            ordersNum={statistics?.salesJulTransactionsCount} tipTotal={statistics?.tipsAmountJul}
            tipPercent={statistics?.tipPercentageJul} tipNum={statistics?.tipCountJul}
          />

          <MonthRow isMonth totalLabel="Aug" total={statistics?.salesAug}
            ordersNum={statistics?.salesAugTransactionsCount} tipTotal={statistics?.tipsAmountAug}
            tipPercent={statistics?.tipPercentageAug} tipNum={statistics?.tipCountAug}
          />

          <MonthRow isMonth totalLabel="Sep" total={statistics?.salesSep}
            ordersNum={statistics?.salesSepTransactionsCount} tipTotal={statistics?.tipsAmountSep}
            tipPercent={statistics?.tipPercentageSep} tipNum={statistics?.tipCountSep}
          />

          <MonthRow isMonth totalLabel="Oct" total={statistics?.salesOct}
            ordersNum={statistics?.salesOctTransactionsCount} tipTotal={statistics?.tipsAmountOct}
            tipPercent={statistics?.tipPercentageOct} tipNum={statistics?.tipCountOct}
          />

          <MonthRow isMonth totalLabel="Nov" total={statistics?.salesNov}
            ordersNum={statistics?.salesNovTransactionsCount} tipTotal={statistics?.tipsAmountNov}
            tipPercent={statistics?.tipPercentageNov} tipNum={statistics?.tipCountNov}
          />

          <MonthRow isMonth totalLabel="Dec" total={statistics?.salesDec}
            ordersNum={statistics?.salesDecTransactionsCount} tipTotal={statistics?.tipsAmountDec}
            tipPercent={statistics?.tipPercentageDec} tipNum={statistics?.tipCountDec}
          />

        </>}
    </div >
  )
}


const MonthRow = (props: StatRowProps) => {
  if(!props.total) return <></>;
  return <StatRow {...props}  />
}


const StatRow: React.FC<StatRowProps> = (props) => {
  const { title, totalLabel, total, ordersNum, isMonth = false } = props;
  const { tipTotal, tipNum, tipPercent } = props;
  const tipsCount = isMonth ? ordersNum : tipNum;

  const formatWithDollar = (amt) => {
    return `$${formatMoney(amt)}`;
  }

  const calcAvg = (amt?: number, orderNum?: number) => {
    if (!amt || !orderNum) return "";
    return (amt / orderNum).toFixed(2);
  }

  const labelData = (label: string, data) => {
    return (<div className={classes.row_item}>
      <div className={classes.row_item_label}>
        {`${label}:`}
      </div>
      <div className={classes.row_item_data}>
        {data ?? 0}
      </div>
    </div>)
  }

  const hasTipTotalValue = Boolean(tipTotal && tipTotal > 0);
  const hasTipPercent = Boolean(tipPercent && tipPercent > 0);
  const tipCountAvg = calcAvg(tipTotal, isMonth ? ordersNum : tipsCount);
  const totalAvg = calcAvg(total, ordersNum);
  return (<>
    {title && title !== "" && <div className={classes.h2}>{title}</div>}

    <div className={classes.row}>
      {labelData(totalLabel ?? "Total sales", formatWithDollar(total))}
      {labelData("Number of orders", ordersNum)}
      {labelData("Average", formatWithDollar(totalAvg))}
      {hasTipTotalValue && labelData("Total Tips", formatWithDollar(tipTotal))}
      {hasTipTotalValue && labelData("Average Tips", formatWithDollar(tipCountAvg))}
      {hasTipPercent && labelData("Average Tip %", `${calcAvg(tipPercent, tipsCount)}%`)}
    </div>
  </>)
}

export default DashboardPage;