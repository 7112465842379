import axios from "axios";
import { createFormData } from "../common/pageUtils";
import { baseUrl } from "../appConfigs";
import { MenuProductDTO } from "../Types/Types";
import { getAuthConfig, getMultipartAuthConfig } from "./apiAuthConfig";

export const getProductsByCategory = async (categoryId: string | number, serviceProvId: string) => {
  return await axios.get(`${baseUrl}/rest/product/productsList/${categoryId}/${serviceProvId}`);
}

export const getProduct = async (productId: number | string) => {
  return await axios.get(`${baseUrl}/rest/product/search/${productId}`)
}

export const updateProduct = async (updatedData: MenuProductDTO) => {
  try {
    return await axios.post(`${baseUrl}/rest/product`, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const updateProductsOrder = async (updatedData: MenuProductDTO[]) => {
  try {
    const uri = `${baseUrl}/rest/productCategory/updateProductsOrder`;
    return await axios.post(uri, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const addProduct = async (newProduct: MenuProductDTO) => {
  try {
    return await axios.put(`${baseUrl}/rest/product`, newProduct, getAuthConfig())
  } catch (err) {
    throw err;
  }
}

export const deleteProduct = async (productId: number, serviceProvId: string) => {
  try {
    return await axios.delete(`${baseUrl}/rest/product/${serviceProvId}/${productId}`, getAuthConfig())
  } catch (err) {
    throw err;
  }
}

export const postProductImage = async (imageFile, productId) => {
  try {
    const form = createFormData(imageFile);
    form.append("productId", productId);

    const uploadUrl = `${baseUrl}/rest/file/upload/menuImage`;
    return await axios.post(uploadUrl, form, getMultipartAuthConfig());
  } catch (err) {
    console.error(err);
  }
}
