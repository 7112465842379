
import classes from './ImgUploadForm.module.scss';
import { ChangeEvent, MouseEventHandler, useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { Box, Typography } from '@material-ui/core';
import { convertImageSrc, ValidateFileSize } from '../../common/pageUtils';
import OkCancelPopup from '../Popup/OkCancelPopup';
import { imgUploadSizeLimit } from '../../appConfigs';
import { red } from '@material-ui/core/colors';

export interface ImgUploadFormProps {
  onValidImage: (file: File) => void;
  currentImage?: string;
  isOpen: boolean;
  isImgAdd: boolean;
  onCancel: (event:
    React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  onOk: MouseEventHandler<Element>;
}

const ImgUploadForm: React.FC<ImgUploadFormProps> = (props) => {
  const { onValidImage, currentImage, onCancel, onOk, isOpen, isImgAdd } = props;
  const [imgError, setError] = useState("");

  const [imgPreview, setImgPreview] = useState("");
  const [isFirstImgLoaded, setIsFirstImgLoaded] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setError("");
    }
  }, [isOpen])

  useEffect(() => {
    const img = convertImageSrc(currentImage);
    setImgPreview(img ?? "");
  }, [currentImage]);

  const handleImgChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;
    const file = files[0];
    const isValid = ValidateFileSize(file);
    setImgPreview(URL.createObjectURL(file));

    if (isValid) {
      setError("");
      onValidImage(file);
      setIsFirstImgLoaded(true);
      return;
    }
    setError(`File size exceeds ${imgUploadSizeLimit} MB,<br /> please upload a smaller image!`);
  };

  const onOkWrapper = (e) => {
    if (!isFirstImgLoaded || imgPreview.length === 0) {
      setError("Please updload a valid image!");
      return;
    }

    setError("");
    onOk(e);
  }

  const body = (
    <div className={classes.imgUpload_form}>
      <Image centered size='small'
        src={imgPreview}
        rounded
      />
      <input type="file"
        accept="image/*"
        onChange={handleImgChange} />
      <Typography component="div"  className={classes.error}>
        <Box fontWeight={900} fontStyle="italic" dangerouslySetInnerHTML={{ __html: imgError}}/>
      </Typography>
    </div>
  );

  return (
    <OkCancelPopup
      isOpen={isOpen}
      onOk={onOkWrapper}
      onCancel={e => {
        onCancel(e);
        setImgPreview("");
      }}
      title="Image upload"
      disabled={imgError.length > 0}
      okButtonContent={isImgAdd ? "Add" : "Save"}
      content={body}
    />
  );
}

export default ImgUploadForm;