import classes from './FormRow.module.scss';
import { CSSProperties, useEffect } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  FormControlLabel, FormGroup, MenuItem, Radio, RadioGroup, Select
} from '@material-ui/core';

interface RowPropsBase<T> {
  label?: string;
  name?: string;
  inputType: string;
  value: T;
  handleChange: (event) => void;
  maxLength?: number;
  placeholder?: string;
  close?: boolean;
  wide?: boolean;
  preFix?: string;
  postFix?: string;
  inputStyle?: CSSProperties;
  rowStyle?: CSSProperties;
}

interface RowProps<T> extends RowPropsBase<T> {
  inputType: "text" | "textarea" | "checkbox" | "time" | "price";


}

interface DropdownProps<T> extends Omit<RowPropsBase<T>, 'value'> {
  inputType: "dropdown";
  value: string | number | undefined;
  options: T[];
  displayField?: (val: T) => string | number | undefined;
  valueField: (val: T) => string | number | undefined;
  placeholder?: never;
}

interface DatePickerProps<T> extends RowPropsBase<T> {
  inputType: "date";
  required?: boolean;
}
interface NumberProps<T> extends RowPropsBase<T> {
  inputType: "number";
  max?: number;
  min?: number;
  onlyDigits?: boolean;
}

interface RadioProps<T> extends Omit<RowPropsBase<T>, 'value'> {
  inputType: "radio";
  value: boolean | string | undefined;
  label?: string;
  options: T[];
  displayField: (val: T) => string | number;
  valueField: (val: T) => boolean | string;
}

const createTimeDropdownOptions = () => {
  const arr: string[] = [""];
  for (let i = 0; i < 24; i++) {
    const hour = i.toString().padStart(2, '0');
    arr.push(`${hour}00`);
    arr.push(`${hour}30`);
  }
  return arr;
}

export const byHalfHourOptions = createTimeDropdownOptions();

type PropType<T> = DropdownProps<T> | RowProps<T> | DatePickerProps<T> | RadioProps<T> | NumberProps<T>;

type AnyType = Object | string | number | boolean | undefined;
const FormRow = <T extends AnyType> (props: PropType<T>) => {
  const {
    label, name, inputType, value, handleChange, inputStyle, rowStyle,
    maxLength = 40, placeholder, close, wide, preFix, postFix
  } = props;
  const { required = false } = props as DatePickerProps<AnyType>;

  useEffect(() => {
  }, []);


  let inputElement;
  if (inputType === "price") {
    const { placeholder = "0.00" } = props;
    const isZero = value !== undefined && Number(value) === 0;
    const priceValue = isZero ? undefined : value as string;

    inputElement = (<>
      $<input
        className={classes.form_row_numberInput}
        name={name}
        type="number"
        value={priceValue}
        onChange={event => {
          handleChange({
            target: {
              name: name ?? "",
              type: inputType,
              value: event.target.value,
            }
          })
        }}
        placeholder={placeholder} />
    </>)
  }

  if (inputType === "number") {
    const {
      onlyDigits = false, min = 0, max, placeholder = "0"
    } = props as NumberProps<AnyType>;

    const isZero = value !== undefined && Number(value) === 0;
    const numberValue = isZero ? '' : value as number
    inputElement = (
      <input
        className={classes.form_row_numberInput}
        name={name}
        type={inputType}
        onKeyDown={event => {
          if (!onlyDigits) return true;
          const isInvalidKeys = event.key === '.' || event.key === 'e';
          return isInvalidKeys && event.preventDefault();
        }}
        onChange={event => {
          const newVal = event.target.value;
          handleChange({
            target: {
              type: inputType,
              name: event.target.name,
              value: newVal ? Number(newVal) : undefined,
            }
          });
        }}
        min={min}
        max={max}
        value={numberValue}
        placeholder={placeholder} />
    );
  }

  if (inputType === "radio") {
    const { options, displayField, valueField, value: radioValue } = props as RadioProps<T>;

    const radioElems = options.map(val => {
      const keyVal = valueField(val);
      const displayValue = displayField(val);
      const newEventObj = { target: { name: name, value: keyVal } };
      const radioElem = (
        <Radio
          checked={radioValue === keyVal}
          onChange={e => handleChange(newEventObj)}
          value={radioValue}
          color="default"
          name={name}
          inputProps={{ 'aria-label': displayValue?.toString() ?? "" }}
        />
      );

      return (
        <FormControlLabel key={JSON.stringify(val)} value="end"
          label={displayValue}
          control={radioElem} />
      )
    })

    inputElement = (
      <RadioGroup row
        aria-label="position"
        className={classes.form_row_radio}
        name="position"
        defaultValue="top">
        {radioElems}
      </RadioGroup>
    );
  }

  if (inputType === "time") {
    inputElement = (
      <Select value={value} name={name}
        onChange={handleChange as any}>
        {byHalfHourOptions.map((val: string) => {
          const hour = val.slice(0, 2);
          const minutes = val.slice(2, 4);
          return (
            <MenuItem key={val} value={val}>
              {val === "" ? "None" : `${hour}:${minutes}`}
            </MenuItem>
          )
        })}
      </Select>
    );
  }

  if (inputType === "dropdown") {
    const { options, displayField, valueField, value: dropdownValue } = props as DropdownProps<T>;

    inputElement = (
      <Select value={dropdownValue} name={name}
        defaultValue=""
        onChange={handleChange as any}>
        {options.map(val => {
          const keyValue = valueField(val);
          const displayValue = displayField ? displayField(val) : keyValue;

          return (
            <MenuItem key={keyValue} value={keyValue}>
              {!displayValue ? "None" : displayValue}
            </MenuItem>
          )
        })}
      </Select>
    );
  }

  if (inputType === "date") {
    const date = new Date(value as string);
    const handleDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
      handleChange({
        target: {
          name: name,
          value: value
        }
      })
    }
    inputElement = (
      <KeyboardDatePicker
        name={name}
        required={required}
        margin="normal"
        placeholder="MM/DD/yyyy"
        format="MM/DD/yyyy"
        value={value ? date : null}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    );
  }

  if (inputType === "textarea") {
    const { maxLength = 150 } = props;
    inputElement = (
      <textarea
        className={classes.form_row_textArea}
        name={name}
        onChange={handleChange as any}
        value={value as string ?? ""}
        placeholder={placeholder}
        maxLength={maxLength} />);
  }

  if (inputType === 'checkbox') {
    inputElement = (
      <input
        className={classes.form_row_checkbox}
        name={name}
        type={inputType}
        onChange={handleChange as any}
        checked={value as boolean}
        placeholder={placeholder}
      />
    );
  }

  if (inputType === "text") {
    inputElement = (
      <input
        className={classes.form_row_input}
        style={inputStyle}
        name={name}
        type={inputType}
        onChange={handleChange as any}
        value={value as string ?? ""}
        placeholder={placeholder}
        maxLength={maxLength} />
    );
  }
  const labelClass = close ? classes.form_row_labelClose : classes.form_row_label;
  const inputWrapClass = close ? classes.form_row_inputWrapClose : classes.form_row_inputWrap;
  const rowStyling = wide ? { gridTemplateColumns: "15% 85%", ...rowStyle} : rowStyle;

  return (
    <FormGroup row className={classes.form_row} style={rowStyling}>
      <label className={labelClass}>{label}</label>
      <div className={inputWrapClass}>
        {preFix}{inputElement}{postFix}
      </div>
    </FormGroup>
  );
};

export default FormRow;