import classes from './FormLocations.module.scss';
import CheckboxLabels from "../FormControls/CheckboxLabels";
import { FormGroup } from "@material-ui/core";
import { nameof } from "ts-simple-nameof";
import { LocationsDTO, CheckBoxChange } from '../../Types/Types';

const noNameText = "No Name found";

type FormLocationsProps = {
    list?: LocationsDTO[];
    handleChange: Function | CheckBoxChange;
}

const FormLocations = (props: FormLocationsProps) => {
    const { list, handleChange } = props;
    const handleCheckboxChange = handleChange as CheckBoxChange;

    if (!list || list.length <= 1) return <></>;

    return (<>
        <h3>Locations</h3>
        <FormGroup className={classes.daysCheckboxes}>
            <FormGroup row>
                {list.map((x, index) =>
                    <CheckboxLabels
                        label={x.businessName ?? noNameText}
                        name={`${nameof<LocationsDTO>(p => p.showInMenu)}_${index}`}
                        checkOnChange={handleCheckboxChange} 
                        value={x.showInMenu} />
                )}
            </FormGroup>
        </FormGroup>
    </>)
}

export default FormLocations;