import axios from "axios";
import { baseUrl } from "../appConfigs";
import { getAuthConfig, getMultipartAuthConfig } from "./apiAuthConfig";
import {
  CustomizationDTO, DiscountRuleDTO, InventoryDTO, MenuProductDTO, ProductOptionDTO, ReloadConfigDTO
} from "../Types/Types";
import { createFormData } from "../common/pageUtils";


export const getOptionsByProduct = async (productId: string) => {
  return await axios.get(`${baseUrl}/rest/productOption/productOptionsList/${productId}`);
}

export const getOption = async (optionId: number | string) => {
  return await axios.get(`${baseUrl}/rest/productOption/search/${optionId}`)
}

export const updateOption = async (updatedData: ProductOptionDTO) => {
  try {
    return await axios.post(`${baseUrl}/rest/productOption`, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const updateOptionList = async (updatedData: MenuProductDTO) => {
  try {
    const url = `${baseUrl}/rest/product/saveProductOptionsList`;
    return await axios.post(url, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const addOption = async (newOption: ProductOptionDTO) => {
  try {
    return await axios.put(`${baseUrl}/rest/productOption`, newOption, getAuthConfig())
  } catch (err) {
    throw err;
  }
}

export const deleteOption = async (optionId: number, serviceProvId: string) => {
  try {
    return await axios.delete(`${baseUrl}/rest/productOption/${serviceProvId}/${optionId}`, getAuthConfig())
  } catch (err) {
    throw err;
  }
}

export const postOptionImage = async (imageFile, optionId) => {
  try {
    const form = createFormData(imageFile);
    form.append("productOptionId", optionId);
    
    const uploadUrl = `${baseUrl}/rest/file/upload/menuOptionImage`;
    return await axios.post(uploadUrl, form, getMultipartAuthConfig());
  } catch (err) {
    console.error(err);
  }
}

export interface InventoryUpdateDTO {
  id?: number;
  inventory?: InventoryDTO;
  reloadConfig?: ReloadConfigDTO;
}

export interface DiscountRuleUpdateDTO {
  id: number;
  discountRule?: DiscountRuleDTO;
}

export interface CustomizationUpdateDTO {
  id: number;
  customization: CustomizationDTO;
}

export const updateInventory = async (updatedData: InventoryUpdateDTO) => {
  try {
    const uri = `${baseUrl}/rest/productOption/optionInventory`;
    return await axios.post(uri, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const updateDiscountRule = async (updatedData: DiscountRuleUpdateDTO) => {
  try {
    const uri = `${baseUrl}/rest/productOption/optionDiscount`;
    return await axios.post(uri, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const updateCustomization = async (updatedData: CustomizationUpdateDTO) => {
  try {
    const uri = `${baseUrl}/rest/productOption/optionCustomization`;
    return await axios.post(uri, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}


export const getCustomizationSectionTemplates = async (serviceProvId: string| number, businessId: string| number = 0) => {
  try {
    const uri = `${baseUrl}/rest/productOption/customizationSectionTemplateList/${serviceProvId}/${businessId}`;
    return await axios.get(uri, getAuthConfig());
  } catch (err) {
    throw err;
  }
}