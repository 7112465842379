import { nameof } from 'ts-simple-nameof';
import { GridColDef } from '@material-ui/data-grid';
import { BestSellerInfo } from './BestSellersPage';

const columnsDefinitions: GridColDef[] = [
  {
    field: nameof<BestSellerInfo>(row => row.name),
    headerName: "Menu Item Category", minWidth: 170,
    flex: 1
  },
  {
    field: nameof<BestSellerInfo>(row => row.count),
    headerName: "Number of Orders", width: 172,
  },
];

export default columnsDefinitions;
