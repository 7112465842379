import { Dispatch } from "react";
import { credentialPayload } from "../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { getCredentials, loginToServer, sendResetEmail } from "../../api/user";
import { getCookie, setCookie, eraseCookie } from "../../common/cookies";
import { validateEmail } from "../../common/account";
import { UserCredentialState } from "../reducers/accountReducer";

export const setUserCredentials = () => {
  return async (dispatch: Dispatch<Action>) => {
    const credentials = getCookie("btoa");
    const email = getCookie("email");
    const serviceProviderId = getCookie("serviceProviderId");

    if (!credentials) {
      dispatch({
        type: ActionType.SIGN_IN_ERROR,
        payload: ""
      })
      return;
    }

    dispatch({
      type: ActionType.SIGN_IN_SUCCESS,
      payload: {
        btoa: credentials,
        email: email,
        userId: serviceProviderId
      }
    })
  }
}


export const signInWithEmail = (data: credentialPayload) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      const signInResult = await getCredentials({
        emailAsUserId: data.signInId,
        password: data.password
      });

      if (signInResult.status === 204 && signInResult.data === "") {
        dispatch({
          type: ActionType.SIGN_IN_ERROR,
          payload: "Your email or password is incorrect"
        })
        return;
      }

      const credentials = signInResult.data as UserCredentialState;
      const auth = btoa(`${data.signInId}:${data.password}`);
      const adminSignInRes = await loginToServer(auth, data.serviceProviderId);

      if (adminSignInRes.data.success === false) {
        alert("Incorrect email or password for this business.")
        return;
      }

      if (adminSignInRes.status === 200) {
        dispatchSuccessActions(dispatch, credentials, auth);
      }

    } catch (err: any) {
      if (err?.response?.status === 401) {
        alert("Incorrect email or password for this business.")
      } else {
        alert("The system is not available at the moment. Please try again later.")
      }
      console.error(err);
    }
  }
}

export const signOut = () => {
  removeCredentials();
  return { type: ActionType.SIGN_OUT };
}

export const resetPassword = (emailAddress: string) => {
  return async (dispatch: Dispatch<Action>) => {
    try {

      const userLogin = {
        emailAsUserId: emailAddress,
        password: "",
        enterChoice: ""
      }

      await sendResetEmail(userLogin);

      dispatch({
        type: ActionType.RESET_PASSWORD_SENT,
        payload: ""
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export const clearAccountErrors = () => ({
  type: ActionType.CLEAR_ACCOUNT_ERRORS
})

export const validateUserEmail = (email: string) => {
  let errorMsg = "";
  if (!validateEmail(email)) {
    errorMsg = "Please enter a valid Email";
  }
  return {
    type: ActionType.SET_ACCOUNT_ERROR,
    payload: errorMsg
  }
}


const dispatchSuccessActions = (dispatch: Dispatch<Action>, credential: UserCredentialState, auth: string) => {
  dispatch({
    type: ActionType.SIGN_IN_SUCCESS,
    payload: {
      ...credential,
      btoa: auth,
      email: credential.emailAsUserId
    }
  });

  setCookie("btoa", auth);
  setCookie("serviceProviderId", credential.userId);
  setCookie("email", credential.emailAsUserId);
}

const removeCredentials = () => {
  eraseCookie("btoa");
  eraseCookie("email");
}
