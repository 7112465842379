import React from 'react'
import classes from './SideNavigation.module.scss';
import { InsertChart, MenuBook, Store, SvgIconComponent, WbIncandescent } from '@material-ui/icons';
import { useParams, useRouteMatch } from 'react-router';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Link } from 'react-router-dom';
import NavItem from "./NavItem";
import Drawer from '@material-ui/core/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { Divider, List, ListItem, SvgIconTypeMap } from '@material-ui/core';
import { useActions } from '../../hooks/useActions';
import { CSSProperties } from 'react';
import AccordionNavLink from './AccordionNavLink';
import BusinessInfoEdit from '../../pages/BusinessConfigs/BusinessInfoEdit';
import PromotionAndCharges from '../../pages/BusinessConfigs/PromotionAndCharges';
import OrderingEdit from '../../pages/BusinessConfigs/OrderingEdit';
import WorkingHours from '../../pages/BusinessConfigs/WorkingHours';
import DeliveryEdit from '../../pages/BusinessConfigs/DeliveryEdit';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

interface SideNavigationProps {
  open: boolean;
}

interface ConfigLink {
  url: string,
  name: string,
  muiIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
  component: () => JSX.Element,
}

interface BizConfigLinks {
  workingHour: ConfigLink;
  orderingEdit: ConfigLink;
  deliveryEdit: ConfigLink;
  promotionAndCharges: ConfigLink;
  businessInfoEdit: ConfigLink;
}

export const bizConfigLinks: BizConfigLinks = {
  workingHour: {
    url: "/workingHoursEdit",
    name: "Working Hours",
    muiIcon: Store,
    component: WorkingHours,
  },
  orderingEdit: {
    url: "/orderingEdit",
    name: "Ordering",
    muiIcon: Store,
    component: OrderingEdit,
  },
  deliveryEdit: {
    url: "/deliveryEdit",
    name: "Delivery",
    muiIcon: Store,
    component: DeliveryEdit,
  },
  promotionAndCharges: {
    url: "/promotionAndChargeEdit",
    name: "Promotions & Charges",
    muiIcon: Store,
    component: PromotionAndCharges,
  },
  businessInfoEdit: {
    url: "/businessInfoEdit",
    name: "Business Info",
    muiIcon: Store,
    component: BusinessInfoEdit,
  },
}

export const links = {
  menuEdit: {
    url: "/menuEdit",
    name: "Menu",
    muiIcon: MenuBook,
    params: "/:categoryId?/:productId?/:optionId?",
  },
  ...bizConfigLinks
}

const SideNavigation: React.FC<SideNavigationProps> = ({ open }) => {
  const { signOut } = useActions();
  const { isSignedIn } = useTypedSelector(state => state.account);
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { businessName } = useParams<{ businessName: string }>();
  const portalBaseUrl = `/${businessName}`;
  const { url } = useRouteMatch();

  const Title = (
    <div className={classes.sideNav_storeTitle}>
      <Link to={portalBaseUrl}>
        <h2>{businessInfo?.name}</h2>
      </Link>
    </div>
  )

  const lightBulbStyle: CSSProperties = {
    transform: "rotate(180deg)"
  };

  return (
    <Drawer
      className={classes.sideNav}
      // onClose={handleDrawerToggle}
      variant="persistent"
      anchor='left'
      open={open}
      classes={{
        paper: classes.sideNav_paper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      {Title}

      <List className={classes.sideList}>

        <List className={classes.linksList}>

          <NavItem muiIcon={InsertChart}
            url='/dashboard' name="Dashboard"
            iconStyle={{
              marginLeft: -2
            }}
          >Dashboard</NavItem>

          <AccordionNavLink
            summary={<>
              <WbIncandescent style={lightBulbStyle} />
              <span className={classes.accordion_dropdown}>
                Reports
              </span>
            </>}
            items={<>
              <NavItem url='/reports' name="Sales"
                wrapperClass={classes.accordion_subLink} >
                Sales
              </NavItem>
              <NavItem url='/bestSellers' name="Best sellers"
                wrapperClass={classes.accordion_subLink} >
                Best Sellers
              </NavItem>
            </>}
          />

          {Object.values(links).map(linkEntry => {

            return (
              <NavItem key={linkEntry.name} {...linkEntry}>
                {linkEntry.name}
              </NavItem>
            )
          })}

        </List>

        <List className={classes.footer}>
          <Divider />
          <Link to={`${url}/account/changePassword`}>
            <ListItem className={classes.item}>
              Change password
              <FontAwesomeIcon
                className={classes.icon}
                icon={faUnlock} />
            </ListItem>
          </Link>
          <Divider />
          <ListItem onClick={e => signOut()}
            className={classes.item}>
            Sign out
            <FontAwesomeIcon
              className={classes.signout_icon}
              icon={faSignOutAlt} />
          </ListItem>
        </List>


      </List>



    </Drawer>
  );
}



export default SideNavigation;