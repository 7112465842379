import classes from './OptionForms.module.scss';
import GlobalModal from '../GlobalModal/GlobalModal';
import FormRow from '../FormRow/FormRow';
import { nameof } from 'ts-simple-nameof';
import { useState, ChangeEvent, useEffect } from "react";
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from '@material-ui/core';
import { DiscountRuleUpdateDTO, getOption, updateDiscountRule } from '../../api/optionsList';
import { DiscountRuleFormData, DISCOUNT_VALUE_TYPE, ProductOptionDTO } from '../../Types/Types';
import FormVisibilityRules from '../../components/FormVisibilityRules/FormVisibilityRules';
import { daysFields, formatMoney, normalizeMoney, nullReplacer, timeNumberToStr, timeStrToNumber } from '../../common/pageUtils';

interface OptionPromotionFormProps {
  showModal: boolean;
  setModalOpen: Function;
  optionId?: number;
}


const OptionPromotionForm: React.FC<OptionPromotionFormProps> = (props) => {
  const { showModal, setModalOpen, optionId } = props;
  const { setAppLoading, setAppLoaded } = useActions();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");
  const [formData, setFormData] = useState({
    id: optionId ?? -1,
    discountRule: {
      title: "",
      Sun: false, Mon: false,
      Tue: false, Wed: false,
      Thu: false, Fri: false, Sat: false,
      endDate: "",
      startDate: "",
      endTime: "",
      startTime: "",
      type: DISCOUNT_VALUE_TYPE.amount,
      value: "",
      discountType: "",
    }
  });

  const [originalData, setOriginalData] = useState({
    ...formData,
  });

  const fetchOptionAsync = async () => {
    if (!optionId) return;
    const res = await getOption(optionId);

    const option = res.data as ProductOptionDTO;
    const fetchedDiscountRule = option.discountRule as any;

    let days = {};
    daysFields.forEach(day => days[day] = false);
    if (fetchedDiscountRule && fetchedDiscountRule.days) {
      fetchedDiscountRule.days.forEach(day => {
        days[day] = true;
      })
      delete fetchedDiscountRule.days;
    }
    const mappedData = {
      id: optionId,
      discountRule: {
        ...formData.discountRule,
        ...days,
      }
    }
    const timeFields = ["endTime", "startTime"];
    const mappedDiscountRule = mappedData.discountRule;
    if (fetchedDiscountRule) {
      const fetchedEntries = Object.entries(fetchedDiscountRule);
      fetchedEntries.forEach(entry => {
        const fieldName = entry[0];
        const originalVal = mappedDiscountRule[fieldName];
        const value = entry[1];
        if (timeFields.includes(fieldName)) {
          const isValidValue = value || value === 0;
          const newVal = isValidValue ? timeNumberToStr(value as number) : originalVal;
          mappedDiscountRule[fieldName] = newVal;
        } else {
          mappedDiscountRule[fieldName] = value ?? originalVal;
        }
      })

      const isAmount = fetchedDiscountRule.type === DISCOUNT_VALUE_TYPE.amount;
      mappedDiscountRule.value = formatMoney(mappedDiscountRule.value, isAmount);
    }

    if (fetchedDiscountRule && fetchedDiscountRule.days) {
      daysFields.forEach(day =>
        mappedData.discountRule[day] = fetchedDiscountRule.days.includes(day)
      )
      delete fetchedDiscountRule.days;
    }

    const originalData = { ...mappedData };
    setFormData(mappedData);
    setOriginalData(originalData);
  }

  const fetchData = async () => {
    setAppLoading();
    await fetchOptionAsync();
    setAppLoaded();
  }

  useEffect(() => {
    fetchData();
  }, [optionId, businessInfo?.serviceProviderUserId]);

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData, nullReplacer);
    const dirtiedJsonStr = JSON.stringify(formData, nullReplacer);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const getFormDtoSnapshot = (): DiscountRuleUpdateDTO => {
    const discountRule = formData.discountRule;

    const isAmount = discountRule.type === DISCOUNT_VALUE_TYPE.amount;
    const value = isAmount ? normalizeMoney(discountRule.value) : Number(discountRule.value);
    const formDTO: DiscountRuleUpdateDTO = {
      id: formData.id,
      discountRule: {
        startDate: discountRule.startDate,
        endDate: discountRule.endDate,
        type: discountRule.type,
        value,
        discountType: discountRule.discountType,
        title: discountRule.title,
        startTime: timeStrToNumber(discountRule.startTime),
        endTime: timeStrToNumber(discountRule.endTime),
        days: daysFields.filter(day => discountRule[day] === true),
      },
    }

    return formDTO;
  }

  const onSave = async (e) => {
    if (!optionId) return;
    if (!validateForm()) return;
    try {
      setAppLoading();
      const updatedData = getFormDtoSnapshot();
      const res = await updateDiscountRule(updatedData);
      if (res.data === "success") {
        setModalOpen(false);
      }
    } catch (err) {
      console.error("Something went wrong with updating the option", err);
    } finally {
      setAppLoaded();
    }
  }

  const onPopupOk = async (event) => {
    setOpenConfirmPopup(false);
    setModalOpen(false);
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
  }

  const validateForm = () => {
    if(formData)

    setValidationErrors("");
    return true;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const nameProp = target.name;
    const checkedInputs = ["checkbox", "radio"]
    const value = checkedInputs.includes(target.type) ? target.checked : target.value;

    if (formData.discountRule?.hasOwnProperty(nameProp)) {
      setFormData({
        ...formData,
        discountRule: {
          ...formData.discountRule,
          [nameProp]: value
        }
      });
      return;
    }

    console.warn(`No prop on discountRule or reloadConfig called ${nameProp}!`);
  }

  const discountTypes = [
    {
      name: "Amount",
      value: DISCOUNT_VALUE_TYPE.amount
    },
    {
      name: "Percentage",
      value: DISCOUNT_VALUE_TYPE.percentage
    }
  ]

  const modalBody = (
    <div className={classes.form}>

      <FormRow
        label="Type"
        name={nameof<DiscountRuleFormData>(p => p.type)}
        inputType="dropdown"
        value={formData.discountRule?.type}
        handleChange={handleChange}
        options={discountTypes}
        valueField={val => val.value ?? ""}
        displayField={val => val.name ?? ""}
      />
      <FormRow
        label="Value"
        name={nameof<DiscountRuleFormData>(p => p.value)}
        inputType="number"
        value={formData.discountRule?.value}
        handleChange={handleChange}
      />
      <FormVisibilityRules
        handleChange={handleChange}
        visibilityRules={formData.discountRule}
      />
    </div>);

  const getTitle = () => openConfirmPopup ? "Warning!" : "Edit Option Promotion";

  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );

  const getBody = () => openConfirmPopup ? closeOnDirtyDataConfirmBody : modalBody;

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      <Button onClick={onSave}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Save</Button>
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  const getButtons = () => openConfirmPopup ? confirmButtons : modalButtons;

  return (
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={getTitle()}
      body={getBody()}
      buttons={getButtons()}
    />
  );
}

export default OptionPromotionForm;