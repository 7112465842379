import classes from './BestSellersPage.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { getBestSellers, getBizOrders } from '../../api/statistics';
import { DataGrid, GridRowsProp, } from '@material-ui/data-grid';
import SearchToolbar, { SearchToolbarProps } from './BestSellerToolbar';
import columnsDefinitions from './BestSellersColumnsDefinitions';


export interface BestSellerInfo {
  name: string;
  count: number;
}


const BestSellersPage: React.FC = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAppLoading, setAppLoaded } = useActions();
  const [allRows, setAllData] = useState<BestSellerInfo[]>();
  const [rows, setRows] = useState<GridRowsProp>();

  const fetchByYearMonth = useCallback(async (year, month) => {
    setAppLoading();
    const serviceProviderUserId = businessInfo?.serviceProviderUserId;
    const businessId = businessInfo?.businessId;
    if (!serviceProviderUserId) return; if (!businessId) return;

    const res = await getBestSellers(serviceProviderUserId, businessId, year, month + 1);
    const fetchedRows = Object.entries(res.data)
      .map(([key, value], ind) => ({
        id: ind,
        name: key,
        count: value,
      } as BestSellerInfo));

    setAllData(fetchedRows);
    return fetchedRows;
  }, [businessInfo?.serviceProviderUserId, businessInfo?.businessId])

  useEffect(() => {
    const now = new Date();
    const currYear = now.getFullYear();
    fetchByYearMonth(currYear, -1)
      .then(firstRows => {
        setRows(firstRows);
        setAppLoaded();
      });
  }, []);

  return (
    <div className={classes.bestSellersPage}>

      <div className={classes.header}>
        <h1 className={classes.header_title}>
          Reports
        </h1>
      </div>

      <DataGrid
        components={{ Toolbar: SearchToolbar }}
        rows={rows ?? []}
        columns={columnsDefinitions}
        componentsProps={{
          toolbar: {
            fetchByYearMonth,
            setRows,
            allRows,
          } as SearchToolbarProps,
        }}
      />

    </div>
  );
}


export default BestSellersPage;