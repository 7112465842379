import { BusinessInfo, Channel, MenuByCategory, MenuProductDTO } from "./../../Types/Types";
import axios from "axios";
import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { baseUrl } from "../../appConfigs";


export const setAppLoading = () => {
  return {
    type: ActionType.SET_APP_LOADING
  }
}

export const setAppLoaded = () => {
  return {
    type: ActionType.SET_APP_LOADED
  }
}

export const fetchProducts = async (serviceProviderId: string | number, businessId?: string, channel?: Channel, dateTime?: number) => {
  if (businessId === undefined) {
    return null;
  }

  channel = channel ?? 'normal';
  dateTime = dateTime ?? 0;

  const params = `${serviceProviderId}/${businessId}/${channel}/${dateTime}`;
  const { data } = await axios.get(`${baseUrl}/rest/product/productsListByBusinessId/${params}`);

  return data;
}

export const fetchBusinessInfo = (businessName: string) => {

  return async (dispatch: Dispatch<Action>) => {
    let serviceProviderId;

    try {
      const { data } = await axios.get(
        `${baseUrl}/rest/business/${businessName}`
      );

      dispatch({
        type: ActionType.FETCH_BUSINESSINFO_SUCCESS,
        payload: data,
      });

      data.tip = data.tip ?? [0, 10, 15, 18];

      serviceProviderId = data.serviceProviderUserId;
    } catch (err) {
      alert('Business info cannot be retrieved, please check if url is correct.');
      window.location.href = `/notFound`;
    }

  };
};

export const fetchProductsAndCategories = (serviceProviderId: any, businessId: string | undefined) => {

  return async (dispatch: Dispatch<Action>) => {
    const productsById = (await fetchProductCategories(serviceProviderId, dispatch) ?? {}) as MenuByCategory;
    try {
      const data = await fetchProducts(serviceProviderId, businessId, 'admin');

      if (Array.isArray(data)) {
        data.forEach((product: MenuProductDTO) => {
          const category = productsById[product.category];
          if (category) {
            category.push(product);
          } else {
            console.error("Not existing category Id on product:", product, productsById);
          }
        });
      }

      dispatch({
        type: ActionType.FETCH_PRODUCTS_SUCCESS,
        payload: productsById,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_PRODUCTS_ERROR,
        payload: err.message,
      });
    }
  }
}




const fetchProductCategories = (serviceProviderId, dispatch?: Dispatch<Action>) => {
  const localDispatch = async (_dispatch: Dispatch<Action>) => {

    const productsById: MenuByCategory = {};
    try {
      const { data } = await axios.get(`${baseUrl}/rest/productCategory/admin/${serviceProviderId}`);

      if (Array.isArray(data)) {
        data.forEach((category: any) => {
          productsById[category.id] = [];
        });
      }

      _dispatch({
        type: ActionType.FETCH_FOODS_CATEGORIES_SUCCESS,
        payload: data,
      });
    } catch (err: any) {
      _dispatch({
        payload: err.message,
        type: ActionType.FETCH_FOODS_CATEGORIES_ERROR,
      });
    }
    return productsById;
  }

  if (!!dispatch) {
    return localDispatch(dispatch);
  }

  return localDispatch;
}

export const setBusinessInfo = (newBusinessInfo: BusinessInfo) => {
  return {
    type: ActionType.FETCH_BUSINESSINFO_SUCCESS,
    payload: newBusinessInfo,
  };
}

export {
  signInWithEmail,
  resetPassword,
  signOut,
  validateUserEmail,
  clearAccountErrors,
  setUserCredentials,
} from './account';

export {
  setBusinessPortalBreadcrumbs
} from './businessPortal';