import classes from "./BusinessConfigs.module.scss";
import OkCancelPopup from "../../components/Popup/OkCancelPopup";
import OverallPriceModifierEditForm, { OverallFormType } from "../../components/OverallPriceModifierEditForm/OverallPriceModifierEditForm";
import { useState } from "react";
import { DiscountRuleDTO, DISCOUNT_TYPE } from "../../Types/Types";
import { Paper } from '@material-ui/core';
import { OverallPriceAdjustmentTable } from "./OverallPriceAdjustmentTable";
import { useBizConfigNavBlocking } from "../../hooks/useBizConfigNavBlocking";
import BizConfigHeader from "./Header";
import { bizConfigLinks } from "../../components/SideNavigation/SideNavigation";


const PromotionAndCharges = () => {
  const [openDiscountModal, setOpenDiscountModal] = useState(false);
  const [openChargeModal, setOpenChargeModal] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<DiscountRuleDTO>();
  const [selectedCharge, setSelectedCharge] = useState<DiscountRuleDTO>();
  const [selectedType, setSelectedType] = useState(OverallFormType.Discount);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const {
    formData, setFormData, elements, saveBusinessInfo
  } = useBizConfigNavBlocking();


  const isWrongType = () => {
    if (selectedType === OverallFormType.Discount && (!selectedDiscount?.value && selectedDiscount?.value !== 0))
      return true;
    if (selectedType === OverallFormType.Charge && (!selectedCharge?.value && selectedCharge?.value !== 0))
      return true;

    return false;
  }

  const getConfirmBody = () => {
    if (isWrongType()) return;

    const priceAdjustment = selectedType === OverallFormType.Charge ? selectedCharge : selectedDiscount;
    const adjustmentType = selectedType === OverallFormType.Charge ? "charge" : "promotion";
    return <>
      <div>Do you really want to delete overall {adjustmentType}</div>
      <div>"{priceAdjustment?.title}"?</div>
    </>;
  };

  const deleteConfirmBody = getConfirmBody();

  const DeleteConfirmPopup = (
    <OkCancelPopup
      isOpen={deleteConfirmOpen}
      onCancel={() => setDeleteConfirmOpen(false)}
      onOk={() => {
        if (!formData) return;
        if (isWrongType()) return;

        const priceAdjustment = selectedType === OverallFormType.Charge ? selectedCharge : selectedDiscount;
        const indexToRemove = formData?.overallDiscounts?.indexOf(priceAdjustment!) ?? -1;
        if (indexToRemove === -1) return;
        formData?.overallDiscounts?.splice(indexToRemove, 1);
        saveBusinessInfo();
        setDeleteConfirmOpen(false);
      }}
      title="Please Confirm"
      content={deleteConfirmBody}
    />
  )

  const overallCharges = formData?.overallDiscounts?.filter(adj => adj.value < 0);
  const overallDiscounts = formData?.overallDiscounts?.filter(adj => adj.value >= 0);

  return (
    <section className={classes.promotionAndCharges}>

      {elements.ConfirmPopup}
      {elements.SnackbarElement}
      {DeleteConfirmPopup}

      <BizConfigHeader title={bizConfigLinks.promotionAndCharges.name} />


      {openDiscountModal &&
        <OverallPriceModifierEditForm
          formTitle="Overall Promotion"
          formType={OverallFormType.Discount}
          showModal={openDiscountModal}
          setModalOpen={setOpenDiscountModal}
          priceAdjustment={selectedDiscount}
          setBusinessInfo={setFormData}
          saveBusinessInfo={saveBusinessInfo}
          typeOptions={[
            { name: "", value: "" },
            { name: "Dine-in", value: DISCOUNT_TYPE.dineIn },
            { name: "Take-out", value: DISCOUNT_TYPE.takeOut },
            { name: "Pre-order", value: DISCOUNT_TYPE.preOrder },
            { name: "Mobile Pay", value: DISCOUNT_TYPE.mobilePay },
            { name: "Promo Code", value: DISCOUNT_TYPE.promoCode },
          ]}
        />}

      <Paper className={classes.table}>
        <OverallPriceAdjustmentTable
          typeName="Overall Promotions"
          tableType={OverallFormType.Discount}
          overallDiscounts={overallDiscounts ?? []}
          onAddBtnClick={() => {
            setSelectedType(OverallFormType.Discount);
            setSelectedDiscount(undefined);
            setOpenDiscountModal(true);
          }}
          onEditClick={discount => {
            setSelectedType(OverallFormType.Discount);
            setSelectedDiscount(discount);
            setOpenDiscountModal(true);
          }}
          onRemoveClick={discount => {
            setSelectedType(OverallFormType.Discount);
            setSelectedDiscount(discount);
            setDeleteConfirmOpen(true);
          }}
        />
      </Paper>

      {openChargeModal &&
        <OverallPriceModifierEditForm
          formTitle="Overall Charge"
          formType={OverallFormType.Charge}
          showModal={openChargeModal}
          setModalOpen={setOpenChargeModal}
          priceAdjustment={selectedCharge}
          setBusinessInfo={setFormData}
          saveBusinessInfo={saveBusinessInfo}
          typeOptions={[
            { name: "", value: "" },
            { name: "Dine-in", value: DISCOUNT_TYPE.dineIn },
            { name: "Take-out", value: DISCOUNT_TYPE.takeOut },
            { name: "Pre-order", value: DISCOUNT_TYPE.preOrder },
            { name: "Mobile Pay", value: DISCOUNT_TYPE.mobilePay },
          ]}
        />}

      <Paper className={classes.table}>
        <OverallPriceAdjustmentTable
          typeName="Overall Charges"
          tableType={OverallFormType.Charge}
          overallDiscounts={overallCharges ?? []}
          onAddBtnClick={() => {
            setSelectedType(OverallFormType.Charge);
            setSelectedCharge(undefined);
            setOpenChargeModal(true);
          }}
          onEditClick={charge => {
            setSelectedType(OverallFormType.Charge);
            setSelectedCharge(charge);
            setOpenChargeModal(true);
          }}
          onRemoveClick={charge => {
            setSelectedType(OverallFormType.Charge);
            setSelectedCharge(charge);
            setDeleteConfirmOpen(true);
          }}
        />
      </Paper>


    </section>
  )
}

export default PromotionAndCharges;
