import classes from './FormVisibilityRules.module.scss';
import CheckboxLabels from "../FormControls/CheckboxLabels";
import { FormGroup } from "@material-ui/core";
import { nameof } from "ts-simple-nameof";
import { CheckBoxChange, DaysCheckboxesType, LocalVisibility, VisibilityRules } from "../../Types/Types";

export interface DaysCheckboxesProps {
  data: DaysCheckboxesType;
  handleChange: Function | CheckBoxChange;
}

 const DaysCheckboxes: React.FC<DaysCheckboxesProps> = (props) => {
  const { data, handleChange } = props;
  const handleCheckboxChange = handleChange as CheckBoxChange;

  return (
    <FormGroup className={classes.daysCheckboxes}>
      <FormGroup row>
        <CheckboxLabels label="Sun" name={nameof<LocalVisibility>(p => p.Sun)}
          checkOnChange={handleCheckboxChange} value={data.Sun} />
        <CheckboxLabels label="Mon" name={nameof<LocalVisibility>(p => p.Mon)}
          checkOnChange={handleCheckboxChange} value={data.Mon} />
        <CheckboxLabels label="Tue" name={nameof<LocalVisibility>(p => p.Tue)}
          checkOnChange={handleCheckboxChange} value={data.Tue} />
      </FormGroup>
      <FormGroup row>
        <CheckboxLabels label="Wed" name={nameof<LocalVisibility>(p => p.Wed)}
          checkOnChange={handleCheckboxChange} value={data.Wed} />
        <CheckboxLabels label="Thu" name={nameof<LocalVisibility>(p => p.Thu)}
          checkOnChange={handleCheckboxChange} value={data.Thu} />
        <CheckboxLabels label="Fri" name={nameof<LocalVisibility>(p => p.Fri)}
          checkOnChange={handleCheckboxChange} value={data.Fri} />
      </FormGroup>
      <FormGroup row>
        <CheckboxLabels label="Sat" name={nameof<VisibilityRules>(p => p.Sat)}
          checkOnChange={handleCheckboxChange} value={data.Sat} />
      </FormGroup>
    </FormGroup>
  );
};

export default DaysCheckboxes;
