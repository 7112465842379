import { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableRow } from '@material-ui/core';


interface SortableTableRowProps {
  content: (item, listeners) => React.ReactNode;
  item: any;
  sortIndex: string;
  styles?: CSSProperties;
  className?: string;
}

const SortableTableRow: React.FC<SortableTableRowProps> = (props) => {
  const { item, content, sortIndex, styles, className } = props;

  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id: sortIndex });

  const style: CSSProperties = {
    ...styles,
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
  };

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className={className}
      role="row"
      hover
      >
      {content(item, listeners)}
    </TableRow>
  );
};

export default SortableTableRow;
