import classes from "./BusinessPortal.module.scss";
import {
  Route, Switch, useHistory, useLocation, useParams, useRouteMatch
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { getCookie } from "../common/cookies";
import { Segment } from "semantic-ui-react";
import { Button } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import SideNavigation, { bizConfigLinks, links } from "../components/SideNavigation/SideNavigation";
import Loader from "../components/Loader/Loader";
import AccountPage from "./account/account";
import MenuPage from "./MenuPage/MenuPage";
import PortalHeader from "../components/PortalHeader";
import GlobalModal from "../components/GlobalModal/GlobalModal";
import ReportPage from "./ReportPage/ReportPage";
import DashboardPage from "./Dashboard/DashboardPage";
import BestSellersPage from "./BestSellersPage/BestSellersPage";

export interface BusinessPortalUrlParams {
  businessName: string;
}

const BusinessPortal: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { businessName } = useParams<BusinessPortalUrlParams>();
  const { path, url } = useRouteMatch();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const account = useTypedSelector(state => state.account);
  const [showModal, setShowModal] = useState(false);
  const {
    fetchBusinessInfo, fetchProductsAndCategories, setUserCredentials, setAppLoading, setAppLoaded
  } = useActions();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setAppLoading();
    setUserCredentials();
    fetchBusinessInfo(businessName);
  }, [businessName]);

  const businessId = businessInfo?.businessId;
  const serviceProviderUserId = businessInfo?.serviceProviderUserId;
  useEffect(() => {
    if (businessId !== undefined) {
      fetchProductsAndCategories(serviceProviderUserId, businessId);
    }
  }, [serviceProviderUserId, businessId]);


  useEffect(() => {
    setLoading(false);
    if (businessInfo) {
      setAppLoaded();
    }
  }, [businessInfo])

  useEffect(() => {
    const cookieCredentials = getCookie("btoa");
    const isSignedIn = cookieCredentials ?? account.isSignedIn;
    const splitPath = location.pathname.split('/');
    const pathFirstPart = splitPath[2];
    const isAccountPath = pathFirstPart === "account";
    const isChangePassPath = splitPath[3] === "changePassword";

    if (!isAccountPath && !isSignedIn) {
      history.push(`${url}/account`);
    } else if (isAccountPath && !isChangePassPath && isSignedIn) {
      history.push(`${url}/dashboard`);
    } else if (isSignedIn && !pathFirstPart) {
      history.push(`${url}/dashboard`);
    }
  }, [account, location])


  const menuEditPath = `${path}${links.menuEdit.url}${links.menuEdit.params}`;
  const reportsPath = `${path}/reports`;
  const bestSellersPath = `${path}/bestSellers`;
  const dashboardPath = `${path}/dashboard`;
  const businessEditPath = `${path}/businessEdit`;
  const allPaths = [path, menuEditPath, businessEditPath, reportsPath, dashboardPath];

  const modalBody = "";

  const newBusinessPopup = (
    <div className={classes.newBusiness}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircle />}
      >
        Add your Business
      </Button>
      <GlobalModal
        open={showModal}
        handleClose={e => setShowModal(false)}
        title="Add your Business"
        body={modalBody}
      />
    </div>
  );

  return (
    <div className={classes.container}>
      {isLoading && <Loader />}
      <Switch>

        <Route path={`${path}/account`}>
          <AccountPage />
        </Route>

        {/* <Route path={allPaths}> */}
        <Route>

          <SideNavigation open={true} />

          <div className={classes.page}>

            <Route path={menuEditPath}>
              <Segment basic>
                <PortalHeader />
              </Segment>
              <MenuPage />
            </Route>

            <Route path={reportsPath}>
              <ReportPage />
            </Route>

            <Route path={bestSellersPath}>
              <BestSellersPage />
            </Route>

            <Route path={dashboardPath}>
              <DashboardPage />
            </Route>

            <BizConfigRoutes />

          </div>

          {/* </> } */}
          {/* {isBusinessInfoFetched && !businessInfo && newBusinessPopup} */}

        </Route>

      </Switch>
    </div>
  );
}

const BizConfigRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>

      {Object.values(bizConfigLinks).map(linkConfig => {
        const url = `${path}${linkConfig.url}`;
        return <Route key={linkConfig.name} path={url} component={linkConfig.component} />;
      })}

    </Switch>
  )
}


export default BusinessPortal;
