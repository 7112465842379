import classes from "./BusinessConfigs.module.scss";
// import { nameof } from "ts-simple-nameof";
import PopupForm from "../../components/PopupForm/PopupForm";
import TextLabelRow from "../../components/TextLabelRow/TextLabelRow";
import { CSSProperties, Dispatch, SetStateAction, useState } from "react";
import { Address, BusinessInfo, ValidationError } from "../../Types/Types";
import { cityErrorMsg } from "../../constants/addressValidationErrors";


const initAddress = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  country: "",
  crossCountryUnion: "",
  googleMapUri: "",
  postalCode: "",
  province: "",
  state: "",
  suiteNumber: "",
};

interface AddressFormProps {
  isOpenState: [boolean, Dispatch<SetStateAction<boolean>>];
  // setFormData: Dispatch<SetStateAction<BusinessInfo | undefined>>;
  saveBusinessInfo: (newBizInfo?: BusinessInfo) => Promise<void>;
  formData?: BusinessInfo;
}

interface RowOptions {
  label: string;
  fieldName: string;
  inputStyle?: CSSProperties;
}

const AddressForm = (props: AddressFormProps) => {
  const { formData, saveBusinessInfo } = props;
  const [isOpenState, setOpenState] = props.isOpenState;
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);

  const clone = (address: Address) => {

    return { ...address };
  }

  const fieldHasError = (fieldName?: string) => {
    const error = validationErrors.find(vErr => vErr.fieldName === fieldName);
    return error;
  }

  const addValidationError = (fieldName: string, errorMsg: string) => {
    validationErrors.push({ fieldName, errorMsg });
    setValidationErrors([...validationErrors]);
  }

  const validateForm = (address: Address) => {
    if (!address.city) {
      addValidationError("city", cityErrorMsg);
      return false;
    }

    return true;
  }

  return <PopupForm<Address>
    title="Update Address"
    setValidationErrors={setValidationErrors}
    clone={clone}
    initData={formData?.location ?? initAddress}
    modalOpen={isOpenState}
    setModalOpen={setOpenState}
    onSaveData={async (address) => {
      if (!formData) return;

      const isValid = validateForm(address);
      if (isValid === false) return;

      saveBusinessInfo({
        ...formData,
        location: { ...address }
      })
      setOpenState(false);
    }}
    bodyContent={(handleChange, address) => {

      const Row = (options: RowOptions) => {
        const { fieldName, label, inputStyle } = options;

        const error = fieldHasError(fieldName);
        const value = address ? address[fieldName] : "";
        return <TextLabelRow
          label={label}
          hasError={!!error}
          helperText={error?.errorMsg}
          name={fieldName}
          value={value}
          inputStyle={inputStyle}
          handleChange={e => handleChange(e.target.name, e.target.value)}
        />
      }

      return (
        <>

          {Row({
            label: "Address Line 1",
            fieldName: "addressLine1",
          })}

          {Row({
            label: "Address Line 2",
            fieldName: "addressLine2",
          })}

          {Row({
            label: "Suite Number",
            fieldName: "suiteNumber",
          })}

          {Row({
            label: "City *",
            fieldName: "city",
          })}

          {Row({
            label: "Province",
            fieldName: "state",
          })}

          {Row({
            label: "Country",
            fieldName: "country",
          })}

          {Row({
            label: "Cross Country Union",
            fieldName: "crossCountryUnion",
          })}

          {Row({
            label: "Postal Code",
            fieldName: "postalCode",
          })}

          {Row({
            label: "Map URI",
            fieldName: "googleMapUri",
            inputStyle: {
              width: "100%"
            },
          })}


        </>
      )
    }}
  />
}

export default AddressForm;