import classes from './OptionList.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useActions } from '../../../hooks/useActions';
import { MenuEditUrlParams } from '../../../components/PortalHeader';
import { MenuProductDTO, ProductOptionDTO } from '../../../Types/Types';
import { Button, Card } from 'semantic-ui-react';
import { addOption, getOptionsByProduct, postOptionImage, updateOptionList } from '../../../api/optionsList';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { getProduct, getProductsByCategory } from '../../../api/productList';
import OptionForm from '../../../components/OptionForms/OptionForm';
import BreadcrumbLink from '../../../components/BreadcrumbLink/BreadcrumbLink';
import OptionInventoryForm from '../../../components/OptionForms/OptionInventoryForm';
import OptionPromotionForm from '../../../components/OptionForms/OptionPromotionForm';
import ImgUploadForm from '../../../components/ImageUploadForm/ImgUploadForm';
import FramedImage from '../../../components/MenuListImage/FramedImage';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import SortableCard from '../SortableCard';
import {
  closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import { reorderList } from '../../../common/pageUtils';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const OptionsList: React.FC = () => {
  const { setAppLoading, setAppLoaded } = useActions();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { categoryId, productId } = useParams<MenuEditUrlParams>();
  const [product, setProduct] = useState<MenuProductDTO>();
  const [categoryProducts, setCategoryProducts] = useState<MenuProductDTO[]>([]);
  const [prodOptions, setProdOptions] = useState<ProductOptionDTO[]>([]);
  const [optionId, setOptionId] = useState<number>();
  const [openEditAddModal, setEditAddModal] = useState(false);
  const [openInventoryModal, setInventoryModal] = useState(false);
  const [openPromotionModal, setPromotionModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isImgAdd, setIsImgAdd] = useState(false);
  const [imgPreview, setImgPreview] = useState("");
  const [imageToUpload, setImgToUpload] = useState<File>();

  const fetchOptions = useCallback(async () => {
    if (!productId) return;
    setAppLoading();

    getProduct(productId).then(res => setProduct(res.data));

    const res = await getOptionsByProduct(productId);
    const productOptions = res.data as ProductOptionDTO[];

    const isSortNotSet = !productOptions.some(option => option.sortingOrder > 0);
    if ( isSortNotSet) {
      productOptions.forEach((option, ind) => option.sortingOrder = ind);
    }

    const sorted = productOptions.sort(
      (firstProd, secondProd) => firstProd.sortingOrder - secondProd.sortingOrder
    );
    setProdOptions(sorted);
    setAppLoaded();
  }, [productId])

  const anyModalOpen = openEditAddModal || openInventoryModal
    || openPromotionModal || openUploadModal;

  const fetchProducts = useCallback(async () => {
    if (anyModalOpen) return;
    if (!categoryId) return;
    if (!businessInfo?.serviceProviderUserId) return;
    const res = await getProductsByCategory(categoryId, businessInfo.serviceProviderUserId);
    const productsByCategory = res.data as MenuProductDTO[];
    const sorted = productsByCategory.sort(
      (firstProd, secondProd) => firstProd.sortingOrder - secondProd.sortingOrder
    );

    setCategoryProducts(sorted);
  }, [categoryId, businessInfo?.serviceProviderUserId, anyModalOpen])

  useEffect(() => {
    if (anyModalOpen) return;
    setOptionId(undefined);
    fetchOptions();
    fetchProducts();
  }, [fetchOptions, anyModalOpen, businessInfo?.serviceProviderUserId])

  const onImgEditClicked = (option: ProductOptionDTO) => {
    setIsImgAdd(false);
    setOpenUploadModal(true);
    setImgPreview(option.optionImage ?? "");
    setOptionId(option.id);
  }

  const uploadImage = async () => {
    if (!imageToUpload) return;
    await postOptionImage(imageToUpload, optionId);
    setOpenUploadModal(false);
  }

  const changeOrder = (event) => {
    const { active, over } = event;
    const oldIndex = Number(active.id);
    const newIndex = Number(over.id);
    if (oldIndex === newIndex) return;

    const sortedList = reorderList(prodOptions, oldIndex, newIndex);

    setProdOptions([...sortedList]);

    if (!product) return;
    updateOptionList({
      ...product,
      options: prodOptions
    });
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const optionToCard = (option: ProductOptionDTO, listeners, index: number) => {
    return (
      <Card key={option.id ?? option.label}>
        <Card.Content>
          <aside className={classes.optionList_aside}>
            <FramedImage
              onImgEditClicked={e => onImgEditClicked(option)}
              image={option.optionImage}
            />
          </aside>
          <Card.Meta>
            {index + 1}/{prodOptions.length}
            <IconButton {...listeners}>
              <ViewWeekIcon />
            </IconButton>
            <Button
              onClick={async e => {
                const clonedOption = {
                  ...option,
                  label: `${option.label} Copy`
                } as any;
                delete clonedOption.id;
                clonedOption.sortingOrder = prodOptions.length;
                const res = await addOption(clonedOption);
                fetchOptions();
              }} >
              Clone
            </Button>
          </Card.Meta>

          <Card.Header>
            {option.label}
            <IconButton
              onClick={e => {
                if (!option.id) return;
                setOptionId(option.id);
                setEditAddModal(true);
              }} >
              <Edit />
            </IconButton>
          </Card.Header>
          <Card.Meta>${(option.price / 100).toFixed(2)}</Card.Meta>
          <Card.Meta>{option.optionDescription}</Card.Meta>
        </Card.Content>
        <Card.Content extra>

          <div className={classes.optionList_buttons}>
            <Button
              onClick={e => {
                setOptionId(option.id);
                setPromotionModal(true);
              }} >
              Edit Promotions
            </Button>
            <Button
              onClick={e => {
                setOptionId(option.id);
                setInventoryModal(true);
              }} >
              Edit Inventory
            </Button>
            <BreadcrumbLink
              isButton
              displayName={option.label}
              pathName={`/menuEdit/${categoryId}/${productId}/${option.id}`}
            >
              Manage Customization
            </BreadcrumbLink>
          </div>

        </Card.Content>
      </Card>
    )
  }

  // const renderList = () => {
  //   return prodOptions.map((option) => {
  //     return (
  //       <Card key={option.id ?? option.label}>
  //         <Card.Content>
  //           <FramedImage
  //             onImgEditClicked={e => onImgEditClicked(option)}
  //             image={option.optionImage}
  //           />
  //           <Card.Header>
  //             {option.label}
  //             <IconButton
  //               onClick={e => {
  //                 if (!option.id) return;
  //                 setOptionId(option.id);
  //                 setEditAddModal(true);
  //               }} >
  //               <Edit />
  //             </IconButton>
  //           </Card.Header>
  //           <Card.Meta>${(option.price / 100).toFixed(2)}</Card.Meta>
  //           <Card.Meta>{option.optionDescription}</Card.Meta>
  //         </Card.Content>
  //         <Card.Content extra>

  //           <div className={classes.optionList_buttonRow}>
  //             <Button
  //               onClick={e => {
  //                 setOptionId(option.id);
  //                 setPromotionModal(true);
  //               }} >
  //               Edit Promotions
  //             </Button>
  //           </div>
  //           <div className={classes.optionList_buttonRow}>
  //             <Button
  //               onClick={e => {
  //                 setOptionId(option.id);
  //                 setInventoryModal(true);
  //               }} >
  //               Edit Inventory
  //             </Button>
  //           </div>
  //           <div className={classes.optionList_buttonRow}>
  //             <BreadcrumbLink
  //               isButton
  //               displayName={option.label}
  //               pathName={`/menuEdit/${categoryId}/${productId}/${option.id}`}
  //             >
  //               Manage Customization
  //             </BreadcrumbLink>
  //           </div>

  //         </Card.Content>
  //       </Card>
  //     );
  //   });
  // }

  return (
    <section className={classes.optionList}>
      <div className={classes.optionList_addNew_button}>
        <Button onClick={e => setEditAddModal(true)}>
          Add New Menu Item
        </Button>
      </div>

      <DndContext
        onDragEnd={changeOrder}
        collisionDetection={closestCenter}
        sensors={sensors}>
        <SortableContext
          items={prodOptions.map((option, index) => index.toString())}
        >
          <Card.Group>
            {prodOptions.map((prodOption, index) =>
              <SortableCard
                key={prodOption.id}
                item={prodOption}
                content={(option, listeners) => optionToCard(option, listeners, index)}
                styles={{ margin: "10px", }}
                sortIndex={index.toString()}
              />)}
          </Card.Group>
        </SortableContext>
      </DndContext>

      <ImgUploadForm
        isOpen={openUploadModal}
        onValidImage={(file) => {
          setImgToUpload(file);
        }}
        currentImage={imgPreview}
        onOk={uploadImage}
        onCancel={e => {
          setImgPreview("")
          setOpenUploadModal(false);
          setImgToUpload(undefined);
        }}
        isImgAdd={isImgAdd}
      />

      {openEditAddModal &&
        <OptionForm
          showModal={openEditAddModal}
          setModalOpen={setEditAddModal}
          optionId={optionId}
          productId={Number(productId)}
          latestSortingOrder={prodOptions.length}
          categoryProducts={categoryProducts}
        />}
      {openInventoryModal &&
        <OptionInventoryForm
          showModal={openInventoryModal}
          setModalOpen={setInventoryModal}
          optionId={optionId}
        />}
      {openPromotionModal &&
        <OptionPromotionForm
          showModal={openPromotionModal}
          setModalOpen={setPromotionModal}
          optionId={optionId}
        />}
    </section>
  );
};

export default OptionsList;