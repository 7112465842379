import { Breadcrumb } from '../../Types/Types';
import { ActionType } from '../action-types';
import { Action } from '../actions';



interface BusinessPortalState {
  breadcrumbs: Breadcrumb[];
}

const initialState = {
  breadcrumbs: [],
};

const reducer = (
  state: BusinessPortalState = initialState,
  action: Action
): BusinessPortalState => {
  switch (action.type) {
    case ActionType.SET_BUSINESS_PORTAL_BREADCRUMBS:
      return { breadcrumbs: action.payload };
    default:
      return state;
  }
};

export default reducer;
