import classes from './FramedImage.module.scss';
import { IconButton } from '@material-ui/core';
import { CSSProperties, MouseEventHandler } from 'react';
import { Image } from 'semantic-ui-react';
import { convertImageSrc } from '../../common/pageUtils';
import { Edit } from '@material-ui/icons';

export interface FramedImageProps {
  onImgEditClicked: MouseEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLDivElement> | undefined;
  image?: string;
  isCard?: boolean;
}

const FramedImage: React.FC<FramedImageProps> = (props) => {
  const { onImgEditClicked, image, isCard = true } = props;
  const imageWithBaseUrl = convertImageSrc(image);

  const cardStyle: CSSProperties = isCard ? {} : {
    float: "unset",
  }

  const editIconCSS: CSSProperties = isCard ? {
  } : {
    position: "absolute",
    top: "40px",
    left: "30px",
  }

  const srcWithRandomNum = `${imageWithBaseUrl}?v=${Math.floor(Math.random() * 1000)}`;
  return (
    <div className={classes.imgCard} style={cardStyle}>
      <div
        className={classes.container}
        onClick={onImgEditClicked as MouseEventHandler<HTMLDivElement>}
      >
        <Image size='tiny'
          src={srcWithRandomNum}
          rounded
        />
        <IconButton
          onClick={onImgEditClicked as MouseEventHandler<HTMLButtonElement>}
          className={classes.editIcon}
          style={editIconCSS}>
          <Edit />
        </IconButton>
      </div>

    </div>
  );
}

export default FramedImage;