import classes from "./TextLabelRow.module.scss";
import { FormGroup, TextField } from "@material-ui/core";
import { ChangeEvent, CSSProperties } from "react";

export interface TextLabelRowProps {
  value?: string;
  label: string;
  name: string;
  hasError?: boolean;
  placeholder?: string;
  maxLength?: number;
  helperText?: string;
  inputStyle?: CSSProperties;
  handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const TextLabelRow = (props: TextLabelRowProps) => {
  const {
    label, value, placeholder, name, helperText,
    maxLength, inputStyle,
    handleChange,
    hasError = false,
  } = props;

  return <FormGroup row className={classes.row}>

    <label className={classes.label}>
      {label}
    </label>

    <div className={classes.inputWrap}>
      <TextField
        className={classes.input}
        name={name}
        type="text"
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        error={hasError}
        helperText={helperText}
        style={inputStyle}
        inputProps={{
          maxLength,
        }} />
    </div>

  </FormGroup>;
};

export default TextLabelRow;