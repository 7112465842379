import { ReactNode, ChangeEvent } from "react";
import { updateBusinessInfo } from "../api/businessEdit";
import { minPaperBagFee } from "../appConfigs";
import { BusinessInfo } from "../Types/Types";
import { daysMapping, nullReplacer } from "./pageUtils";


export const cloneBusinessInfo = (businessInfo: BusinessInfo) => {

  const workingHours = businessInfo.workingHours ?? [];
  Object.keys(daysMapping).forEach(shortName => {
    const dayWorkingHour = workingHours.find(wh => wh.days === shortName);
    if (dayWorkingHour) return;
    workingHours.push({
      days: shortName,
      startTime: 800,
      endTime: 2000,
      open: true
    })
  })

  const clonedBI: BusinessInfo = {
    ...businessInfo,
    paperBagFee:  businessInfo.paperBagFee ?? minPaperBagFee,
    paymentGateway: businessInfo.paymentGateway ?? "square",
    payInPersonDisabled: businessInfo.payInPersonDisabled ?? false,
    menuItemCollapsible: businessInfo.menuItemCollapsible ?? false,
    announcement: { ...businessInfo.announcement },
    diningOption: { ...businessInfo.diningOption },
    prepTimeConfig: { ...businessInfo.prepTimeConfig },
    workingHours: JSON.parse(JSON.stringify(workingHours)),
    theme: { ...businessInfo?.theme },
    overallDiscounts: [...businessInfo.overallDiscounts ?? []],
    tip: [...businessInfo?.tip ?? []],
    businessLabels: [...businessInfo?.businessLabels ?? []],
  }
  clonedBI.theme.color = clonedBI.theme.color || "#000000";
  return clonedBI;
}

export const postBusinessInfo = async (onSuccess: Function, onFail: Function, newBusinessData?: BusinessInfo) => {
  if (!newBusinessData) return;

  try {
    await updateBusinessInfo(newBusinessData);
    onSuccess();
  } catch (error) {
    console.error(error);
    onFail();
  }
};

export type SelectType = ChangeEvent<{ name?: string | undefined; value: unknown; }>;
export interface SnackbarSettings {
  message: ReactNode;
  severity: "success" | "error";
}

export const successSnackbar: SnackbarSettings = {
  severity: "success",
  message: "Save Success!",
}

export const errorSnackbar: SnackbarSettings = {
  severity: "error",
  message: "Something went wrong...",
}

export const validationErrorSnackbar: SnackbarSettings = {
  severity: "error",
  message: "Some fields have incorrect content.",
}

export const isEqual = (first, second) => {
  const originalJsonStr = JSON.stringify(first, nullReplacer);
  const dirtiedJsonStr = JSON.stringify(second, nullReplacer);
  let whEqual = true;
  if (first?.workingHours) {
    const firstWhs = JSON.stringify(first.workingHours, nullReplacer);
    const secondWhs = JSON.stringify(second.workingHours, nullReplacer);
    whEqual = firstWhs === secondWhs;
  }

  return originalJsonStr === dirtiedJsonStr && whEqual;
}

export const isDirty = (first, second) => {
  return !isEqual(first, second);
}
