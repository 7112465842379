import classes from './CategoryList.module.scss';
import { useState, useEffect } from "react";
import BreadcrumbLink from "../../../components/BreadcrumbLink/BreadcrumbLink";
import CategoryForm from './CategoryForm';
import { FoodCategory } from "../../../Types/Types";
import { getAllCategories, postCategoryImage } from '../../../api/categoryList';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Button, Card } from 'semantic-ui-react';
import { useActions } from '../../../hooks/useActions';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import ImgUploadForm from '../../../components/ImageUploadForm/ImgUploadForm';
import FramedImage from '../../../components/MenuListImage/FramedImage';

interface MenuEditProps {
}

const CategoryList: React.FC<MenuEditProps> = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAppLoading, setAppLoaded } = useActions();
  const [categories, setCategories] = useState<FoodCategory[]>([]);
  const [categoryId, setCategoryId] = useState<number>();
  const [showModal, setModalOpen] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isImgAdd, setIsImgAdd] = useState(false);
  const [imgPreview, setImgPreview] = useState("");
  const [imageToUpload, setImgToUpload] = useState<File>();

  const fetchCategories = async () => {
    if (showModal || openUploadModal) return;
    if (!businessInfo) return;
    setCategoryId(undefined);
    setAppLoading();
    const res = await getAllCategories(businessInfo.serviceProviderUserId);
    const categories = res.data;
    setCategories(categories);
    setAppLoaded();
  }

  useEffect(() => {
    fetchCategories();
  }, [showModal, businessInfo, openUploadModal]);

  const handleEdit = (id?: number) => {
    setCategoryId(id);
    setModalOpen(true);
  };

  const onImgEditClicked = (category: FoodCategory) => {
    setIsImgAdd(false);
    setOpenUploadModal(true);
    setImgPreview(category.defaultProductImage ?? "");
    setCategoryId(category.id);
  }

  const uploadImage = async () => {
    if (!imageToUpload) return;
    await postCategoryImage(imageToUpload, categoryId);
    setOpenUploadModal(false);
  }

  const renderList = () => {
    return categories.map((category) => {

      return (
        <Card key={category.id}>
          <Card.Content>
            <FramedImage
              onImgEditClicked={e => onImgEditClicked(category)}
              image={category.defaultProductImage}
            />
            <Card.Header>
              {category.name}
              <IconButton
                onClick={e => handleEdit(category.id)} >
                <Edit />
              </IconButton>
            </Card.Header>
            <Card.Meta>{category.description}</Card.Meta>
          </Card.Content>
          <Card.Content extra>
            <div className={classes.categoryList_buttonRow}>
              <BreadcrumbLink
                isButton
                displayName={category.name}
                pathName={`/menuEdit/${category.id}`}
              >
                Manage Menu Item Categories
              </BreadcrumbLink>
            </div>
          </Card.Content>
        </Card>
      );
    });
  };

  return (
    <section className={classes.categoryList}>

      <ImgUploadForm
        isOpen={openUploadModal}
        onValidImage={(file) => {
          setImgToUpload(file);
        }}
        currentImage={imgPreview}
        onOk={uploadImage}
        onCancel={e => {
          setImgPreview("")
          setOpenUploadModal(false);
          setImgToUpload(undefined);
        }}
        isImgAdd={isImgAdd}
      />

      <div className={classes.categoryList_addNew_button}>
        <Button onClick={e => setModalOpen(true)}>
          Add New Menu Category
        </Button>
      </div>

      <Card.Group>{renderList()}</Card.Group>

      {showModal && <CategoryForm
        showModal={showModal}
        setModalOpen={setModalOpen}
        categoryId={categoryId}
      />}
    </section>
  );
};

export default CategoryList;
