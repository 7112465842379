import classes from './ProductList.module.scss';
import GlobalModal from '../../../components/GlobalModal/GlobalModal';
import FormRow from '../../../components/FormRow/FormRow';
import FormVisibilityRules from '../../../components/FormVisibilityRules/FormVisibilityRules';
import { nameof } from 'ts-simple-nameof';
import { useState, ChangeEvent, useEffect } from "react";
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Button } from '@material-ui/core';
import { ProductFormData, MenuProductDTO } from '../../../Types/Types';
import { daysFields, nullReplacer, numberToString, timeStrToNumber } from '../../../common/pageUtils';
import { addProduct, deleteProduct, getProduct, updateProduct } from '../../../api/productList';

interface ProductFormProps {
  showModal: boolean;
  setModalOpen: Function;
  categorySelectOptions: CategorySelectOption[];
  categoryId: number;
  productId?: number;
}

export type CategorySelectOption = { id: number; name: string }

const ProductForm: React.FC<ProductFormProps> = (props) => {
  const { setAppLoading, setAppLoaded } = useActions();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { showModal, setModalOpen, productId, categoryId, categorySelectOptions } = props;
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isEdit] = useState(productId ? true : false);
  const [nameValidationError, setNameValidationError] = useState("");
  const [formData, setFormData] = useState<ProductFormData>({
    serviceProviderUserId: Number(businessInfo?.serviceProviderUserId),
    name: "",
    featured: false,
    optionsType: "",
    category: categoryId,
    description: "",
    sortingOrder: 0,
    visibilityRules: {
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      Sun: false, Mon: false,
      Tue: false, Wed: false,
      Thu: false, Fri: false, Sat: false,
      type: ""
    }
  });

  const [originalData, setOriginalData] = useState<ProductFormData>({
    ...formData,
    visibilityRules: { ...formData.visibilityRules }
  });

  const fetchProductAsync = async () => {
    if (!productId) return;
    const res = await getProduct(productId);

    const mapped = Object.assign({}, res.data as MenuProductDTO);
    let visibilityRule = Object.assign({}, formData.visibilityRules) as any;
    visibilityRule = Object.assign(visibilityRule, mapped.visibilityRuleJson);
    visibilityRule.days && visibilityRule.days.forEach(day => {
      visibilityRule[day] = true;
    })

    delete visibilityRule.days;

    visibilityRule.endTime = numberToString(visibilityRule.endTime);
    visibilityRule.startTime = numberToString(visibilityRule.startTime);

    const mappedData = { ...mapped, visibilityRules: visibilityRule };
    const originalData = Object.assign({}, mappedData);
    originalData.visibilityRules = Object.assign({}, originalData.visibilityRules);
    setFormData(mappedData);
    setOriginalData(originalData);
  }

  const fetchData = async () => {
    setAppLoading();
    await fetchProductAsync();
    setAppLoaded();
  }

  useEffect(() => {
    fetchData();
  }, [productId, businessInfo?.serviceProviderUserId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const nameProp = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const visibilityRules = formData.visibilityRules;
    if (formData.hasOwnProperty(nameProp)) {
      setFormData({ ...formData, [nameProp]: value });
      return;
    }

    if (visibilityRules.hasOwnProperty(nameProp)) {
      const newVisibility = formData.visibilityRules;
      newVisibility[nameProp] = value;
      setFormData({ ...formData, visibilityRules: newVisibility });
      return;
    }

    console.warn(`No prop on formData or visibilityRules called ${nameProp}!`);
  }

  const modalBody = (
    <div className={classes.form}>
      <FormRow
        label="Featured"
        name={nameof<ProductFormData>(p => p.featured)}
        inputType="checkbox"
        value={formData.featured}
        handleChange={handleChange}
      />
      <FormRow
        label="Name"
        name={nameof<ProductFormData>(p => p.name)}
        inputType="text"
        value={formData.name}
        placeholder="Menu Name"
        handleChange={handleChange}
      />
      <div>{nameValidationError}</div>
      <FormRow
        label="Description"
        name={nameof<ProductFormData>(p => p.description)}
        inputType="textarea"
        value={formData.description}
        placeholder="Description"
        handleChange={handleChange}
      />
      <FormRow
        label="Option type"
        name={nameof<ProductFormData>(p => p.optionsType)}
        inputType="dropdown"
        options={["None", "Adds-on", "Size"]}
        valueField={val => val ?? ""}
        value={formData.optionsType}
        handleChange={handleChange}
      />
      <FormRow<CategorySelectOption>
        label="Tab"
        name={nameof<ProductFormData>(p => p.category)}
        inputType="dropdown"
        options={categorySelectOptions}
        value={formData.category}
        displayField={val => val.name}
        valueField={val => val.id}
        handleChange={handleChange}
      />
      <FormVisibilityRules
        visibilityRules={formData.visibilityRules}
        handleChange={handleChange} />
    </div>);

  const getFormDtoSnapshot = () => {
    const days: string[] = [];
    daysFields.forEach(day => {
      if (formData.visibilityRules[day] !== true) return;
      days.push(day);
    })

    const formDTO: MenuProductDTO = {
      id: formData.id,
      description: formData.description,
      name: formData.name,
      image: formData.image,
      category: formData.category,
      sortingOrder: formData.sortingOrder,
      optionsType: formData.optionsType,
      featured: formData.featured,
      serviceProviderUserId: formData.serviceProviderUserId,
      visibilityRuleJson: {
        startTime: timeStrToNumber(formData.visibilityRules.startTime),
        endTime: timeStrToNumber(formData.visibilityRules.endTime),
        startDate: formData.visibilityRules.startDate,
        endDate: formData.visibilityRules.endDate,
        days: days,
        type: formData.visibilityRules.type,
      },
    }

    return formDTO;
  }

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData, nullReplacer);
    const dirtiedJsonStr = JSON.stringify(formData, nullReplacer);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const onAdd = async (e) => {
    if (productId) return;
    if (!validateForm()) return;
    const newProduct = getFormDtoSnapshot();

    try {
      setAppLoading();
      const res = await addProduct(newProduct);
      if (res.status === 200 && !isNaN(Number(res.data))) {
        setModalOpen(false);
        setOpenDeleteConfirm(false);
      }
    } catch (err) {
      console.error("Add product failed: ", err);
    } finally {
      setAppLoaded();
    }
  }

  const onSave = async (e) => {
    if (!productId) return;
    if (!validateForm()) return;

    const updatedProduct = getFormDtoSnapshot();
    try {
      setAppLoading();
      const res = await updateProduct(updatedProduct);
      if (res.data === "success") {
        setModalOpen(false);
      }
    } catch (err) {
      console.error("Something went wrong with updating the product", err);
    } finally {
      setAppLoaded();
    }
  }

  const onPopupOk = async (event) => {
    if (openConfirmPopup) {
      setOpenConfirmPopup(false);
      setModalOpen(false);
      return;
    }

    if (!openDeleteConfirm) return;
    if (!productId) return;
    if (!businessInfo) return;

    try {
      setAppLoading();
      const res = await deleteProduct(productId, businessInfo.serviceProviderUserId);
      if (res.data === "error") {
        alert('Cannot delete Menu item Category, it probably has menu items.');
      }
    } catch (err) {
      console.error("Delete product failed: ", err);
    } finally {
      setOpenDeleteConfirm(false);
      setModalOpen(false);
      setAppLoaded();
    }
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
    setOpenDeleteConfirm(false);
  }

  const validateForm = () => {
    if (formData.name.length === 0) {
      setNameValidationError("Menu item Category's name is required!");
      return false;
    }

    setNameValidationError("");
    return true;
  }

  const getTitle = () => {
    const formTitle = `${isEdit ? "Edit" : "Add"} Menu Item Category`;
    return openConfirmPopup || openDeleteConfirm ? "Warning!" : formTitle;
  }

  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );

  const deleteConfirmBody = (
    <div>Are you sure you want to delete this Menu item Category?</div>
  );

  const getBody = () => {
    const confirmBody = openDeleteConfirm ? deleteConfirmBody : closeOnDirtyDataConfirmBody;
    return openConfirmPopup || openDeleteConfirm ? confirmBody : modalBody;
  }

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      {isEdit ? <>
        <Button onClick={onSave}
          className={classes.form_footer_buttons_save}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Save</Button>
        <Button onClick={e => setOpenDeleteConfirm(true)}
          className={classes.form_footer_buttons_delete}
        >Delete</Button>
      </>
        : <Button onClick={onAdd}
          className={classes.form_footer_buttons_add}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Add</Button>}
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  const getButtons = () => {
    return openConfirmPopup || openDeleteConfirm ? confirmButtons : modalButtons;
  }

  return (<>
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={getTitle()}
      body={getBody()}
      buttons={getButtons()}
    />
  </>);
};

export default ProductForm;
