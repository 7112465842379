import classes from './GrandTotalsFooter.module.scss';
import { Box } from "@material-ui/core";
import { GridPagination } from "@material-ui/data-grid";
import { formatMoney } from "../../common/pageUtils";
import { BusinessOrder } from "../../Types/Types";


interface GrandTotalsFooterProps {
    rows?: BusinessOrder[];
}

const GrandTotalsFooter = (props: GrandTotalsFooterProps) => {


    if (!props.rows) return <></>;

    const totalTips = props.rows.reduce((acc, num) => acc + num.tip, 0);
    const totalOfSubTotals = props.rows.reduce((acc, num) => acc + num.subTotal, 0);
    const grandTotalOfOrders = props.rows.reduce((acc, num) => acc + num.total, 0);



    return <Box className={classes.footerWrap}>

        <section className={classes.comboWrap}>

            <Combo value={totalTips} label="Tips Total" />
            <hr className={classes.verticalRow} />
            <Combo value={totalOfSubTotals} label="Total of Subtotals" />
            <hr className={classes.verticalRow} />
            <Combo value={grandTotalOfOrders} label="Grand Total" />
            <hr className={classes.verticalRow} />

        </section>

        <GridPagination style={{ display: 'inline-block' }} />
    </Box>
}


interface ComboProps { label: string, value: string | number };
const Combo = (props: ComboProps) => {
    return <div className={classes.combo}>
        <span className={classes.label}>{props.label}:</span>
        <span className={classes.value}>${formatMoney(props.value)}</span>
    </div>
};

export default GrandTotalsFooter;