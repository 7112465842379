import {
  Snackbar as MuiSnackbar, SnackbarCloseReason
} from '@material-ui/core';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { SyntheticEvent } from 'react';


export interface SnackbarProps {
  isOpen: boolean;
  message: React.ReactNode;
  severity: AlertProps['severity'];
  onClose: (event: SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => void;
}

const Snackbar: React.FC<SnackbarProps> = (props) => {
  const { isOpen, message, severity, onClose } = props;

  
  return (
    <MuiSnackbar autoHideDuration={6000}
      open={isOpen}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}>
      <Alert onClose={onClose}
        variant="filled"
        elevation={6}
        severity={severity}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;