import classes from './ReportPageToolbar.module.scss';
import moment, { Moment } from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, MenuItem, Select, SelectProps, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { nameof } from 'ts-simple-nameof';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { apiDateFormat, getBizOrders, getBizOrdersByDates } from '../../api/statistics';
import { BusinessOrder } from '../../Types/Types';
import columnsDefinitions from "./ReportsColumnsDefinitions";

export interface SearchToolbarProps {
  setRows: (rows: any) => void;
  setAllData: (rows: any) => void;
  allRows: BusinessOrder[];
}

enum PaymentMethod {
  all = "allPaymentMethods",
  payInPerson = "payInPerson",
  google = "googlePay",
  apple = "applePay",
  other = "other",
}

enum OrderType {
  all = "allOrderTypes",
  cancelled = "cancelled",
}

enum DateRange {
  today,
  week,
  month,
  custom,
}

export const paymentMethods = [
  { name: "All Payment Methods", value: PaymentMethod.all },
  { name: "Pay in Person", value: PaymentMethod.payInPerson },
  { name: "Apple Pay", value: PaymentMethod.apple },
  { name: "Google Pay", value: PaymentMethod.google },
  { name: "Other", value: PaymentMethod.other },
]

const dateRanges = [
  { name: "Today", value: DateRange.today },
  { name: "Last 7 days", value: DateRange.week },
  { name: "Last 30 days", value: DateRange.month },
  { name: "Custom", value: DateRange.custom },
]

interface FilterValues {
  paymentMethodVal: PaymentMethod;
  orderTypeVal: OrderType;
  dateRangeVal: DateRange;
  dateStart: Moment,
  dateEnd: Moment,
}

const displayedColumns = columnsDefinitions.map(column => column.field);

const SearchToolbar = (props: SearchToolbarProps) => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAllData, setRows, allRows } = props;
  const [quickSearchValue, setSearchText] = useState('');
  const [filterValues, setFilterValues] = useState<FilterValues>({
    paymentMethodVal: PaymentMethod.all,
    orderTypeVal: OrderType.all,
    dateRangeVal: DateRange.week,
    dateStart: moment().subtract(7, 'd'),
    dateEnd: moment(),
  });

  useEffect(() => {

  }, []);
  console.log(filterValues);

  const handleDateChange = async (fieldName: string, date: MaterialUiPickersDate, value?: string) => {
    const newFilterValues = { ...filterValues };

    if (!date?.isValid()) {
      setFilterValues({ ...newFilterValues, dateRangeVal: DateRange.custom });
      return;
    }


    if (!businessInfo?.serviceProviderUserId) return;
    const isDateStart = fieldName === nameof<FilterValues>(filter => filter.dateStart);
    const isDateEnd = fieldName === nameof<FilterValues>(filter => filter.dateEnd);
    const updatedDate = date.format(apiDateFormat);
    const startDate = isDateStart ? updatedDate : newFilterValues.dateStart.format(apiDateFormat);
    const endDate = isDateEnd ? updatedDate : newFilterValues.dateEnd.format(apiDateFormat);

    if (!isDateStart && !isDateEnd) {
      console.error(`Invalid field name: ${fieldName}`);
      return;
    }

    await updateBizOrdersByDateRange(startDate, endDate);

    if (filterValues.hasOwnProperty(fieldName)) {
      newFilterValues[fieldName] = date;
    }
    newFilterValues.dateRangeVal = DateRange.custom;
    setFilterValues({ ...newFilterValues });
  }

  const quickSearch = (searchValue: string) => {
    setSearchText(searchValue);
    if(!searchValue) {
      setRows(allRows);
      return;
    }
    const newSearchRegex = new RegExp(searchValue, 'i');
    const filteredRows = allRows.filter(row => quickFilter(row, newSearchRegex));
    setRows(filteredRows);
  };

  const handleDateRangeChange = async (filters: FilterValues) => {
    if (!businessInfo?.serviceProviderUserId) return;
    if (!businessInfo?.businessId) return;

    let days = 7;
    switch (filters.dateRangeVal) {
      case DateRange.today:
        days = 1;
        break;
      case DateRange.week:
        days = 7;
        break;
      case DateRange.month:
        days = 30;
        break;
      default:
        break;
    }
    filters.dateStart = moment().subtract(days, 'd');
    filters.dateEnd = moment();

    const res = await getBizOrders(businessInfo.serviceProviderUserId, days, businessInfo.businessId);
    if (res?.status !== 200) return;
    const ordersList = (res.data as BusinessOrder[])
      .filter(row => quickFilter(row))
      .map(order => ({ ...order, id: order.orderId }));
    setRows(ordersList);
    setAllData(ordersList);
  }

  const handlePaymentChange = (paymentMethod) => {
    const filteredRows = allRows
      .filter(row => {
        const quickFilterRes = quickFilter(row);
        if (!quickFilterRes) return false;
        const isIphone = row.phoneOS === "iphone";
        const isAndroid = row.phoneOS === "android";
        switch (paymentMethod) {
          case PaymentMethod.all:
            return true;
          case PaymentMethod.apple:
            return row.paymentStatus === "success" && isIphone
          case PaymentMethod.google:
            return row.paymentStatus === "success" && isAndroid
          case PaymentMethod.payInPerson:
            return row.paymentStatus === PaymentMethod.payInPerson
          case PaymentMethod.other:
            return row.paymentStatus === "success" && (isAndroid || isIphone)
          default:
            return false;
        }
      });
    setRows(filteredRows);
  }

  const handleDropDownChanges = async (event) => {
    const { value, name } = event.target;

    const newFilterValues = { ...filterValues };
    if (filterValues.hasOwnProperty(name)) {
      newFilterValues[name] = value;
    }

    if (name === nameof<FilterValues>(filter => filter.paymentMethodVal)) {
      handlePaymentChange(newFilterValues.paymentMethodVal);
    }

    if (name === nameof<FilterValues>(filter => filter.dateRangeVal)) {
      await handleDateRangeChange(newFilterValues);
    }

    setFilterValues(newFilterValues);
  }

  const searchRegex = new RegExp(quickSearchValue, 'i');
  const quickFilter = (row: BusinessOrder, regex?: RegExp) => {
    const values = Object.entries(row);
    const reg = regex ?? searchRegex;
    return values.some((val) => {
      return displayedColumns.includes(val[0]) && reg.test(val[1])
    });
  }

  const updateBizOrdersByDateRange = async (startDate: string, endDate: string) => {
    if (!businessInfo?.serviceProviderUserId) return;
    if (!businessInfo?.businessId) return;
    const res = await getBizOrdersByDates(businessInfo.serviceProviderUserId, startDate, endDate, businessInfo.businessId);
    if (res?.status !== 200) return;
    const ordersList = (res.data as BusinessOrder[])
      .filter(row => quickFilter(row))
      .map(order => ({ ...order, id: order.orderId }));
    setRows(ordersList);
    setAllData(ordersList);
  }

  return (
    <div className={classes.root}>

      <KeyboardDatePicker
        className={classes.datePicker}
        required={false}
        clearable
        margin="normal"
        placeholder="MM/DD/yyyy"
        format="MM/DD/yyyy"
        value={filterValues.dateStart}
        onChange={(date, value) => {
          const fieldName = nameof<FilterValues>(filter => filter.dateStart);
          handleDateChange(fieldName, date, value ?? "")
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />

      <KeyboardDatePicker
        className={classes.datePicker}
        required={false}
        clearable
        margin="normal"
        placeholder="MM/DD/yyyy"
        format="MM/DD/yyyy"
        value={filterValues.dateEnd}
        onChange={(date, value) => {
          const fieldName = nameof<FilterValues>(filter => filter.dateEnd);
          handleDateChange(fieldName, date, value ?? "")
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />

      <DropDown value={filterValues.paymentMethodVal}
        name={nameof<FilterValues>(filter => filter.paymentMethodVal)}
        onChange={handleDropDownChanges}
        options={paymentMethods}
      />

      <DropDown value={filterValues.dateRangeVal}
        name={nameof<FilterValues>(filter => filter.dateRangeVal)}
        onChange={handleDropDownChanges}
        options={dateRanges}
      />

      <TextField
        variant="standard"
        value={quickSearchValue}
        onChange={event => quickSearch(event.target.value)}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: quickSearchValue ? 'visible' : 'hidden' }}
              onClick={e => quickSearch('')}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }} />
    </div>
  );
};

interface DropdownProps {
  value: string | number;
  name: string;
  onChange: SelectProps["onChange"];
  options: any[];
}

const DropDown: React.FC<DropdownProps> = (props) => {
  const { value, name, onChange, options } = props;

  return (
    <Select value={value}
      name={name}
      onChange={onChange}
    >
      {options.map(method => {
        return (
          <MenuItem key={method.value} value={method.value}>
            {method.name ? method.name : "None"}
          </MenuItem>
        )
      })}
    </Select>
  )
}


export default SearchToolbar;