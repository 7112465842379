import classes from "./BusinessConfigs.module.scss";
import BizConfigHeader from "./Header";
import FormGroup from "@material-ui/core/FormGroup";
import CheckboxLabels from "../../components/FormControls/CheckboxLabels";
import LabelRow from "./LabelRow";
import { nameof } from "ts-simple-nameof";
import { ChangeEvent } from "react";
import { daysMapping, timeNumberToStr } from "../../common/pageUtils";
import { BusinessInfo, WorkingHour } from "../../Types/Types";
import { Select } from '@material-ui/core';
import { byHalfHourOptions } from "../../components/FormRow/FormRow";
import { useBizConfigNavBlocking } from "../../hooks/useBizConfigNavBlocking";
import { SelectType } from "../../common/bizConfigUtils";
import { bizConfigLinks } from "../../components/SideNavigation/SideNavigation";

const getDayValue = (day: string, fieldName?: string, workingHours?: WorkingHour[]) => {
  if (!workingHours) return "";

  const dayHours = workingHours.find(item => item.days === day);
  if (!dayHours) return "";

  if (fieldName === undefined) {
    return timeNumberToStr(dayHours.startTime);
  }

  return timeNumberToStr(dayHours[fieldName]);
}

const options = [...byHalfHourOptions, "2359"];
const timeOptionSelects = options.map((val: string) => {
  const displayValue = !val ? "None" : `${val.slice(0, 2)}:${val.slice(2, 4)}`;
  return (
    <option key={val ?? -1} value={val}>
      {displayValue}
    </option>
  )
})


const WorkingHours = () => {
  const {
    formData, setFormData, elements, handleChange, saveBusinessInfo
  } = useBizConfigNavBlocking();


  const handleDayCloseChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;

    const day = event.target.name;
    if (!day) return;
    const workingHourDay = formData.workingHours.find(item => item.days === day);
    if (!workingHourDay) {
      console.error(`Day is not found ${day}`);
      return;
    }

    workingHourDay.open = !event.target.checked;
    setFormData({ ...formData });
  }

  const handleDayHourChange = (event: SelectType, fieldName: keyof WorkingHour) => {
    if (!formData) return;

    const day = event.target.name;
    const value = event.target.value;
    const dayHour = formData.workingHours.find(item => item.days === day);
    if (!dayHour) return;

    dayHour[fieldName.toString()] = Number(value);
    setFormData({ ...formData });
  }


  const WorkingHoursColumns = Object.entries(daysMapping).map(([shortName, fullName]) => {
    if (!formData) return;
    const startTime = getDayValue(shortName, "startTime", formData.workingHours);
    const endTime = getDayValue(shortName, "endTime", formData.workingHours);

    const whd = formData?.workingHours?.find(item => item.days === shortName);
    const isClosed = whd ? !whd.open : false;

    return (
      <div key={shortName} className={classes.body_workingHours_row}>

        <div className={classes.body_workingHours_row_label}>
          {fullName}
        </div>

        <Select native
          value={startTime ? startTime : "0800"}
          name={shortName}
          disabled={isClosed}
          onChange={(event) => handleDayHourChange(event, "startTime")}
        >
          {timeOptionSelects}
        </Select>

        <Select native
          value={endTime ? endTime : "2000"}
          name={shortName}
          disabled={isClosed}
          onChange={(event) => handleDayHourChange(event, "endTime")}
        >
          {timeOptionSelects}
        </Select>

        <CheckboxLabels
          label="Closed"
          name={shortName}
          checkOnChange={handleDayCloseChange}
          value={isClosed} />
      </div>
    );
  });



  return (
    <section className={classes.workingHours}>

      {elements.ConfirmPopup}
      {elements.SnackbarElement}
      <BizConfigHeader title={bizConfigLinks.workingHour.name} onSave={saveBusinessInfo} />

      <FormGroup row className={classes.body_workingHours}>

        <label className={classes.body_workingHours_label}>
          Working Hours
        </label>

        <div className={classes.body_workingHours_rows}>
          {WorkingHoursColumns}
        </div>

      </FormGroup>


      <LabelRow
        label={
          <>
            Closed Reason
            <FormGroup row className={classes.inner_row}>
              <CheckboxLabels
                label="Temporarily Closed"
                name={nameof<BusinessInfo>(p => p.storeTemporarilyClosed)}
                checkOnChange={handleChange}
                value={formData?.storeTemporarilyClosed ?? false}
              />
            </FormGroup>
          </>}
        content={
          <textarea value={formData?.storeTemporarilyClosedReason ?? ""}
            name={nameof<BusinessInfo>(p => p.storeTemporarilyClosedReason)}
            onChange={handleChange}
          />
        }
      />

    </section>
  )
}

export default WorkingHours;
