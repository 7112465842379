import classes from './pageNotFound.module.scss';

export interface PageNotFoundProps {

}

const PageNotFound: React.FC<PageNotFoundProps> = () => {
  return (
    <div
      className={classes.errorPage}
      data-role="page"
      id="notFoundErrorPage">
      <div>
        <h2>404</h2>
        <h1>Oops!</h1>
        <p>This page you requested could not be found</p>
      </div>
    </div>
  );
}

export default PageNotFound;