import React, {  useEffect, useState } from "react";
import { useActions } from "../../hooks/useActions";
import Snackbar from "../Snackbar/Snackbar";

const ErrorBoundary: React.FC = (props) => {
  const { children } = props;
  const [hasError, setHasError] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { setAppLoaded } = useActions();

  const promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    setHasError(true);
    setIsSnackbarOpen(true);
    setAppLoaded();
    console.error(event);
  }

  useEffect(() => {
    window.addEventListener('unhandledrejection', promiseRejectionHandler)

    const cleanup = () => {
      window.removeEventListener('unhandledrejection', promiseRejectionHandler);
    }
    return cleanup;
  }, []);

  return <>
    {hasError && <Snackbar
      isOpen={isSnackbarOpen}
      onClose={e => setIsSnackbarOpen(false)}
      severity="error"
      message="Oops something went wrong!"
    />}
    {children}
  </>;
}

export default ErrorBoundary;