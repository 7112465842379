import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, PropsWithChildren, useEffect, useState } from "react";
// import { Modal } from "@material-ui/core";
import Modal from 'react-modal';
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Popup.module.scss";

interface PopupPropsBase extends PropsWithChildren<Object> {
  isOpen: boolean;
  popupStyle?: React.CSSProperties;
  onClickElement?: React.ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  showHeaderCloseBtn?: boolean;
  handleClose: (event:
    React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

export interface CustomBodyProps extends PopupPropsBase {
  body: React.ReactNode;
  content?: never;
  header?: never;
  title?: never;
  titleWrapStyles?: never;
  headerStyle?: never;
  contentStyle?: never;
  footer?: never;
  onOkay?: never;
  showOk?: never;
}

export interface DefaultPopupProps extends PopupPropsBase {
  body?: never;
  content?: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  title?: React.ReactNode;
  titleWrapStyles?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  onOk?: React.MouseEventHandler<Element>;
  showOk?: boolean;
}

type PopupProps = CustomBodyProps | DefaultPopupProps;

const Popup: FC<PopupProps> = (props) => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const { onClickElement, isOpen, handleClose, popupStyle } = props;
  const { shouldCloseOnOverlayClick = true, showHeaderCloseBtn = true } = props;
  const { body } = props as CustomBodyProps;
  const {
    header, titleWrapStyles, titleStyle, content, contentStyle, title, footer, onOk,
    showOk = true
  } = props as DefaultPopupProps;

  const wrapperStyle: React.CSSProperties = {
    borderColor: businessInfo?.theme?.color,
    ...popupStyle
  }

  const bodyWrapColors: React.CSSProperties = {
    borderColor: businessInfo?.theme?.color,
    color: businessInfo?.theme?.color,
  };

  const titleWrapCSS: React.CSSProperties = {
    backgroundColor: businessInfo?.theme?.color,
    color: "#FFF",
    ...titleWrapStyles
  };

  const contentCSS: React.CSSProperties = {
    marginBottom: footer ? "" : "20px",
    ...contentStyle,
  }

  const okayBtn = showOk && (<div className={classes.okayFooter}>
    <hr className={classes.okayFooter_separator} />
    <div onClick={onOk ?? handleClose}
      className={classes.okayFooter_button}
    >
      OK
    </div>
  </div>)


  const popupBody = body ?? (
    <div style={bodyWrapColors} className={classes.body}>
      {header ??
        <section style={titleWrapCSS} className={classes.body_title}>
          <div style={titleStyle} className={classes.body_title_label}>
            {title}
          </div>
          {showHeaderCloseBtn && <div
            onClick={handleClose}
            className={classes.body_title_closeButton}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </div>}
        </section>}
      <section style={contentCSS} className={classes.body_content}>
        {content ?? "Add some content!"}
      </section>
      {footer ?
        <section className={classes.body_footer}>
          {footer}
        </section>
        : okayBtn}
    </div>
  )

  return (
    <div
      className={classes.popup_container}
    >
      {onClickElement}
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        ariaHideApp={false}
        style={{
          content: wrapperStyle,
        }}
        className={classes.popup}
      >
        {popupBody ?? <div className={classes.popup_content}>
          {props.children ? props.children : body}
        </div>}
      </Modal>
    </div>
  );
}

export default Popup;