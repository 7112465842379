import { combineReducers } from "redux";
import businessInfoReducer from "./businessInfoReducer";
import accountReducer from './accountReducer';
import businessPortalReducers from './businessPortalReducers';
import appReducer from "./appReducer";

const reducers = combineReducers({
  businessInfo: businessInfoReducer,
  account: accountReducer,
  businessPortal: businessPortalReducers,
  appStore: appReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
