
import { Button } from "semantic-ui-react";
import classes from "./BusinessConfigs.module.scss";

interface BizConfigHeaderProps {
  title: string,
  onSave?: Function,
}

const BizConfigHeader = (props: BizConfigHeaderProps) => {
  const { title, onSave } = props;

  return (
    <section className={classes.header}>
      <span className={classes.header_title}>
        {title}
      </span>
      {onSave && <span className={classes.header_buttons}>
        <Button
          content="Save"
          onClick={() => onSave()}
        />
      </span>}
    </section>
  )
}

export default BizConfigHeader;