import classes from './ProductList.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { MenuEditUrlParams } from '../../../components/PortalHeader';
import { MenuProductDTO } from '../../../Types/Types';
import { Button, Card } from 'semantic-ui-react';
import { getProductsByCategory, postProductImage, updateProductsOrder } from '../../../api/productList';
import { getAllCategories } from '../../../api/categoryList';
import { IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import ProductForm, { CategorySelectOption } from './ProductForm';
import ImgUploadForm from '../../../components/ImageUploadForm/ImgUploadForm';
import FramedImage from '../../../components/MenuListImage/FramedImage';
import BreadcrumbLink from '../../../components/BreadcrumbLink/BreadcrumbLink';
import SortableCard from '../SortableCard';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import { reorderList } from '../../../common/pageUtils';

const ProductList = () => {
  const { setAppLoading, setAppLoaded } = useActions();
  const { categoryId } = useParams<MenuEditUrlParams>();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [products, setProducts] = useState<MenuProductDTO[]>([]);
  const [productId, setProductId] = useState<number>();
  const [showModal, setModalOpen] = useState(false);
  const [categories, setCategories] = useState<CategorySelectOption[]>([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isImgAdd, setIsImgAdd] = useState(false);
  const [imgPreview, setImgPreview] = useState("");
  const [imageToUpload, setImgToUpload] = useState<File>();

  const fetchProducts = useCallback(async () => {
    if (openUploadModal) return;
    if (showModal) return;
    if (!categoryId) return;
    if (!businessInfo) return;
    const res = await getProductsByCategory(categoryId, businessInfo.serviceProviderUserId);
    const productsByCategory = res.data as MenuProductDTO[];
    if (!productsByCategory.some(product => product.sortingOrder > 0)) {
      productsByCategory.forEach((product, ind) => product.sortingOrder = ind);
    }
    const sorted = productsByCategory.sort(
      (firstProd, secondProd) => firstProd.sortingOrder - secondProd.sortingOrder
    );

    setProducts(sorted);
  }, [categoryId, businessInfo, openUploadModal, showModal])

  const fetchCategories = useCallback(async () => {
    if (!businessInfo?.serviceProviderUserId) return;
    const res = await getAllCategories(businessInfo.serviceProviderUserId);
    setCategories(res.data);
  }, [businessInfo?.serviceProviderUserId])

  const fetchData = useCallback(async () => {
    setAppLoading();
    if (categories.length === 0) {
      fetchProducts();
      await fetchCategories();
    } else {
      await fetchProducts();
    }
    setAppLoaded();
  }, [fetchProducts, fetchCategories])

  useEffect(() => {
    if (showModal) return;
    if (openUploadModal) return;
    setProductId(undefined);
    fetchData();
  }, [showModal, openUploadModal, fetchData])

  const onImgEditClicked = (product: MenuProductDTO) => {
    setIsImgAdd(false);
    setOpenUploadModal(true);
    setImgPreview(product.image ?? "");
    setProductId(product.id);
  };

  const handleEdit = (id?: number) => {
    setProductId(id);
    setModalOpen(true);
  };

  const uploadImage = async () => {
    if (!imageToUpload) return;
    await postProductImage(imageToUpload, productId);
    setOpenUploadModal(false);
  }

  const changeOrder = (event) => {
    const { active, over } = event;
    const oldIndex = Number(active.id);
    const newIndex = Number(over.id);
    if (oldIndex === newIndex) return;

    const sortedList = reorderList(products, oldIndex, newIndex);
    setProducts([...sortedList]);
    updateProductsOrder(sortedList);
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const productToCard = (product: MenuProductDTO, listeners, index: number) => {
    return (
      <Card key={product.id}>
        <Card.Content>
          <FramedImage
            onImgEditClicked={e => onImgEditClicked(product)}
            image={product.image} />
          <Card.Meta>
            {index + 1}/{products.length}
            <IconButton {...listeners}>
              <ViewWeekIcon />
            </IconButton>
          </Card.Meta>
          <Card.Header>
            {product.name}
            <IconButton
              onClick={e => handleEdit(product.id)}>
              <Edit />
            </IconButton>
          </Card.Header>
          <Card.Meta>{product.description}</Card.Meta>
        </Card.Content>
        <Card.Content extra>
          <div className={classes.productList_buttonRow}>

            <BreadcrumbLink
              isButton
              breadcrumb={{
                displayName: product.name,
                pathName: `/menuEdit/${categoryId}/${product.id}`
              }}>
              Manage Menu Items
            </BreadcrumbLink>
          </div>
        </Card.Content>
      </Card>
    )
  }

  return (
    <section className={classes.productList}>

      <ImgUploadForm
        isOpen={openUploadModal}
        onValidImage={(file) => {
          setImgToUpload(file);
        }}
        currentImage={imgPreview}
        onOk={uploadImage}
        onCancel={e => {
          setImgPreview("")
          setOpenUploadModal(false);
          setImgToUpload(undefined);
        }}
        isImgAdd={isImgAdd}
      />

      <div className={classes.productList_addNew_button}>
        <Button onClick={e => setModalOpen(true)}>
          Add New Menu Item Category
        </Button>
      </div>

      <DndContext
        onDragEnd={changeOrder}
        collisionDetection={closestCenter}
        sensors={sensors}>
        <SortableContext
          items={products.map((prod, index) => index.toString())}
        >
          <Card.Group>
            {products.map((product, index) =>
              <SortableCard
                key={product.id}
                item={product}
                content={(prod, listeners) =>  productToCard(prod, listeners, index)}
                styles={{ margin: "10px", }}
                sortIndex={index.toString()}
              />)}
          </Card.Group>
        </SortableContext>
      </DndContext>

      {showModal && <ProductForm
        showModal={showModal}
        setModalOpen={setModalOpen}
        productId={productId}
        categoryId={Number(categoryId)}
        categorySelectOptions={categories.length === 0 ? [] : categories}
      />}
    </section>
  );
};


export default ProductList;