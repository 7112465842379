import "./App.scss";
import "semantic-ui-css/semantic.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useTypedSelector } from "./hooks/useTypedSelector";
import BusinessPortal from "./pages/BusinessPortal";
import Loader from "./components/Loader/Loader";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import PageNotFound from "./pages/notFound/pageNotFound";
import { useState, useEffect } from "react";
import LocationSelectorPage from "./pages/LocationSelector/LocationSelectorPage";


const BusinessPortalRouting = () => {

  const getIsFirstParamString = () => {
    const allPath = window.location.pathname;
    const paths = allPath.split("/").splice(1, 1);
    return isNaN(Number(paths[0]));
  }
  const isStringInitial = getIsFirstParamString();

  const [firstPathPartIsString, setFirstPathPartIsString] = useState(isStringInitial);

  useEffect(() => {
    const isString = getIsFirstParamString();
    setFirstPathPartIsString(isString);
    return () => { }
  }, [window.location.pathname])

  return (
    firstPathPartIsString
      ?
      <Route path={"/:businessName"}>
        <BusinessPortal />
      </Route>
      :
      <Route path={"/:serviceProviderUserId/:businessName?"}>
        <LocationSelectorPage />
      </Route>
  )
}


const App: React.FC = () => {
  const { isLoading } = useTypedSelector(state => state.appStore);
  return (
    <BrowserRouter>
      <ErrorBoundary>
        {isLoading && <Loader />}
        <Switch>
          <Route path="/notFound" component={PageNotFound}></Route>
          {/* <Route path="/:businessName" component={BusinessPortal}></Route> */}
          <BusinessPortalRouting />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;


