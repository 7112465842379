import classes from "./SideNavigation.module.scss";
import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ListItem, SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import BreadcrumbLink from "../BreadcrumbLink/BreadcrumbLink";

interface NavItemProps {
  url: string;
  name: string;
  className?: string;
  wrapperClass?: string;
  iconStyle?: CSSProperties;
  contentStyle?: CSSProperties;
}

interface PropWithFaIcons extends NavItemProps {
  faIcon?: IconProp;
  muiIcon?: never
}

interface PropWithMuiIcons extends NavItemProps {
  faIcon?: never;
  muiIcon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>
}

const NavItem: React.FC<PropWithFaIcons | PropWithMuiIcons> = (props) => {
  const { name, children, url, faIcon, contentStyle, wrapperClass, iconStyle,
    muiIcon: MuiIcon, className = classes.navLink } = props;


  const icon = (
    <span style={iconStyle}
      className={`${classes.navLink_icon}`} >
      {faIcon && <FontAwesomeIcon icon={faIcon} />}
      {MuiIcon && <MuiIcon />}
    </span>
  )

  const contentClass = className ? " " + className : "";
  return (
    <ListItem className={wrapperClass}>
      <BreadcrumbLink
        breadcrumb={{
          displayName: name,
          pathName: url
        }}
      >
        <div className={`${classes.sideNav_item}${contentClass}`}>

          {(faIcon || MuiIcon) && icon}

          <span style={contentStyle} className={classes.navLink_content}>
            {children}
          </span>
        </div>
      </BreadcrumbLink>
    </ListItem >
  );
};

export default NavItem;
