import {  useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import classes from "./Account.module.scss";
import SubmitFieldWithButton from "./SubmitFieldWithButton";

const ForgotPassword: React.FC = () => {
  const params = useParams<{ businessName: string }>();
  const appBaseUrl = `/${params.businessName}`;
  const { validateUserEmail, resetPassword } = useActions();
  const account = useTypedSelector(state => state.account);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currValue = String(event.target.value);
    setMessage("");
    setEmail(currValue);
    validateUserEmail(currValue);
  }

  const isFormValid = () => {
    return email.length > 0 && account.error === "";
  }

  const submitEmail = (e) => {
    resetPassword(email);
    setMessage("We've sent a temporary password to your email.");
  }

  return (
    <div className={classes.form}>
      <div className={classes.pageTitle}>Trouble Signing In?</div>

      <SubmitFieldWithButton
        value={email}
        onChange={handleInput}
        onSubmit={submitEmail}
        isFormValid={isFormValid}
        moreClasses={classes.forgot_button}
      />

      <div className={classes.messages}>
        {message}
      </div>

      <div className={classes.forgot_links}>
        <div className={classes.forgot_info}>
          Enter your email and we'll send you a link to get back into your account.
        </div>
        <Link
          className={`${classes.link_bottom} ${classes.link} ${classes.forgot_backBtn}`}
          to={`${appBaseUrl}/account/signIn`}
        >
          Back to Login
        </Link>
      </div>
    </div>
  );
}

export default ForgotPassword;