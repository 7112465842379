import { nameof } from "ts-simple-nameof";
import { LocalVisibility } from "../../Types/Types";
import FormRow from "../FormRow/FormRow";
import DaysCheckboxes from "./DaysCheckboxes";

export interface FormVisibilityRulesProps {
  visibilityRules: LocalVisibility;
  handleChange: (event) => void;
}

const FormVisibilityRules: React.FC<FormVisibilityRulesProps> = (props) => {
  const { visibilityRules, handleChange } = props;

  return (
    <div>
      <FormRow
        label="Start time"
        name={nameof<LocalVisibility>(p => p.startTime)}
        inputType="time"
        value={visibilityRules.startTime}
        placeholder="Start time"
        handleChange={handleChange}
      />
      <FormRow
        label="End time"
        name={nameof<LocalVisibility>(p => p.endTime)}
        inputType="time"
        value={visibilityRules.endTime}
        placeholder="End time"
        handleChange={handleChange}
      />
      <FormRow
        label="Start date"
        name={nameof<LocalVisibility>(p => p.startDate)}
        inputType="date"
        value={visibilityRules.startDate}
        placeholder="Start date"
        handleChange={handleChange}
      />
      <FormRow
        label="End date"
        name={nameof<LocalVisibility>(p => p.endDate)}
        inputType="date"
        value={visibilityRules.endDate}
        placeholder="End date"
        handleChange={handleChange}
      />
      <DaysCheckboxes data={visibilityRules} handleChange={handleChange} />
    </div>
  );
}

export default FormVisibilityRules;
