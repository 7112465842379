import classes from './ConfirmModal.module.scss';
import GlobalModal from "../GlobalModal/GlobalModal";
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from 'semantic-ui-react';
import { MouseEventHandler } from 'react';


export interface ConfirmModalProps {
  isOpen: boolean;
  handleClose?: Function;
  onOk: MouseEventHandler;
  onCancel: MouseEventHandler;
}

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  const { isOpen, handleClose, onCancel, onOk } = props;

  const confirmButtons = (
    <div className={classes.buttons}>
      <Button onClick={onCancel}
        className={classes.buttons_no}
      >No</Button>
      <Button onClick={onOk}
        className={classes.buttons_yes}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  return (
    <GlobalModal
      open={isOpen}
      handleClose={handleClose || onCancel}
      title={"Warning!"}
      body={
        <div>
          <div>There are unsaved modifications</div>
          <div>Are you sure you want to leave without saving?</div>
        </div>
      }
      buttons={confirmButtons}
    />
  );
}

export default ConfirmModal;