import classes from "./BusinessConfigs.module.scss";
import { FormGroup, FormHelperText, Tooltip } from "@material-ui/core";
import { Error } from "@material-ui/icons";
import { ValidationError } from "../../Types/Types";


interface LabelRowProps {
  label?: React.ReactNode;
  content: React.ReactNode;
  helperText?: string;
  multiInput?: boolean;
  isMoney?: boolean;
  contentClassName?: string;
  validationError?: ValidationError;
}

const LabelRow = (props: LabelRowProps) => {
  const { label, content, helperText, multiInput, isMoney, contentClassName, validationError } = props;

  const moneyContent = isMoney ? <div className={classes.dollarSignWrap}>
    <span className={classes.dollarSign}>$</span>
    {content}
  </div>
    : content;

  const getErrorRow = () => {
    if (!validationError) return;

    return (
      <Tooltip className={classes.errorIndicator}
        placement="bottom"
        title={validationError.errorMsg}>
        <Error />
      </Tooltip>
    )
  }

  const contentWithHelperTxt = helperText ?
    <div>

      <div className={contentClassName ?? classes.content}>
        {moneyContent}
      </div>

      <FormHelperText className={classes.helperText}>
        {helperText}
      </FormHelperText>

    </div>
    : <div className={contentClassName ?? classes.content}>
      {moneyContent}
    </div>;

  const getRow = () => {
    if (multiInput) {
      return (
        <FormGroup row className={classes.multiRow}>
          {label && <label className={classes.label}>
            {label}
          </label>}
          <section className={classes.contentWrap}>
            {contentWithHelperTxt}
            {getErrorRow()}
          </section>
        </FormGroup>
      )
    }


    return <FormGroup row className={classes.body_row}>
      <label className={classes.label}>
        {label}
      </label>
      <section className={classes.contentWrap}>
        {contentWithHelperTxt}
        {getErrorRow()}
      </section>
    </FormGroup>
  }


  return getRow();
}

export default LabelRow;