import classes from './CategoryList.module.scss';
import GlobalModal from '../../../components/GlobalModal/GlobalModal';
import FormRow from '../../../components/FormRow/FormRow';
import FormVisibilityRules from '../../../components/FormVisibilityRules/FormVisibilityRules';
import { useState, ChangeEvent, useEffect, useCallback } from "react";
import { Button } from '@material-ui/core';
import { nameof } from 'ts-simple-nameof';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { getProductsByCategory } from '../../../api/productList';
import { daysFields, numberToString, timeStrToNumber } from '../../../common/pageUtils';
import {
  getCategory, addCategory, deleteCategory, updateCategory
} from '../../../api/categoryList';
import {
  MenuCategoryDTO, CategoryFormData, MENU_CATEGORY_TYPE, VisibilityRules
} from '../../../Types/Types';

interface CategoryFormProps {
  showModal: boolean;
  setModalOpen: Function;
  categoryId?: number;
}

const CategoryForm: React.FC<CategoryFormProps> = (props) => {
  const { showModal, setModalOpen, categoryId } = props;
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAppLoading, setAppLoaded } = useActions();
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isEdit] = useState(categoryId ? true : false);
  const [nameValidationError, setNameValidationError] = useState("");
  const [formData, setFormData] = useState<CategoryFormData>({
    selected: true,
    serviceProviderUserId: Number(businessInfo?.serviceProviderUserId),
    name: "",
    description: "",
    visibilityRules: {
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      Sun: false, Mon: false,
      Tue: false, Wed: false,
      Thu: false, Fri: false, Sat: false,
      type: ""
    }
  });
  const [originalData, setOriginalData] = useState<CategoryFormData>({
    ...formData,
    visibilityRules: { ...formData.visibilityRules }
  });

  const getCategoryAsync = useCallback(async () => {
    if (!categoryId) return;
    setAppLoading();
    const res = await getCategory(categoryId);

    const mapped = Object.assign({}, res.data as MenuCategoryDTO);
    let visibilityRule = Object.assign({}, formData.visibilityRules) as any;
    visibilityRule = Object.assign(visibilityRule, mapped.visibilityRuleJson);
    visibilityRule.days && visibilityRule.days.forEach(day => {
      visibilityRule[day] = true;
    })

    delete visibilityRule.days;

    visibilityRule.endTime = numberToString(visibilityRule.endTime);
    visibilityRule.startTime = numberToString(visibilityRule.startTime);

    const mappedData = { ...mapped, visibilityRules: visibilityRule };
    const originalData = Object.assign({}, mappedData);
    originalData.visibilityRules = Object.assign({}, originalData.visibilityRules);
    setFormData(mappedData);
    setOriginalData(originalData);
    setAppLoaded();
  }, [categoryId])

  const getProducts = useCallback(async () => {
    if (!categoryId) return;
    if (!businessInfo) return;
    const productsRes = await getProductsByCategory(categoryId, businessInfo.serviceProviderUserId);
    setShowDeleteButton(productsRes.data.length === 0);
  }, [categoryId, businessInfo])

  useEffect(() => {
    getCategoryAsync();
    getProducts();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const nameProp = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const visibilityRules = formData.visibilityRules;
    if (formData.hasOwnProperty(nameProp)) {
      setFormData({ ...formData, [nameProp]: value });
      return;
    }

    if (visibilityRules.hasOwnProperty(nameProp)) {
      const newVisibility = formData.visibilityRules;
      newVisibility[nameProp] = value;
      setFormData({ ...formData, visibilityRules: newVisibility });
      return;
    }

    console.warn(`No prop on formData or visibilityRules called ${nameProp}!`);
  }

  const getFormDtoSnapshot = () => {
    const days: string[] = [];
    daysFields.forEach(day => {
      if (formData.visibilityRules[day] !== true) return;
      days.push(day);
    })

    const formDTO: MenuCategoryDTO = {
      id: formData.id,
      description: formData.description,
      name: formData.name,
      selected: formData.selected,
      defaultProductImage: formData.defaultProductImage,
      serviceProviderUserId: formData.serviceProviderUserId,
      visibilityRuleJson: {
        startTime: timeStrToNumber(formData.visibilityRules.startTime),
        endTime: timeStrToNumber(formData.visibilityRules.endTime),
        startDate: formData.visibilityRules.startDate,
        endDate: formData.visibilityRules.endDate,
        days: days,
        type: formData.visibilityRules.type,
      },
    }

    return formDTO;
  }

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData);
    const dirtiedJsonStr = JSON.stringify(formData);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const onAdd = async (e) => {
    if (categoryId) return;
    if (!validateForm()) return;
    const newCategory = getFormDtoSnapshot();

    try {
      setAppLoading();
      const res = await addCategory(newCategory);
      if (res.status === 200 && !isNaN(Number(res.data))) {
        setModalOpen(false);
        setOpenDeleteConfirm(false);
      }
    } catch (err) {
      console.error("Add category failed: ", err);
    } finally {
      setAppLoaded();
    }
  }

  const onDeleteClick = async (e) => {
    if (!categoryId) return;
    setOpenDeleteConfirm(true);
  }

  const onSave = async (e) => {
    if (!categoryId) return;
    if (!validateForm()) return;

    const updatedCategory = getFormDtoSnapshot();

    try {
      setAppLoading();
      const res = await updateCategory(updatedCategory);
      if (res.data === "success") {
        setModalOpen(false);
      }
    } catch (err) {
      console.error("Something went wrong with updating the category", err);
    } finally {
      setAppLoaded();
    }
  }

  const onPopupOk = async (event) => {
    if (openConfirmPopup) {
      setOpenConfirmPopup(false);
    }

    if (!(openDeleteConfirm && categoryId && businessInfo)) {
      setModalOpen(false);
      return;
    }

    try {
      setAppLoading();
      const res = await deleteCategory(categoryId, businessInfo.serviceProviderUserId);

      if (res.data === "error") {
        alert('Cannot delete category, it probably has menu item Categories.');
      }
    } catch (err) {
      console.error("Delete category failed: ", err);
    } finally {
      setOpenDeleteConfirm(false);
      setModalOpen(false);
      setAppLoaded();
    }
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
    setOpenDeleteConfirm(false);
  }

  const validateForm = () => {
    if (formData.name.length === 0) {
      setNameValidationError("Menu name is required!");
      return false;
    }

    setNameValidationError("");
    return true;
  }

  const modalBody = (
    <div className={classes.form}>
      <FormRow
        label="Visible"
        name={nameof<CategoryFormData>(p => p.selected)}
        inputType="checkbox"
        value={formData.selected}
        handleChange={handleChange}
      />
      <FormRow
        label="Name"
        name={nameof<CategoryFormData>(p => p.name)}
        inputType="text"
        value={formData.name}
        placeholder="Menu Name"
        handleChange={handleChange}
      />
      <div>{nameValidationError}</div>
      <FormRow
        label="Description"
        name={nameof<CategoryFormData>(p => p.description)}
        inputType="textarea"
        value={formData.description}
        placeholder="Description"
        handleChange={handleChange}
      />

      <FormVisibilityRules
        visibilityRules={formData.visibilityRules}
        handleChange={handleChange} />

      <FormRow
        label="Menu Type"
        name={nameof<VisibilityRules>(p => p.type)}
        inputType="dropdown"
        value={formData.visibilityRules.type}
        handleChange={handleChange}
        options={["", ...Object.values(MENU_CATEGORY_TYPE)]}
        valueField={val => val}
      />
    </div>
  );

  const getTitle = () => {
    const formTitle = `${isEdit ? "Edit" : "Add"} Menu`;
    return openConfirmPopup || openDeleteConfirm ? "Warning!" : formTitle;
  }

  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );

  const deleteConfirmBody = (
    <div>Are you sure you want to delete this category?</div>
  );

  const getBody = () => {
    const confirmBody = openDeleteConfirm ? deleteConfirmBody : closeOnDirtyDataConfirmBody;
    return openConfirmPopup || openDeleteConfirm ? confirmBody : modalBody;
  }

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      {isEdit ? <>
        <Button onClick={onSave}
          className={classes.form_footer_buttons_save}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Save</Button>
        <Button onClick={onDeleteClick}
          className={classes.form_footer_buttons_delete}
        >Delete</Button>
      </>
        : <Button onClick={onAdd}
          className={classes.form_footer_buttons_add}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Add</Button>}
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  const getButtons = () => {
    return openConfirmPopup || openDeleteConfirm ? confirmButtons : modalButtons;
  }

  return (<>
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={getTitle()}
      body={getBody()}
      buttons={getButtons()}
    />
  </>);
};

export default CategoryForm;
