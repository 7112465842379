import { AxiosRequestConfig } from "axios";
import { store } from "../state";

export const getCredentials = () => {
  return store.getState().account.credential;
}

export const getAuthConfig = () => {
  const credential = getCredentials();
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${credential.btoa}`
    },
  }

  return config;
}

export const getMultipartAuthConfig = () => {
  const credential = getCredentials();
  if (!credential) return {};

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Basic ${credential.btoa}`
    },
  }

  return config;
}
