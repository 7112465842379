import axios, { AxiosRequestConfig } from "axios";
import { baseUrl } from "../appConfigs";

interface UserLoginState {
  emailAsUserId: string,
  password: string,
  enterChoice?: string
}

export const loginToServer = async (auth: string, serviceProviderId: string) => {
  const payload = { serviceProviderUserId: serviceProviderId };
  
  const authConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${auth}`,
    },
  }

  try {
    return await axios.post(`${baseUrl}/rest/user/adminLogin`, payload, authConfig);
  } catch (error) {
    throw error;
  }
}

export const getCredentials = async (credential: UserLoginState) => {
  try {
    return await axios.post(`${baseUrl}/rest/user/login/light`, credential);
  } catch (error) {
    throw error;
  }
}

export const signUpOnServer = async (credential: UserLoginState) => {
  try {
    return await axios.put(`${baseUrl}/rest/user/`, credential);
  } catch (error) {
    throw error;
  }
}

export const sendResetEmail = async (credential: UserLoginState) => {
  try {
    return await axios.post(`${baseUrl}/rest/user/forgotPassword`, credential);
  } catch (error) {
    throw error;
  }
}

export interface ChangePasswordState {
  confirmedPassword: string;
  emailAsUserId: string;
  newPassword: string;
  password: string;
}

export const sendPasswordChange = async (data: ChangePasswordState) => {
  try {
    return await axios.post(`${baseUrl}/rest/user/changePassword`, data);
  } catch (error) {
    throw error;
  }
}
