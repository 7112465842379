import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DOMAttributes, ReactElement } from "react";
import classes from "./Account.module.scss";

type HtmlInputProps = React.DOMAttributes<HTMLInputElement>;

interface InputWithIconsProps {
  value: string | number | readonly string[] | undefined;
  leftIcon?: IconProp;
  RightElem?: ReactElement<any, any>;
  onChange?: HtmlInputProps["onChange"];
  onKeyUp?: HtmlInputProps["onKeyUp"];
  name?: string;
  type?: string;
  placeholder?: string;
  wrapperClasses?: string;
}

const InputWithIcons: React.FC<InputWithIconsProps> = (props) => {
  const {
    name, value, leftIcon, RightElem, type, placeholder, 
    onChange, onKeyUp, wrapperClasses
  } = props;

  return (
    <div className={`${classes.elemWithIcon_inputWrap} ${wrapperClasses}`}>

      {leftIcon && (
        <span className={classes.elemWithIcon_iconWrap}>
          <FontAwesomeIcon 
            className={classes.elemWithIcon_icon}
            icon={leftIcon} />
        </span>
      )}

      <input
        name={name}
        value={value}
        className={`${classes.elemWithIcon_element} ${classes.elemWithIcon_input}`}
        onChange={onChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        type={type}
      >
      </input>

      {RightElem && RightElem}

    </div>
  );
};

export default InputWithIcons;