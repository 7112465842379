import { NameBoolean, NameString } from "../Types/Types";

export const soldOutRadioOptions: NameBoolean[] = [
  { name: "Available", value: false },
  { name: "Soldout", value: true }
];

export const forceCustomizationBeforeAddOptions: NameBoolean[] = [
  { name: "Quick Add", value: false },
  { name: "Customize Add", value: true }
];

export enum AddCondition {
  all = "all",
  signIn = "signedIn",
}

export enum OptionType {
  all = "all",
  giftCard = "giftCard",
  eventTicket = "eventTicket",
}

export const optionAddConditionOptions: NameString[] = [
  { name: "All", value: AddCondition.all },
  { name: "Signed In", value: AddCondition.signIn }
];

export const optionTypeOptions: NameString[] = [
  { name: "All", value: OptionType.all },
  { name: "Gift Card", value: OptionType.giftCard },
  { name: "Event Ticket", value: OptionType.eventTicket },
];