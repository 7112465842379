import { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableContainer } from '@material-ui/core';


interface SortableTableProps {
  content: (item, listeners) => React.ReactNode;
  item: any;
  sortIndex: string;
  styles?: CSSProperties;
  className?: string;
}

const SortableTable: React.FC<SortableTableProps> = (props) => {
  const { item, content, sortIndex, styles, className } = props;

  const {
    attributes, listeners, setNodeRef, transform, transition,
  } = useSortable({ id: sortIndex });

  const style: CSSProperties = {
    ...styles,
    transform: CSS.Transform.toString(transform),
    transition: transition ?? "",
  };

  return (
    <TableContainer
      ref={setNodeRef}
      {...attributes}
      // {...listeners}
      style={style}
      className={className}
    >
      {content(item, listeners)}
    </TableContainer>
  );
};

export default SortableTable;
