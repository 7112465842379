export const getFormattedPhoneNumber = (userPhoneNumber?: string) => {
  if (!userPhoneNumber) return "";

  userPhoneNumber = userPhoneNumber.replaceAll(' ', '');

  if (userPhoneNumber.length < 10) {
    return userPhoneNumber;
  }

  if (userPhoneNumber.length === 10) {
    const firstThree = userPhoneNumber.slice(0, 3);
    const nextThree = userPhoneNumber.slice(3, 6);
    const rest = userPhoneNumber.slice(6, userPhoneNumber.length);
    const newValue = `(${firstThree})` +
      (nextThree && " " + nextThree) + (rest && " " + rest);
    return newValue;
  }
}

export const validatePhone = (number?: string) => {
  if (!number) return false;
  if (isNaN(Number(number))) return false;
  if (number.length === 0) return false;
  if (number.length < 10) return false;

  return true;
}

const plusOneFormatRegex = new RegExp("^\\+1[\\d]{10}$");

export const normalizeFormattedPhoneNumber = (formattedPhone?: string) => {
  if (!formattedPhone) return "";
  // (123) 456 7890 -> (123)4567890
  formattedPhone = formattedPhone.replaceAll(' ', '');

  if(plusOneFormatRegex.test(formattedPhone)) {
    // +14255550100 -> 4255550100
    formattedPhone = formattedPhone.substring(2);
    return formattedPhone;
  }

  const phoneNum = formattedPhone.replaceAll(/\D/g, '');
  return phoneNum;
}
