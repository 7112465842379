import React from 'react';
import classes from './SideNavigation.module.scss';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

export interface AccordionNavLinkProps {
  summary: React.ReactNode;
  items?: React.ReactNode;
}

const AccordionNavLink: React.FC<AccordionNavLinkProps> = (props) => {
  const { summary, items } = props;

  return (
    <Accordion
      className={classes.sideNav_accordionWrap}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.sideNav_item}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={classes.sideNav_accordion}>
        {items}
      </AccordionDetails>
    </Accordion>
  );
}; export default AccordionNavLink;

