import classes from "./AccountPage.module.scss";
import SignIn from "../../components/Account/SignIn";
import AccountHeader from "../../components/Account/AccountHeader";
import ForgotPassword from "../../components/Account/ForgotPassword";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useEffect } from "react";
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import ChangePassword from "../../components/Account/ChangePassword";
import { BusinessPortalUrlParams } from "../BusinessPortal";

const AccountPage: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const {businessName} = useParams<BusinessPortalUrlParams>();
  const history = useHistory();
  const account = useTypedSelector(state => state.account);

  useEffect(() => {
    if (account.isSignedIn) {
      if (location.pathname.includes("changePassword")) {
        return;
      }

      history.push(`${businessName}/dashboard`);
    }
  }, [account.isSignedIn, location.pathname, history, businessName]);

  return (
    <Switch>
      <div className={classes.account}>
        <div className={classes.content}>
          <AccountHeader />
          <Route exact path={[`${path}`, `${path}/signIn`]}>
            <SignIn />
          </Route>
          <Route exact path={`${path}/forgot`}>
            <ForgotPassword />
          </Route>
          <Route exact path={`${path}/changePassword`}>
            <ChangePassword />
          </Route>
        </div>
      </div>
    </Switch>
  );
}

export default AccountPage;
