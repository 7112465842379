import classes from "./BusinessConfigs.module.scss";
import CenteredCell from "../../components/Tables/CenteredCell";
import { DiscountRuleDTO, DISCOUNT_VALUE_TYPE } from "../../Types/Types";
import {
  Table, TableBody, TableCell, TableContainer, TableRow, TableHead
} from '@material-ui/core';
import { Button } from "semantic-ui-react";
import { MouseEventHandler } from "react";
import { OverallFormType } from "../../components/OverallPriceModifierEditForm/OverallPriceModifierEditForm";

interface OverallPriceAdjustmentTableProps {
  onAddBtnClick: MouseEventHandler;
  onEditClick: (discount: DiscountRuleDTO) => void;
  onRemoveClick: (discount: DiscountRuleDTO) => void;
  overallDiscounts: DiscountRuleDTO[];
  typeName: string;
  tableType: OverallFormType;
}

export const OverallPriceAdjustmentTable = (props: OverallPriceAdjustmentTableProps) => {
  const {
    tableType, typeName, overallDiscounts, onAddBtnClick, onEditClick, onRemoveClick
  } = props;

  const isChargeType = tableType === OverallFormType.Charge;
  const discountRow = (discount: DiscountRuleDTO) => {
    let value = discount.value;

    if(isChargeType && value < 0){
      value = -value;
    }

    const fixedAmt = (value / 100).toFixed(2);
    const isAmountDsc = discount.type === DISCOUNT_VALUE_TYPE.amount;
    const isPercentDsc = discount.type === DISCOUNT_VALUE_TYPE.percentage;
    const valueFormatted = isAmountDsc ? fixedAmt : value.toString();
    return (
      <TableRow key={`${discount.title}_${discount.type}`}>
        <CenteredCell>
          {discount.title}
        </CenteredCell>
        <CenteredCell>
          {discount.type}
        </CenteredCell>
        <CenteredCell>
          {isAmountDsc && "$"}
          {valueFormatted}
          {isPercentDsc && "%"}
        </CenteredCell>
        <CenteredCell>
          <Button
            content="Edit"
            onClick={e => onEditClick(discount)}
          />
          <Button
            content="Remove"
            className={classes.body_discount_deleteButton}
            onClick={e => onRemoveClick(discount)}
          />
        </CenteredCell>
      </TableRow>
    )
  }


  const discountsHeader = () => {
    return (
      <TableHead>
        <TableRow>
          <CenteredCell>Name</CenteredCell>
          <CenteredCell>Type</CenteredCell>
          <CenteredCell>Value</CenteredCell>
          <TableCell >
            <Button
              content={`Add ${typeName}`}
              onClick={onAddBtnClick}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    )
  }

  return (
    <TableContainer className={classes.table_container}>
      <Table>
        <TableHead>
          <TableRow>

            <CenteredCell>
              <h1>{typeName}</h1>
            </CenteredCell>

            <CenteredCell></CenteredCell>
            <CenteredCell></CenteredCell>
            <TableCell>
            </TableCell>
          </TableRow>
        </TableHead>
        {discountsHeader()}

        <TableBody>
          {overallDiscounts &&
            overallDiscounts.map(discountRow)}
        </TableBody>

      </Table>
    </TableContainer>
  );
};
