import moment from 'moment';
import { formatMoney, formatPhone } from '../../common/pageUtils';
import { nameof } from 'ts-simple-nameof';
import { GridColDef, GridValueFormatterParams } from '@material-ui/data-grid';
import { paymentMethods } from './ReportPageToolbar';
import { BusinessOrder } from '../../Types/Types';

const defaultColumnWidth = 135;
const columnsDefinitions: GridColDef[] = [
  {
    field: nameof<BusinessOrder>(order => order.orderId),
    headerName: "Order Number", width: 152,
  },
  {
    field: nameof<BusinessOrder>(order => order.orderType),
    headerName: "Order Type", width: defaultColumnWidth,
    valueFormatter: (params: GridValueFormatterParams) => {
      const valueStr = params.value as string;
      return valueStr[0].toUpperCase() + valueStr.substring(1);
    },
  },
  {
    field: nameof<BusinessOrder>(order => order.selectedTipPercentage),
    headerName: "Tip Percentage", width: 160,
    valueFormatter: (params: GridValueFormatterParams) => {
      const number = params.value as number;
      if (number === 0)
        return "-";
      return `${params.value} %`;
    },
  },
  {
    field: nameof<BusinessOrder>(order => order.tip),
    headerName: "Tip Amount", width: defaultColumnWidth,
    valueFormatter: (params: GridValueFormatterParams) => {
      const number = params.value as number;
      if (number === 0)
        return "-";
      const valueFormatted = Number(number / 100).toFixed(2);
      return `$${valueFormatted}`;
    },
  },
  {
    field: nameof<BusinessOrder>(order => order.subTotal),
    headerName: "Sub Total", width: defaultColumnWidth,
    valueFormatter: (params: GridValueFormatterParams) => `$${formatMoney(params.value)}`
  },
  {
    field: nameof<BusinessOrder>(order => order.total),
    headerName: "Total Amount", width: defaultColumnWidth,
    valueFormatter: (params: GridValueFormatterParams) => `$${formatMoney(params.value)}`
  },
  {
    field: nameof<BusinessOrder>(order => order.instructions),
    headerName: "Instructions", width: defaultColumnWidth,
  },
  {
    field: nameof<BusinessOrder>(order => order.userPhoneNumber),
    headerName: "Customer Phone Number", width: 200,
    valueFormatter: (params: GridValueFormatterParams) => {
      return formatPhone(params.value?.toString());
    },
  },
  {
    field: nameof<BusinessOrder>(order => order.phoneOS),
    headerName: "Device", width: 110,
  },
  {
    field: nameof<BusinessOrder>(order => order.receiptEmail),
    headerName: "Customer Email", width: defaultColumnWidth,
  },
  {
    field: nameof<BusinessOrder>(order => order.paymentStatus),
    headerName: "Payment Status", width: defaultColumnWidth,
    valueFormatter: (params: GridValueFormatterParams) => {
      const paymentMethod = paymentMethods.find(method => method.value === params.value as string);
      return paymentMethod?.name;
    },
  },
  {
    field: nameof<BusinessOrder>(order => order.orderDateAndTime),
    headerName: "Order Date and Time", width: 200,
    valueFormatter: (params: GridValueFormatterParams) => {
      if (!params.value)
        return "";
      return moment(params.value as number).format('LLL');
    },
  },
];export default columnsDefinitions;

