import classes from "./PopupForm.module.scss";
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { isDirty } from "../../common/bizConfigUtils";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { ValidationError } from "../../Types/Types";
import { Button } from "@material-ui/core";
import GlobalModal from "../GlobalModal/GlobalModal";

interface useFormProps<T> {
  initData: T;
  onFetchData?: () => Promise<T | void>;
  onSaveData: (formData: T) => Promise<T | void>;
  clone: (formData: T) => T;

  title: string;
  bodyContent: (handleChange: (name: string, value) => void, formData?: T) => ReactNode;
  footerContent?: ReactNode;
  yesBtnLabel?: string;
  noBtnLabel?: string;

  modalOpen: boolean;
  setModalOpen: Function;

  setValidationErrors: Dispatch<SetStateAction<ValidationError[]>>;
}


const closeOnDirtyDataConfirmBody = (
  <>
    <div>There are unsaved modifications</div>
    <div>Are you sure you want to leave without saving?</div>
  </>
)


const PopupForm = function <T>(props: useFormProps<T>) {
  const { initData,
    title, bodyContent, yesBtnLabel, noBtnLabel, footerContent,
    onSaveData, onFetchData, clone,
    modalOpen, setModalOpen, setValidationErrors
  } = props;

  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const [formData, setFormData] = useState<T | undefined>();
  const [originalData, setOriginalData] = useState<T | undefined>();
  const [isConfirm, setIsConfirm] = useState(false);

  useEffect(() => {
    const fetchInitData = async () => {
      if (!onFetchData) return;

      const data = await onFetchData();
      if (!data) return;

      setFormData(data);
      setOriginalData({ ...data });
    }

    fetchInitData();
    return () => { }
  }, []);

  useEffect(() => {
    if (!modalOpen) return;

    setFormData(initData);
    setOriginalData({ ...initData });
    setValidationErrors([]);

    return () => { }
  }, [modalOpen])


  const saveFormData = async () => {
    if (!formData) return;
    await onSaveData(clone(formData));
  }

  const handleChange = (fieldName: string, value) => {
    if (!formData) return;

    setFormData({
      ...formData,
      [fieldName]: value
    })

    setValidationErrors(prev => {
      const deleteIndex = prev.findIndex(vErr => vErr.fieldName === fieldName);
      if (deleteIndex !== -1) {
        prev.splice(deleteIndex, 1);
      }
      return [...prev];
    })
  }

  const handleIsDirty = (e) => {
    const dirty = isDirty(originalData, formData);
    if (dirty) {
      setIsConfirm(true);
      return;
    }

    setModalOpen(false);
  }

  const themedStyle = { backgroundColor: businessInfo?.theme?.color ?? undefined };
  const confirmButtons = (
    <div className={classes.buttons}>

      <Button
        onClick={() => {
          setIsConfirm(false);
          // setModalOpen(true);
        }}
        className={classes.confirmNoBtn}>
        No
      </Button>

      <Button
        onClick={() => {
          setIsConfirm(false);
          setModalOpen(false);

          if (!originalData) return;
          setFormData({ ...originalData });
        }}
        className={classes.confirmBtn}
        style={themedStyle}>
        Yes
      </Button>

    </div>
  )

  const onSave = () => {
    saveFormData();
  }


  const getFooter = () => {
    if (footerContent) return footerContent;

    return (
      <div className={classes.buttons}>

        <Button onClick={handleIsDirty}
          className={classes.cancelBtn}>
          {noBtnLabel ?? "Cancel"}
        </Button>

        <Button onClick={onSave}
          className={classes.saveBtn}
          style={{ backgroundColor: businessInfo?.theme?.color }}>
          {yesBtnLabel ?? "Save"}
        </Button>

      </div>
    )
  }


  return <GlobalModal
    open={modalOpen}
    handleClose={handleIsDirty}
    title={isConfirm ? "Warning!" : title}
    body={
      <div className={classes.formBody}>
        {isConfirm ? closeOnDirtyDataConfirmBody : bodyContent(handleChange, formData)}
      </div>
    }
    buttons={isConfirm ? confirmButtons : getFooter()}
  />
}


export default PopupForm;