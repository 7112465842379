import axios from "axios";
import { createFormData } from "../common/pageUtils";
import { baseUrl } from "../appConfigs";
import { MenuCategoryDTO } from "../Types/Types";
import { getAuthConfig, getMultipartAuthConfig } from "./apiAuthConfig";


export const getAllCategories = async (serviceProviderId: string) => {
  try {
    return await axios.get(`${baseUrl}/rest/productCategory/admin/${serviceProviderId}`);
  } catch (err) {
    throw err;
  }
}

export const getCategory = async (categoryId: number) => {
  try {
    return await axios.get(`${baseUrl}/rest/productCategory/search/${categoryId}`);
  } catch (err) {
    throw err;
  }
}

export const updateCategory = async (updatedData: MenuCategoryDTO) => {
  try {
    return await axios.post(`${baseUrl}/rest/productCategory`, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const addCategory = async (newCategory: MenuCategoryDTO) => {
  try {
    return await axios.put(`${baseUrl}/rest/productCategory`, newCategory, getAuthConfig())
  } catch (err) {
    throw err;
  }
}

export const deleteCategory = async (categoryId: number, serviceProviderId: string) => {
  try {
    const url = `${baseUrl}/rest/productCategory/${categoryId}/${serviceProviderId}`;
    return await axios.delete(url, getAuthConfig())
  } catch (err) {
    throw err;
  }
}

export const postCategoryImage = async (imageFile, categoryId) => {
  try {
    const form = createFormData(imageFile);
    form.append("productCategoryId", categoryId);
    
    const uploadUrl = `${baseUrl}/rest/file/upload/menuCategoryImage`;
    return await axios.post(uploadUrl, form, getMultipartAuthConfig());
  } catch (err) {
    console.error(err);
  }
}
