import classes from "./LocationSelectorPage.module.scss";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Loader } from "semantic-ui-react";
import { baseUrl } from "../../appConfigs";
import { BusinessInfo } from "../../Types/Types";
import { appendBaseUrlToImage } from "../../common/pageUtils";
import { Button } from "@material-ui/core";

interface LocationSelectorUrlParams {
    serviceProviderUserId: string;
    businessName: string;
}

interface Address {
    businessId: number,
    serviceProviderUserId: number,
    name: string,
    parentBusinessName: string,
    json?: BusinessInfo,
    stripePublishKey?: null,
    stripeSecretKey?: string,
    pushToken?: string,
    createDate: string,
    updateDate: string,
    visitsCount: number
}

const LocationSelectorPage = (props) => {
    const { serviceProviderUserId, businessName } = useParams<LocationSelectorUrlParams>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [addresses, setAddresses] = useState<Address[]>([]);

    useEffect(() => {
        const fetchLocationInfo = async () => {
            if (!serviceProviderUserId) return;

            const locationRes = await axios.get(`${baseUrl}/rest/business/businessLocationList/${serviceProviderUserId}`);
            const addressList = locationRes.data as Address[];

            setAddresses(addressList.map(addr => {
                try {
                    if (addr.json !== undefined) {
                        addr.json = JSON.parse(addr.json as any);
                    }
                } catch (error) {
                    console.error("Parsing error", error);
                }

                return addr;
            }));
            setIsLoading(false);
        }

        fetchLocationInfo();
        return () => {
            // setAddresses([]);
        }
    }, [])

    useEffect(() => {
        if (addresses.length === 0) return;

        if (addresses.length === 1) {
            const singleAddr = addresses[0];
            history.push(`/${singleAddr.name}`);
        }

        return () => {
            // setAddresses([]);
        }
    }, [addresses])


    if (isLoading) return <Loader />;

    const singleAddr = addresses[0];
    const logoSize = 128;

    const placeholderLogo = "https://blog.hubspot.com/hubfs/image8-2.jpg";
    const appendedLogo = appendBaseUrlToImage(singleAddr?.json?.logo);

    const LocationButtons = addresses.map(address => {
        const bizJson = address.json;
        const theme = bizJson?.theme;
        return (
            <Button
                className={classes.button}
                style={{
                    background: theme?.color || "black",
                    fontFamily: theme?.font || "Quicksand, sand-serif"
                }}
                onClick={(e) => history.push(`/${address?.name}`)}
            >
                {bizJson?.name}
            </Button>
        );
    });

    return (
        <div className={classes.page}>
            <div className={classes.bizInfo}>
                <img width={logoSize}
                    src={appendedLogo || placeholderLogo}
                    alt={`logo of ${singleAddr?.parentBusinessName || "business name"}`}
                />
                {singleAddr?.parentBusinessName || "Business name"}
            </div>

            <div className={classes.buttons}>
                {LocationButtons}
            </div>
        </div>)
}

export default LocationSelectorPage;
