import { Divider } from "@material-ui/core";
import { FC, MouseEventHandler, PropsWithChildren, useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import Popup, { DefaultPopupProps } from "./Popup";
import classes from "./Popup.module.scss";

interface OkCancelPopupProps extends Omit<DefaultPopupProps, "handleClose"> {
  onCancel: (event:
    React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  onOk: MouseEventHandler<Element>;
  okButtonContent?: string;
  disabled?: boolean;
  handleClose?: (event:
    React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
}

const OkCancelPopup: FC<OkCancelPopupProps> = (props) => {
  const { businessInfo } = useTypedSelector((state) => state.businessInfo);
  const {
    header, titleWrapStyles, title, titleStyle, content, contentStyle, footer,
    onOk, onCancel, handleClose, okButtonContent, showOk = true, disabled = false,
  } = props as OkCancelPopupProps;


  const titleWrapCSS: React.CSSProperties = {
    backgroundColor: businessInfo?.theme?.color,
    color: "#FFF",
    ...titleWrapStyles
  };

  const contentCSS: React.CSSProperties = {
    marginBottom: footer ? "" : "20px",
    ...contentStyle,
  }

  const okayBtn = (
    <>
      <Divider className={classes.okCancelFooter_separator} />
      <div className={classes.okCancelFooter}>

        <button onClick={onCancel ?? handleClose}
          className={classes.okCancelFooter_cancelButton}
        >Cancel
        </button>

        <button onClick={onOk}
          disabled={disabled}
          className={classes.okCancelFooter_okayButton}
        >{okButtonContent ?? "OK"}
        </button>

      </div>
    </>
  )

  return (
    <Popup
      {...props}
      handleClose={handleClose ?? onCancel}
      contentStyle={contentCSS}
      footer={okayBtn}
    />
  );
}

export default OkCancelPopup;