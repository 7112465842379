import { Action } from "redux";
import { ActionType } from "../action-types";

interface AppStoreState {
  isLoading: boolean;
}

const initState: AppStoreState = {
  isLoading: false,
};

const appReducer = (
  state = initState,
  action: Action
): AppStoreState => {
  switch (action.type) {
    case ActionType.SET_APP_LOADING:
      return { isLoading: true };
    case ActionType.SET_APP_LOADED:
      return { isLoading: false };
    default:
      return state;
  }
};

export default appReducer;