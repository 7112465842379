import classes from "./BreadcrumbLink.module.scss";
import { NavLink, useParams, useRouteMatch } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { Breadcrumb } from "../../Types/Types";
import { useActions } from "../../hooks/useActions";
import { CSSProperties, useEffect, useState } from "react";
import { MenuEditUrlParams } from "../PortalHeader";

interface BreadcrumbPropBase {
  color?: string;
  className?: string;
  style?: CSSProperties;
  persist?: boolean;
  isButton?: boolean;
}

interface BreadcrumbByObjectLinkProp extends BreadcrumbPropBase {
  breadcrumb: Breadcrumb;
  displayName?: never;
  pathName?: never;
}

interface BreadcrumbByPropsLinkProp extends BreadcrumbPropBase {
  breadcrumb?: never;
  displayName: string;
  pathName: string;
}


type BreadcrumbLinkProp = BreadcrumbByObjectLinkProp | BreadcrumbByPropsLinkProp;

const BreadcrumbLink: React.FC<BreadcrumbLinkProp> = (props) => {
  const { breadcrumb, color = "grey", isButton = false } = props as BreadcrumbByObjectLinkProp;
  const { displayName, pathName, style, persist = false } = props as BreadcrumbByPropsLinkProp;
  const { businessName } = useParams<MenuEditUrlParams>();
  const match = useRouteMatch();
  const { breadcrumbs } = useTypedSelector(state => state.businessPortal);
  const { setBusinessPortalBreadcrumbs } = useActions();
  const [newBreadcrumbs, setNewBreadcrumbs] = useState<Breadcrumb[]>([]);


  const path = pathName ?? breadcrumb.pathName;
  useEffect(() => {
    if (breadcrumbs.length === 0) {
      setNewBreadcrumbs([...newBreadcrumbs, breadcrumb ?? {
        pathName: pathName,
        displayName: displayName,
      }]);
      return;
    }

    const previousBcs: Breadcrumb[] = [];
    const foundBc = breadcrumbs.find(bc => {
      if (!bc) return false;
      previousBcs.push(bc);
      return bc.pathName === path;
    });

    if (foundBc) {
      setNewBreadcrumbs([...previousBcs]);
      return;
    }
    const newBc = { pathName: pathName, displayName: displayName };
    setNewBreadcrumbs([...previousBcs, breadcrumb ?? newBc]);
  }, []);


  let className = isButton ? classes.breadcrumb_button : "";
  className += props.className ? " " + props.className : "";
  const urlPath = `${`/${businessName}`}${path}`;
  return (
    <NavLink
      // to={{
      //   pathname: `${portalBaseUrl}${path}`,
      //   state: { persist: persist }
      // }}
      to={urlPath}
      isActive={() => {
        if (!match) {
          return false;
        }
        
        return match.url === urlPath;
      }}
      exact
      style={{
        ...style,
        color: color,
      }}
      className={className}
      // activeClassName={classes.breadcrumb_link_active}
      activeStyle={{
        fontWeight: "bold",
        color: "black"
      }}
      onClick={e => {
        const isNewArrayEmpty = newBreadcrumbs.length === 0;
        const newValues = isNewArrayEmpty ? breadcrumbs : newBreadcrumbs;
        setBusinessPortalBreadcrumbs(newValues);
      }}
    >
      {props.children ??
        // <div >
          displayName ?? breadcrumb.displayName
        // </div>
      }
    </NavLink>
  );
};

export default BreadcrumbLink;
