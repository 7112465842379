import classes from './ReportPage.module.scss';
import { useEffect, useState } from 'react';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { getBizOrders } from '../../api/statistics';
import { DataGrid, GridRowsProp, } from '@material-ui/data-grid';
import { BusinessOrder } from '../../Types/Types';
import SearchToolbar, { SearchToolbarProps } from './ReportPageToolbar';
import columnsDefinitions from './ReportsColumnsDefinitions';
import GrandTotalsFooter from './GrandTotalsFooter';

const ReportPage: React.FC = () => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAppLoading, setAppLoaded } = useActions();
  const [allRows, setAllData] = useState<BusinessOrder[]>();
  const [rows, setRows] = useState<GridRowsProp>();

  useEffect(() => {
    const fetchInitialOrders = async () => {
      setAppLoading();
      if (!businessInfo?.serviceProviderUserId) return;
      if (!businessInfo?.businessId) return;

      const res = await getBizOrders(businessInfo.serviceProviderUserId, 7, businessInfo.businessId);
      const fetchedRows = res.data.map((row: BusinessOrder) => (
        { ...row, id: row.orderId }
      ));
      setAllData(fetchedRows);
      setRows(fetchedRows);
      setAppLoaded();
    }

    fetchInitialOrders();
  }, [businessInfo?.serviceProviderUserId, businessInfo?.businessId]);

  return (
    <div className={classes.reportsPage}>

      <div className={classes.header}>
        <h1 className={classes.header_title}>
          Reports
        </h1>
      </div>

      <DataGrid
        rows={rows ?? []}
        columns={columnsDefinitions}
        hideFooter={false}
        components={{
          Toolbar: SearchToolbar,
          Footer: GrandTotalsFooter,
        }}
        componentsProps={{
          toolbar: {
            setAllData,
            setRows,
            allRows,
          } as SearchToolbarProps,
          footer: {
            rows: rows as BusinessOrder[]
          }
        }}
      />

    </div>
  );
}



export default ReportPage;