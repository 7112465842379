import classes from './BestSellerToolbar.module.scss';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import columnsDefinitions from "./BestSellersColumnsDefinitions";
import { IconButton, MenuItem, Select, SelectProps, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { BestSellerInfo } from './BestSellersPage';
import { useActions } from '../../hooks/useActions';
import { bestSellerNumLastYears } from '../../appConfigs';
import { GridToolbarExport } from '@material-ui/data-grid';

export interface SearchToolbarProps {
  setRows: (rows: any) => void;
  fetchByYearMonth: (year: any, month: any) => Promise<BestSellerInfo[]>;
  allRows: BestSellerInfo[];
}


export const yearOptions = (() => {
  let options: Array<{ name: number, value: number }> = []

  const date = new Date();
  for (let i = 0; i < bestSellerNumLastYears; i++) {
    const year = date.getFullYear();
    options.push({ name: year, value: year });
    date.setFullYear(year - 1);
  }
  return options;
})()

const monthOptions = (() => {
  let options: Array<{ name: string, value: number }> = [
    { name: "All", value: -1 }
  ]

  const date = new Date();
  date.setMonth(0);
  for (let i = 0; i < 12; i++) {
    const month = date.getMonth();
    const monthDisplayVal = date.toLocaleString('en-ca', { month: 'short' });
    options.push({ name: monthDisplayVal, value: month });
    date.setMonth(month + 1);
  }
  return options;
})()

interface FilterValueFields {
  year: number;
  month: number;
}

const displayedColumns = columnsDefinitions.map(column => column.field);

const SearchToolbar = (props: SearchToolbarProps) => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAppLoaded } = useActions();
  const { fetchByYearMonth, setRows, allRows } = props;
  const [quickSearchValue, setSearchText] = useState('');
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(-1);

  useEffect(() => {

  }, []);


  const quickSearch = (searchValue: string) => {
    setSearchText(searchValue);
    if (!searchValue) {
      setRows(allRows);
      return;
    }
    const newSearchRegex = new RegExp(searchValue, 'i');
    const filteredRows = allRows.filter(row => quickFilter(row, newSearchRegex));
    setRows(filteredRows);
  };


  const handleDropDownChanges = async (year: number, month: number) => {
    const newRows = await fetchByYearMonth(year, month);
    setAppLoaded();

    const filteredRows = newRows.filter(row => quickFilter(row));
    setRows(filteredRows);
  }

  const searchRegex = new RegExp(quickSearchValue, 'i');
  const quickFilter = (row: BestSellerInfo, regex?: RegExp) => {
    const values = Object.entries(row);
    const reg = regex ?? searchRegex;
    return values.some((val) =>
      displayedColumns.includes(val[0]) && reg.test(val[1])
    );
  }


  return (
    <div className={classes.root}>

      <DropDown value={year}
        name={nameof<FilterValueFields>(filter => filter.year)}
        onChange={e => {
          const newVal = e.target.value as number;
          handleDropDownChanges(newVal, month);
          setYear(newVal);
        }}
        options={yearOptions}
      />

      <DropDown value={month}
        name={nameof<FilterValueFields>(filter => filter.month)}
        onChange={e => {
          const newVal = e.target.value as number;
          handleDropDownChanges(year, newVal);
          setMonth(newVal);
        }}
        options={monthOptions}
      />

      <TextField
        variant="standard"
        value={quickSearchValue}
        onChange={event => quickSearch(event.target.value)}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: quickSearchValue ? 'visible' : 'hidden' }}
              onClick={e => quickSearch('')}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }} />

    </div>
  );
};

interface DropdownProps {
  value: string | number;
  name: string;
  onChange: SelectProps["onChange"];
  options: any[];
}

const DropDown = (props: DropdownProps) => {
  const { value, name, onChange, options } = props;

  return (
    <Select value={value}
      name={name}
      onChange={onChange}
    >
      {options.map(method => {
        return (
          <MenuItem key={method.value} value={method.value}>
            {method.name ? method.name : "None"}
          </MenuItem>
        )
      })}
    </Select>
  )
}


export default SearchToolbar;