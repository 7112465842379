import { BusinessInfo, FoodCategory, MenuByCategory } from "./../../Types/Types";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface BusinessInfoState {
  error: string | null;
  businessInfo: BusinessInfo | null;
  foodsCategories: FoodCategory[];
  productsInfo: MenuByCategory | null;
}

const initialState = {
  error: null,
  businessInfo: null,
  foodsCategories: [],
  productsInfo: null,
};

const reducer = (
  state: BusinessInfoState = initialState,
  action: Action
): BusinessInfoState => {
  switch (action.type) {
    case ActionType.FETCH_BUSINESSINFO_SUCCESS:
      return { ...state, businessInfo: action.payload };
    case ActionType.FETCH_BUSINESSINFO_ERROR:
      return { ...state, error: action.payload };
    case ActionType.FETCH_FOODS_CATEGORIES_SUCCESS:
      return { ...state, foodsCategories: action.payload };
    case ActionType.FETCH_FOODS_CATEGORIES_ERROR:
      return { ...state, error: action.payload };
    case ActionType.FETCH_PRODUCTS_SUCCESS:
      return { ...state, productsInfo: action.payload };
    case ActionType.FETCH_PRODUCTS_ERROR:
      return { ...state, error: action.payload };
    case ActionType.SET_CREDIT_DISCOUNT:
      const overallDiscounts = state.businessInfo?.overallDiscounts;
      const credit = overallDiscounts?.find(dsc => dsc.title === "Credit");

      if (credit) {
        credit.value = action.payload
      }
      
      return state;
    case ActionType.ADD_OVERALL_DISCOUNT:
      if (!state.businessInfo) return state;
      
      const newBusinessInfo = state.businessInfo;
      if (newBusinessInfo.overallDiscounts  === null) {
        newBusinessInfo.overallDiscounts = [];
      }

      const currentDiscounts = newBusinessInfo.overallDiscounts;
      const promoDiscount = action.payload;
      if (currentDiscounts?.some(dsc=>dsc.title === promoDiscount.title)) {
        return state;
      }

      newBusinessInfo.overallDiscounts?.push(promoDiscount);
      return {...state, businessInfo: newBusinessInfo};
    default:
      return state;
  }
};

export default reducer;
