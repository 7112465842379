import { nameof } from "ts-simple-nameof";
import { baseUrl, imgUploadSizeLimit } from "../appConfigs";
import { DaysCheckboxesType, VisibilityRules } from "../Types/Types";

export const numberToString = (num?: number) => {
  if (num || num === 0) {
    return num.toString().padStart(4, '0');
  }
  return "";
}

export const daysFields = [
  nameof<VisibilityRules>(d => d.Sun),
  nameof<VisibilityRules>(d => d.Mon),
  nameof<VisibilityRules>(d => d.Tue),
  nameof<VisibilityRules>(d => d.Wed),
  nameof<VisibilityRules>(d => d.Thu),
  nameof<VisibilityRules>(d => d.Fri),
  nameof<VisibilityRules>(d => d.Sat),
];

export const daysMapping = {
  Mon: "Monday",
  Tue: "Tuesday",
  Wed: "Wednesday",
  Thu: "Thursday",
  Fri: "Friday",
  Sat: "Saturday",
  Sun: "Sunday",
}

export const nullReplacer = (key, value) => value ?? "";

export const mapDaysToForm = (days: string[], formData) => {
  daysFields.forEach(day => {
    formData[day] = days.includes(day);
  });
}

export function ValidateFileSize(file: File) {
  if (!file) return false;
  const FileSizeMB = file.size / 1024 / 1024; // in MB
  if (FileSizeMB > imgUploadSizeLimit) {
    return false;
  }
  return true;
}

export const createFormData = (imageFile: File) => {
  const form = new FormData();
  form.append("file", imageFile, imageFile.name);
  return form;
}

export const convertImageSrc = (imageSrc?: string) => {
  if (imageSrc && imageSrc?.startsWith('/')) {
    return `${baseUrl}${imageSrc}`;
  }
  return imageSrc;
}

export const formatMoney = (price, isAmount = true, setToFixed = true): string => {
  if (!price && price !== 0) return '';
  if (!isAmount || isNaN(+price)) {
    return price.toString();
  }
  let dividedByHundred = price / 100;

  let resStr = dividedByHundred.toString();
  if (setToFixed) {
    resStr = dividedByHundred.toFixed(2);
  }
  const replaced = resStr.replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return replaced;
}

export const normalizeMoney = (price, isAmount = true) => {
  if (!price) return 0;
  if (!isAmount) {
    return Number(price);
  }
  const fixedPrice = Number(price).toFixed(2);
  const normalizedPrice = Number(fixedPrice) * 100;
  const roundedPrice = Math.round(normalizedPrice);
  return roundedPrice;
}

export const timeNumberToStr = (time?: number | null) => {
  if (time === undefined || time === null) return "";
  const timeStr = time.toString() ?? "";
  return timeStr.padStart(4, '0');
}

export const timeStrToNumber = (time?: string) => {
  return time ? Number(time) : undefined;
}

export interface ISortable {
  sortingOrder: number
}

export const reorderList = <T extends ISortable>(
  list: T[], oldIndex: number, newIndex: number
) => {
  const deletedItems = list.splice(oldIndex, 1);
  const draggedItem = deletedItems[0];
  list.splice(newIndex, 0, draggedItem);
  list.forEach((option, index) => option.sortingOrder = index);
  return list;
}

const defaultDays = {
  Sun: false,
  Mon: false,
  Tue: false,
  Wed: false,
  Thu: false,
  Fri: false,
  Sat: false,
};

export const convertVisibilityDays = (daysArray: string[]): DaysCheckboxesType => {
  const newDays = { ...defaultDays };
  if (!daysArray) return newDays;
  daysArray.forEach(day => {
    newDays[day] = true;
  })
  return newDays;
}

export const formatPhone = (phoneVal?: string) => {
  const phoneAsNum = Number(phoneVal);
  if (isNaN(phoneAsNum)) return phoneVal;
  if (!phoneVal) return "";
  const firstThree = phoneVal.slice(0, 3);
  const nextThree = phoneVal.slice(3, 6);
  const rest = phoneVal.slice(6, phoneVal.length);
  return `(${firstThree}) ${nextThree && " " + nextThree} ${rest && " " + rest}`;
}

export const appendBaseUrlToImage = (img: string | null | undefined) => {
  if (!img || !img.startsWith('/')) return img ?? "";
  return `${baseUrl}${img}`;
}
