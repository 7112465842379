import axios from "axios";
import { createFormData } from "../common/pageUtils";
import { baseUrl } from "../appConfigs";
import { BusinessInfo } from "../Types/Types";
import { getAuthConfig, getMultipartAuthConfig } from "./apiAuthConfig";

interface BusinessInfoAddState {
  version: number;
  name: string;
  serviceProviderUserId: number;
  serviceProviderUserEmail: string;
}

export const addBusinessInfo = async (newData: BusinessInfoAddState) => {
  try {
    return await axios.put(`${baseUrl}/rest/business/new`, newData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const getBusinessInfo = async (id: string | number) => {
  try {
    return await axios.get(`${baseUrl}/rest/business/location/${id}`);
  } catch (err) {
    throw err;
  }
}

export const updateBusinessInfo = async (updatedData: BusinessInfo) => {
  try {
    const url = `${baseUrl}/rest/business/update`;
    return await axios.post(url, updatedData, getAuthConfig());
  } catch (err) {
    throw err;
  }
}

export const uploadBusinessLogo = async (imageFile: File, businessId: number | string) => {
  try {
    const form = createFormData(imageFile);
    form.append("businessId", businessId.toString());

    const uploadUrl = `${baseUrl}/rest/file/upload/businessLogo`;
    return await axios.post(uploadUrl, form, getMultipartAuthConfig());
  } catch (err) {
    console.error(err);
  }
}