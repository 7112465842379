import classes from './OptionForms.module.scss';
import GlobalModal from '../GlobalModal/GlobalModal';
import FormRow from '../FormRow/FormRow';
import { nameof } from 'ts-simple-nameof';
import { useState, ChangeEvent, useEffect } from "react";
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button } from '@material-ui/core';
import { ChoiceDTO, ChoiceListType, CustomizationDTO, NameBoolean, OptionFormData } from '../../Types/Types';
import { normalizeMoney, nullReplacer } from '../../common/pageUtils';
import { CustomizationUpdateDTO } from '../../api/optionsList';
import { soldOutRadioOptions } from '../../constants/formConstants';

interface CustomizationFormProps {
  showModal: boolean;
  setModalOpen: Function;
  isEdit: boolean;
  setFormData: Function;
  tryUpdate: Function;
  choice?: ChoiceDTO;
  customization: CustomizationDTO;
}

const CustomizationForm: React.FC<CustomizationFormProps> = (props) => {
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const {
    showModal, setModalOpen, isEdit, setFormData, choice, customization, tryUpdate
  } = props;
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [nameValidationError, setNameValidationError] = useState("");
  const [choiceListType, setChoiceListType] = useState<ChoiceListType>();
  const [oneOfSectionType, setOneOfSectionType] = useState<ChoiceListType>();
  const [originalData, setOriginalData] = useState({ ...choice });

  useEffect(() => {
    const anyOfChoice = customization.anyOf.choices.find(c => c === choice);
    const oneOfList = customization.oneOfList
      .find(list => list.choices.find(c => c === choice));

    const oneOfType = oneOfList ? oneOfList.sectionType : undefined;
    const choiceType = anyOfChoice ? "anyOf" : oneOfType;
    setChoiceListType(choiceType || "oneOf");
    setOneOfSectionType(oneOfList?.sectionType);
    if (!choice) return;

    if (!choice.type) {
      choice.type = choiceType === "anyOf" ? "number" : "boolean";
    }

    setOriginalData({ ...choice });
  }, [choice]);


  const isAnyOfList = choiceListType === "anyOf";
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const nameProp = target.name;

    if (!(choice && choice.hasOwnProperty(nameProp))) {
      console.warn(`No prop on formData called ${nameProp}!`);
      return;
    }

    setFormData((prevVal: CustomizationUpdateDTO) => {
      const newVal = { ...prevVal };
      const checkedInputs = ["checkbox", "radio"]
      const isNumber = target.type === "number";
      let value: any = checkedInputs.includes(target.type) ? target.checked : target.value;
      value = isNumber ? target.value : value;

      const isValueField = target.name === nameof<ChoiceDTO>(p => p.value);
      const isTypeField = target.name === nameof<ChoiceDTO>(p => p.value);

      const anyOfChoice = customization.anyOf.choices.find(c => c === choice);
      const oneOfListIndex = customization.oneOfList
        .findIndex(list => list.choices.find(c => c === choice));

      const isNewTypeBoolean = isTypeField && value === "boolean";
      const isChoiceTypeBoolean = isNewTypeBoolean || choice.type === "boolean";

      if (oneOfListIndex !== -1) {
        const oneOfList = customization.oneOfList[oneOfListIndex];
        const oneOfChoice = oneOfList.choices.find(ch => ch === choice);
        if (!oneOfChoice) return newVal;

        if (isValueField && isChoiceTypeBoolean) {
          value = value ? 1 : 0;
        }
        oneOfChoice[nameProp] = value;
      }

      if (anyOfChoice) {
        const anyOfChoice = newVal.customization.anyOf.choices.find(c => c === choice);
        if (!anyOfChoice) return newVal;

        if (isValueField && isChoiceTypeBoolean) {
          value = value ? 1 : 0;
        }

        anyOfChoice[nameProp] = value;
      }

      return newVal;
    });
  }

  const unitsOfMeasurements = [
    "", "pack", "spoon", "splash", "pump", "shot", "unit",
  ]
  const customizationTypes = [
    "", "add-on", "ingredient", "option",
  ]

  const valueTypes = [
    {
      name: "Option",
      value: "boolean"
    },
  ]

  if (isAnyOfList) {
    valueTypes.push({
      name: "Quantity",
      value: "number"
    });
  }

  const isBooleanChoice = choice?.type === "boolean";
  const isNumberChoice = choice?.type === "number";
  const oneOfIsAnyOf = oneOfSectionType === "anyOf";

  const valueFormRow = isBooleanChoice ?
    <FormRow
      label="Selected"
      name={nameof<ChoiceDTO>(p => p.value)}
      inputType="checkbox"
      value={choice?.value}
      handleChange={handleChange}
    />
    : <FormRow
      label="Value"
      name={nameof<ChoiceDTO>(p => p.value)}
      inputType="number"
      value={choice?.value}
      handleChange={handleChange}
    />;

  const modalBody = (
    <div className={classes.form}>
      <FormRow
        label="Name"
        name={nameof<ChoiceDTO>(p => p.name)}
        inputType="text"
        value={choice?.name}
        handleChange={handleChange}
      />
      <FormRow<NameBoolean>
        name={nameof<ChoiceDTO>(p => p.soldout)}
        inputType="radio"
        value={choice?.soldout}
        handleChange={event => {
          handleChange(event);

          if(!choice) return;
          const value = event.target.value;
          if(value === true) choice.value = 0;
        }}
        options={soldOutRadioOptions}
        valueField={radioOption => radioOption.value}
        displayField={radioOption => radioOption.name}
      />
      <FormRow
        label="Unit of Measurement"
        name={nameof<ChoiceDTO>(p => p.unitOfMeasurement)}
        inputType="dropdown"
        value={choice?.unitOfMeasurement}
        handleChange={handleChange}
        options={unitsOfMeasurements}
        valueField={selectOption => selectOption ?? ""}
      />
      {(isAnyOfList || oneOfIsAnyOf) && <FormRow
        label="Type"
        name={nameof<ChoiceDTO>(p => p.type)}
        inputType="dropdown"
        value={choice?.type}
        handleChange={handleChange}
        options={valueTypes}
        valueField={selectOption => selectOption?.value ?? ""}
        displayField={selectOption => selectOption?.name ?? ""}
      />}

      {valueFormRow}

      <FormRow
        label="Price per Unit"
        name={nameof<ChoiceDTO>(p => p.price)}
        inputType="price"
        value={choice?.price}
        handleChange={handleChange}
      />
      <FormRow
        label="Customization Type"
        name={nameof<ChoiceDTO>(p => p.customizationItemType)}
        inputType="dropdown"
        value={choice?.customizationItemType}
        handleChange={handleChange}
        options={customizationTypes}
        valueField={selectOption => selectOption ?? ""}
      />
    </div>);

  const onModalClose = (e) => {
    const originalJsonStr = JSON.stringify(originalData, nullReplacer);
    const dirtiedJsonStr = JSON.stringify(choice, nullReplacer);
    if (originalJsonStr !== dirtiedJsonStr) {
      setOpenConfirmPopup(true);
      return;
    }
    setModalOpen(false);
  }

  const onAdd = async (e) => {
    if (!validateForm()) return;
    if (!choice) return;
    choice.price = normalizeMoney(choice.price);
    tryUpdate(choice, choiceListType);
  }

  const onSave = async (e) => {
    if (!validateForm()) return;
    if (!choice) return;
    choice.price = normalizeMoney(choice.price);
    tryUpdate(choice, choiceListType, originalData);
  }

  const onPopupOk = async (event) => {
    setOpenConfirmPopup(false);
    setModalOpen(false);
  }

  const onPopupClose = (event) => {
    setModalOpen(true);
    setOpenConfirmPopup(false);
    setOpenDeleteConfirm(false);
  }

  const validateForm = () => {
    if (!choice) return;
    if (choice.name.length === 0) {
      setNameValidationError("Customization name is required!");
      return false;
    }

    setNameValidationError("");
    return true;
  }

  const getTitle = () => {
    const formTitle = `${isEdit ? "Edit" : "Add"} Customization`;
    return openConfirmPopup ? "Warning!" : formTitle;
  }

  const closeOnDirtyDataConfirmBody = (
    <div>
      <div>There are unsaved modifications</div>
      <div>Are you sure you want to leave without saving?</div>
    </div>
  );


  const getBody = () => {
    const confirmBody = closeOnDirtyDataConfirmBody;
    return openConfirmPopup ? confirmBody : modalBody;
  }

  const modalButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onModalClose}
        className={classes.form_footer_buttons_cancel}
      >Cancel</Button>
      {isEdit ?
        <Button onClick={onSave}
          className={classes.form_footer_buttons_save}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Save</Button>
        : <Button onClick={onAdd}
          className={classes.form_footer_buttons_add}
          style={{ backgroundColor: businessInfo?.theme?.color }}
        >Add</Button>}
    </div>
  );

  const confirmButtons = (
    <div className={classes.form_footer_buttons}>
      <Button onClick={onPopupClose}
        className={classes.form_footer_buttons_cancel}
      >No</Button>
      <Button onClick={onPopupOk}
        className={classes.form_footer_buttons_save}
        style={{ backgroundColor: businessInfo?.theme?.color }}
      >Yes</Button>
    </div>
  )

  const getButtons = () =>
    openConfirmPopup || openDeleteConfirm ? confirmButtons : modalButtons;

  return (
    <GlobalModal
      open={showModal}
      handleClose={onModalClose}
      title={getTitle()}
      body={getBody()}
      buttons={getButtons()}
    />
  );
};

export default CustomizationForm;
