import classes from './GlobalModal.module.scss';
import React, { MouseEventHandler, useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

interface ModalProps {
  open: boolean;
  body: React.ReactNode;
  onOk: Function;
  onCancel: Function;
  handleClose?: never;
  title: string;
  buttons?: React.ReactNode;
}

interface ModalPropsCustom {
  open: boolean;
  body: React.ReactNode;
  onOk?: never;
  onCancel?: never;
  handleClose: Function;
  title: string;
  buttons?: React.ReactNode;
}

const GlobalModal: React.FC<ModalProps | ModalPropsCustom> = (props) => {
  const { open, title, body, onOk, onCancel, buttons: Buttons } = props;
  const { handleClose } = props as ModalPropsCustom;
  const [isOpen, setIsOpen] = useState(open);
  const { businessInfo } = useTypedSelector(state => state.businessInfo);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleOk = () => {
    onOk && onOk();
  }

  const handleCancel = () => {
    onCancel && onCancel();
    handleClose && handleClose();
  }

  const getTheme = () => {
    if (!businessInfo?.theme) return {};
    return { backgroundColor: businessInfo.theme.color };
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      className={classes.globalModal}
    >
      <div className={classes.paper} >
        <div style={getTheme()} className={classes.paper_header}>
          <h2>{title}</h2>
          <span className={classes.paper_closeIcon} style={{ fontSize: "24px" }}
            onClick={handleClose as MouseEventHandler<HTMLSpanElement>} >
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        </div>
        <section className={classes.paper_content}>
          {body}
        </section>

        {Buttons ? <div className={classes.paper_footer}>
          {Buttons}
        </div>
          : <p className={classes.paper_buttonGroup}>
            <button onClick={handleCancel} className={classes.paper_cancel}>Cancel</button>
            <button onClick={handleOk} className={classes.paper_ok}>Ok</button>
          </p>}

      </div>
    </Modal >
  )
}

export default GlobalModal;
