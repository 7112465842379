import { faArrowRight, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DOMAttributes } from "react";
import classes from "./Account.module.scss";
import InputWithIcons from "./InputWithIcons";


interface SubmitFieldWithButtonProps {
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit?: React.MouseEventHandler<HTMLSpanElement>;
  onKeyUp?: DOMAttributes<HTMLInputElement>["onKeyUp"];
  isFormValid: () => boolean;
  moreClasses?: string;
  name?: string;
}

const SubmitFieldWithButton: React.FC<SubmitFieldWithButtonProps> = (props) => {
  const { value, onChange, onKeyUp, onSubmit, isFormValid, moreClasses, name } = props;
  const getSubmitBtnClass = (): string | undefined => {
    let classesStr = `${classes.signInBtn}`;
    if (!isFormValid()) {
      return classesStr += " " + classes.disabled;
    }
    return classesStr;
  }

  return (
    <InputWithIcons
      value={value}
      placeholder="Email"
      type="email"
      name={name}
      leftIcon={faSignInAlt}
      onKeyUp={onKeyUp}
      onChange={onChange}
      wrapperClasses={`${classes.inputField} ${moreClasses}`}
      RightElem={(
        <span
          onClick={onSubmit}
          className={getSubmitBtnClass()}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </span>
      )} />
  );
};

export default SubmitFieldWithButton;
