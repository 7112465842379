export enum ActionType {
  FETCH_BUSINESSINFO_SUCCESS = "search_repositories_success",
  FETCH_BUSINESSINFO_ERROR = "search_repositories_error",
  FETCH_FOODS_CATEGORIES_SUCCESS = "fetch_foods_categories_success",
  FETCH_FOODS_CATEGORIES_ERROR = "fetch_foods_categories_error",
  FETCH_PRODUCTS_SUCCESS = "fetch_products_success",
  FETCH_PRODUCTS_ERROR = "fetch_products_error",
  SIGN_IN_SUCCESS = 'sign_in_success',
  SIGN_IN_ERROR = 'sign_in_error',
  SIGN_OUT = 'sign_out',
  SET_ACCOUNT_ERROR = 'set_error',
  RESET_PASSWORD_SENT = 'forgot_password',
  CLEAR_ACCOUNT_ERRORS = "clear_account_errors",
  EXTERNAL_SIGN_IN_ERROR = "external_sign_in_error",
  ADD_OVERALL_DISCOUNT = "add_overall_discount",
  SET_CREDIT_DISCOUNT = "set_credit_discount",
  SET_BUSINESS_PORTAL_BREADCRUMBS = "set_business_portal_breadcrumbs",
  SET_APP_LOADING = "SET_APP_LOADING",
  SET_APP_LOADED = "SET_APP_LOADED"
}
