import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { Breadcrumbs } from "@material-ui/core";
import BreadcrumbLink from "./BreadcrumbLink/BreadcrumbLink";
import { useEffect } from "react";
import { Breadcrumb } from "../Types/Types";
import { useActions } from "../hooks/useActions";
import { BusinessPortalUrlParams } from "../pages/BusinessPortal";

const pagesBreadCrumbs = [
  {
    path: 'menuEdit',
    displayName: 'Menu'
  },
]

export interface MenuEditUrlParams extends BusinessPortalUrlParams {
  categoryId: string;
  productId: string;
  optionId: string;
}

const PortalHeader: React.FC = (props) => {
  const match = useRouteMatch();
  const location = useLocation();
  const { businessName, categoryId, productId, optionId } = useParams<MenuEditUrlParams>();
  const { breadcrumbs } = useTypedSelector(state => state.businessPortal);
  const { foodsCategories, productsInfo } = useTypedSelector(state => state.businessInfo);
  const { setBusinessPortalBreadcrumbs } = useActions();
  const { children } = props;

  useEffect(() => {

    if (foodsCategories.length === 0) return;
    if (!productsInfo || !businessName) return;

    const initialBreadcrumbs: Breadcrumb[] = [];

    const pageBreadcrumb = pagesBreadCrumbs.find(brdcr => location.pathname.includes(brdcr.path))
    if (!pageBreadcrumb) return;

    const pagePath = `/${pageBreadcrumb.path}`;
    initialBreadcrumbs.push({
      displayName: pageBreadcrumb.displayName,
      pathName: pagePath
    })

    const category = foodsCategories.find(category => category.id === Number(categoryId));
    if (categoryId && category) {
      category && initialBreadcrumbs.push({
        displayName: category.name,
        pathName: `${pagePath}/${categoryId}`
      })
    }

    const productsList = productsInfo[categoryId];
    const product = productsList?.find(product => product.id === Number(productId));
    if (productId && product) {
      initialBreadcrumbs.push({
        displayName: product.name,
        pathName: `${pagePath}/${categoryId}/${productId}`
      })

      const option = product.options?.find(option => option.id === Number(optionId));
      if (optionId && option) {
        initialBreadcrumbs.push({
          displayName: option.label,
          pathName: `${pagePath}/${categoryId}/${productId}/${optionId}`
        })
      }
    }

    setBusinessPortalBreadcrumbs(initialBreadcrumbs);
  }, [location.pathname, foodsCategories, productsInfo, categoryId, productId, optionId]);

  const breadcrumbLinks = breadcrumbs.filter(bc => !!bc)
    .map(breadcrumb => (
      <BreadcrumbLink
        key={breadcrumb.pathName}
        breadcrumb={breadcrumb}
      />));

  return (
    <div>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbLinks}
      </Breadcrumbs>
      {children}
    </div>
  );
};

export default PortalHeader;