import { Route, Switch, useRouteMatch } from "react-router";
import CategoryList from "./CategoryList/CategoryList";
import OptionCustomization from "./OptionsList/OptionCustomization";
import OptionsList from "./OptionsList/OptionsList";
import ProductList from "./ProductList/ProductList";


const MenuPage: React.FC = () => {
  const { path } = useRouteMatch();

  const productListPath = `${path}/:categoryId`;
  const optionsListPath = `${productListPath}/:productId`;
  const optionsCustomizationPath = `${optionsListPath}/:optionId`;
  return (
    <Switch>
      <Route exact path={optionsCustomizationPath}>
        <OptionCustomization />
      </Route>
      <Route exact path={optionsListPath}>
        <OptionsList />
      </Route>
      <Route exact path={productListPath}>
        <ProductList />
      </Route>
      <Route exact path={`${path}`}>
        <CategoryList />
      </Route>
    </Switch>
  );
};

export default MenuPage;
