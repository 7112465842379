import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import Snackbar from "../components/Snackbar/Snackbar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getBusinessInfo } from "../api/businessEdit";
import { cloneBusinessInfo, errorSnackbar, isDirty, postBusinessInfo, SnackbarSettings, successSnackbar, validationErrorSnackbar } from "../common/bizConfigUtils";
import { useActions } from "./useActions";
import { useTypedSelector } from "./useTypedSelector";
import { BusinessInfo, ValidationError } from "../Types/Types";
import { validatePhone } from "../common/phoneUtils";
import { nameof } from "ts-simple-nameof";
import { formatMoney } from "../common/pageUtils";

const checkedInputs = ["checkbox", "radio"];

const isCheckInput = (type) => {
  return checkedInputs.includes(type);
}

type onFetchedFunc = (fetchBusinessInfo: BusinessInfo) => void;

export const useBizConfigNavBlocking = (onFetchedTransform?: onFetchedFunc) => {

  const history = useHistory();
  const { businessInfo } = useTypedSelector(state => state.businessInfo);
  const { setAppLoading, setAppLoaded, setBusinessInfo } = useActions();

  const [locationToNavigate, setLocationToNavigate] = useState(history.location);
  const [isConfirmOpen, showConfirmPopup] = useState(false);
  const [formData, setFormData] = useState<BusinessInfo>();
  const [originalData, setOriginalData] = useState<BusinessInfo>();
  const [isBlocking, setIsBlocking] = useState(false);

  const [snackbarSettings, setSnackbarSettings] = useState<SnackbarSettings>(successSnackbar);
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  let isBlockingInner = isBlocking;

  console.log(formData);

  useEffect(() => {
    if (!businessInfo?.businessId) return;
    fetchBusinessInfo();

    return () => { }
  }, [businessInfo?.businessId]);

  useEffect(() => {
    const dirty = isDirty(originalData, formData);
    dirty && setIsBlocking(true);
  }, [formData]);



  const fetchBusinessInfo = async () => {
    if (!businessInfo?.businessId) return;
    setAppLoading();
    const res = await getBusinessInfo(businessInfo.businessId);
    const fetchedBusinessInfo = res.data as BusinessInfo;
    onFetchedTransform && onFetchedTransform(fetchedBusinessInfo);

    const initialData = cloneBusinessInfo(fetchedBusinessInfo);
    setFormData(initialData);
    setOriginalData(cloneBusinessInfo(initialData));
    setAppLoaded();
    return initialData;
  };

  const handleChange = (event) => {
    if (!formData) return;
    const target = event.target;
    const nameProp = target.name;
    let value = isCheckInput(target.type) ? target.checked : target.value;
    const isNumberType = target.type === 'number' && !isNaN(value);
    if (!!value)
      value = isNumberType ? +value : value;


    const namePath = nameProp.split('.');
    const upperDepth = namePath[0];
    const oneDepth = namePath[1];

    if (!formData.hasOwnProperty(upperDepth)) {
      console.warn(`No prop on formData called ${nameProp}!`);
      return;
    }

    const newData = { ...formData };
    if (oneDepth && newData[upperDepth].hasOwnProperty(oneDepth)) {
      const nestedObj = newData[upperDepth];
      nestedObj[oneDepth] = value;
    } else {
      newData[upperDepth] = value;
    }

    setFormData(newData);
    clearError(nameProp);
  }

  const saveBusinessInfo = async (newBizInfo?: BusinessInfo) => {
    if (!formData) return;

    const dataToSave = newBizInfo ?? formData;

    const isValid = validateData();
    if (!isValid) {
      setSnackbarSettings(validationErrorSnackbar);
      setSnackbarOpen(true);
      return;
    }
    const onFail = () => {
      setSnackbarSettings(errorSnackbar);
    };

    const onSuccess = () => {
      const newOriginalData = cloneBusinessInfo(formData);
      setFormData({ ...formData })
      setBusinessInfo({ ...formData });
      setOriginalData(newOriginalData);
      setSnackbarSettings(successSnackbar);
      setIsBlocking(false);
    };

    await postBusinessInfo(onSuccess, onFail, dataToSave);

    setSnackbarOpen(true);
  }

  const validateData = () => {
    if (!formData) return false;
    let isValid = true;

    if (!!formData.orderReminderPhoneNumber?.length && !validatePhone(formData.orderReminderPhoneNumber)) {
      validationErrors.push({
        fieldName: nameof<BusinessInfo>(p => p.orderReminderPhoneNumber),
        errorMsg: "Please enter a valid phone",
      });
      isValid = false;
    }

    setValidationErrors([...validationErrors]);
    return isValid;
  }

  const clearError = (fieldName: string) => {
    const index = validationErrors.findIndex(ve => ve.fieldName === fieldName);
    validationErrors.splice(index, 1);
    setValidationErrors([...validationErrors]);
  }


  const handleCheckboxChanges = (event, trueValue?: string, falseValue?: string) => {
    if (!formData) return;
    const target = event.target;
    const nameProp = target.name;
    const value = target.checked ? trueValue ?? true : falseValue ?? false;

    const namePath = nameProp.split('.');
    const upperDepth = namePath[0];
    const oneDepth = namePath[1];

    if (formData.hasOwnProperty(upperDepth)) {
      const newData = { ...formData };
      if (oneDepth && newData[upperDepth].hasOwnProperty(oneDepth)) {
        const nestedObj = newData[upperDepth];
        nestedObj[oneDepth] = value;
      } else {
        newData[upperDepth] = value;
      }
      setFormData(newData);
      return;
    }

    console.warn(`No prop on formData called ${nameProp}!`);
  }



  history.block((location, action) => {
    if (action !== "PUSH") return;
    if (location.pathname.includes('businessEdit')) return;

    setLocationToNavigate(location);
    if (isDirty(originalData, formData)) {
      if (!isBlockingInner) return;

      showConfirmPopup(true);
      return false;
    }
  })



  const ConfirmPopup = (
    <ConfirmModal
      isOpen={isConfirmOpen}
      handleClose={e => showConfirmPopup(false)}
      onOk={e => {
        isBlockingInner = false;
        history.push(locationToNavigate.pathname)
      }}
      onCancel={e => showConfirmPopup(false)}
    />
  )

  const SnackbarElement = (
    <Snackbar
      isOpen={snackbarOpen}
      onClose={e => setSnackbarOpen(false)}
      severity={snackbarSettings.severity}
      message={snackbarSettings.message}
    />
  )


  return {
    formData, setFormData,
    handleCheckboxChanges,
    elements: { ConfirmPopup, SnackbarElement },
    clearError, validationErrors,
    fetchBusinessInfo, handleChange, saveBusinessInfo
  };
}
