import axios from "axios";
import { baseUrl } from "../appConfigs";
import { getAuthConfig } from "./apiAuthConfig";

export const getDashboardStats = async (serviceProviderId: string | number, bizId: string) => {
  try {
    return await axios.get(`${baseUrl}/rest/order/ordersData/${serviceProviderId}/${bizId}`);
  } catch (err) {
    throw err;
  }
}

export const getBestSellers = async (serviceProviderId: string | number, bizId: string, year: number, month: number) => {
  // /rest/order/bestSellingMenuItemsByRange/839/1/2022/0
  try {
    return await axios.get(`${baseUrl}/rest/order/bestSellingMenuItemsByRange/${serviceProviderId}/${bizId}/${year}/${month}`);
  } catch (err) {
    throw err;
  }
}
export const getBizOrders = async (serviceProviderId: string | number, days: number, bizId: string) => {
  try {
    return await axios.get(`${baseUrl}/rest/order/orderItemsByDay/${serviceProviderId}/${days}/${bizId}`);
  } catch (err) {
    throw err;
  }
}

export const apiDateFormat = 'YYYY-MM-DD';
const dateMatch = /^\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|([1-2]\d)|3[0-1])$/
export const getBizOrdersByDates = async (serviceProviderId: string | number, startDate: string, endDate: string, bizId: string) => {
  // 2021-07-20/2021-07-30
  if (!dateMatch.test(startDate) || !dateMatch.test(endDate)) {
    console.error("Start or end date is not in correct format: ", startDate, endDate);
    return;
  }

  try {
    const params = `${serviceProviderId}/${startDate}/${endDate}/${bizId}`;
    const url = `${baseUrl}/rest/order/orderReportsByRange/${params}`;
    return await axios.get(url);
  } catch (err) {
    throw err;
  }
}


